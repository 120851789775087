import { Injectable } from "@angular/core";
import { IProfessionnel } from "../../../common/src/bdd/interfaces/IProfessionnel";
import { COLLECTION } from "../../../common/src/bdd/utility/collections";
import { TypeProfessionnal } from "../../../common/src/bdd/interfaces/types";
import { HieroBDD } from "./hierobdd.service";

@Injectable()
export class ProfessionnelService {
  private professionnelData: IProfessionnel;
  private professionnelId: string;

  constructor(private hiero: HieroBDD) {}

  public async getProfessionnelId(): Promise<string> {
    const currentUser = this.hiero.Auth.User;
    let proId = await this.hiero.DB.collection(COLLECTION.PROFESSIONNELS)
      .where("uid", "==", currentUser.Id)
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((doc) => {
          return doc.id;
        });
      });
    this.professionnelId = proId[0];
    return this.professionnelId;
  }

  public async getProfessionnelData(): Promise<IProfessionnel> {
    const currentUser = this.hiero.Auth.User;
    let proData = await this.hiero.DB.collection(COLLECTION.PROFESSIONNELS)
      .where("uid", "==", currentUser.Id)
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((doc) => {
          return doc.data();
        });
      });

    this.professionnelData = proData[0] as IProfessionnel;
    return this.professionnelData;
  }

  public async getTypeProfessionnel(): Promise<TypeProfessionnal> {
    const currentUser = this.hiero.Auth.User;
    const user = this.hiero.DB.collection(COLLECTION.USERS).doc(currentUser.Id);
    const snap = await user.get();
    const client = snap.data();

    if (client.profile.isExpat) return TypeProfessionnal.expat;
    else if (client.profile.isSeineMaritime)
      return TypeProfessionnal.seineMaritime;
    else if (client.profile.isFidinam) return TypeProfessionnal.fidinam;
    else if (client.profile.isANR) return TypeProfessionnal.ANR;
    else return TypeProfessionnal.normal;
  }

  public updateBudget(price: number) {
    if (this.professionnelData.budget > 0) {
      this.professionnelData.budget = this.professionnelData.budget - price;

      this.hiero.DB.collection("professionnels")
        .doc(this.professionnelId)
        .update({
          budget: this.professionnelData.budget,
        });
    }
  }
}
