<div class="row ml-4 d-flex justify-content-between container-fluid">
  <div class="col-md-3 card text-center ml-n5 p-0" *ngIf="proData">
    <div class="mt-3">
      <input
        type="file"
        class="file-input"
        (change)="onFileSelected($event)"
        #fileUpload
        accept="image/x-png,image/gif,image/jpeg"
      />
      <img
        *ngIf="photo == null"
        src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
        alt="imageProfile"
        class="img-fluid border"
        (click)="fileUpload.click()"
      />
      <img
        class="img-fluid border"
        *ngIf="photo"
        [src]="photo"
        alt="imageProfile"
        (click)="fileUpload.click()"
      />
    </div>
    <div>
      <p *ngIf="errorImg" class="text-danger mt-2">
        Nous n'avons pas pu mettre à jour votre photo de profil.
      </p>
    </div>
    <div class="mt-5">
      <h3
        [ngClass]="
          profile.isSeineMaritime
            ? 'p-seine-maritime-color-primary'
            : profile.isFidinam
            ? 'p-fidinam-color-primary'
            : 'p-color'
        "
      >
        {{ profile.givenName }} {{ profile.familyName | uppercase }}
      </h3>
      <p class="text-center font-italic text-muted">
        {{ proData.poste }}
      </p>
      <hr />
      <p class="text-center mt-3">Travaille pour {{ proData.businessName }}</p>
      <hr />
      <p class="text-center mt-3">
        {{ "inscription_date" | localise }}
        {{ pro.metadata.creationTime | date : "dd/MM/yyyy" }}
      </p>
    </div>
  </div>
  <div class="col-md-9 card">
    <div class="mt-3">
      <div class="d-flex justify-content-between">
        <div class="col-md-8">
          <h5 class="mb-3">{{ "my_info" | localise }}</h5>
          <form [formGroup]="profileForm" (ngSubmit)="submit()">
            <div class="d-flex">
              <div class="form-group mr-2 mb-0">
                <label class="sr-only" for="familyName">{{
                  "account_surname" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "account_surname" | localise }}
                    </div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="familyName"
                    formControlName="familyName"
                    [placeholder]="'account_surname_placeholder' | localise"
                  />
                </div>
                <div
                  *ngIf="
                    profileForm.get('familyName').hasError('required') &&
                    profileForm.get('familyName').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
              </div>
              <div class="form-group mb-0">
                <label class="sr-only" for="givenName">{{
                  "account_name" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "account_name" | localise }}
                    </div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="givenName"
                    formControlName="givenName"
                    [placeholder]="'account_name_placeholder' | localise"
                  />
                </div>
                <div
                  *ngIf="
                    profileForm.get('givenName').hasError('required') &&
                    profileForm.get('givenName').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
              </div>
            </div>
            <div class="mb-2">
              <small class="form-text text-muted">
                {{ "agency_name_help" | localise }}
              </small>
            </div>
            <div class="d-flex m-0">
              <div class="form-group mr-2 mb-0">
                <label class="sr-only" for="poste">{{
                  "account_poste" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "account_poste" | localise }}
                    </div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="poste"
                    formControlName="poste"
                    [placeholder]="'account_poste_placeholder' | localise"
                  />
                </div>
                <div
                  *ngIf="
                    profileForm.get('poste').hasError('required') &&
                    profileForm.get('poste').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
              </div>
              <div class="form-group mb-0">
                <label class="sr-only" for="service">{{
                  "account_service" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "account_service" | localise }}
                    </div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="service"
                    formControlName="service"
                    [placeholder]="'account_service_placeholder' | localise"
                  />
                </div>
              </div>
            </div>
            <div class="mb-2">
              <small class="form-text text-muted">
                {{ "agency_poste_service_help" | localise }}
              </small>
            </div>
            <div class="form-group mb-1">
              <label class="sr-only" for="address">{{
                "account_adresse" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_adresse" | localise }}
                  </div>
                </div>
                <input
                  id="address"
                  type="text"
                  class="form-control"
                  [ngbTypeahead]="search"
                  [inputFormatter]="formatter"
                  [resultFormatter]="formatter"
                  [editable]="false"
                  formControlName="address"
                  [placeholder]="'account_adresse_placeholder' | localise"
                />
              </div>
              <small id="address" class="form-text text-muted">
                {{ "agency_setup_address_help" | localise }}
              </small>
              <div
                *ngIf="
                  profileForm.get('address').hasError('required') &&
                  profileForm.get('address').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_required" | localise }}
                </small>
              </div>
            </div>
            <div class="form-group mb-1">
              <label class="sr-only" for="address2">{{
                "account_complement_adr" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_complement_adr" | localise }}
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control"
                  id="address2"
                  formControlName="address2"
                  [placeholder]="
                    'account_complement_adr_placeholder' | localise
                  "
                />
              </div>
              <small class="form-text text-muted">
                {{ "agency_setup_addresscompl_help" | localise }}
              </small>
            </div>
            <div class="d-flex">
              <div class="form-group mr-2 mb-0">
                <label class="sr-only" for="email">{{
                  "account_email" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "account_email" | localise }}
                    </div>
                  </div>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    formControlName="email"
                    [placeholder]="'account_email_placeholder' | localise"
                  />
                </div>
                <div
                  *ngIf="
                    profileForm.get('email').hasError('required') &&
                    profileForm.get('email').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
                <div
                  *ngIf="
                    profileForm.get('email').hasError('email') &&
                    profileForm.get('email').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_invalid_email" | localise }}
                  </small>
                </div>
              </div>
              <div class="form-group mb-0">
                <label class="sr-only" for="tel">{{
                  "account_telephone" | localise
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      {{ "account_telephone" | localise }}
                    </div>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    id="tel"
                    formControlName="tel"
                    [placeholder]="'account_telephone_placeholder' | localise"
                  />
                </div>
                <div
                  *ngIf="
                    profileForm.get('tel').hasError('required') &&
                    profileForm.get('tel').touched
                  "
                >
                  <small class="text-danger">
                    {{ "account_error_required" | localise }}
                  </small>
                </div>
              </div>
            </div>
            <div class="mb-2">
              <small class="form-text text-muted">
                {{ "agency_contact_help_pro" | localise }}
              </small>
            </div>
            <div class="mb-3">
              <button
                [ngClass]="
                  profile.isSeineMaritime
                    ? 'btn seine-maritime-color-secondary'
                    : profile.isFidinam
                    ? 'btn fidinam-color-secondary'
                    : 'btn my-btn'
                "
                [disabled]="profileForm.invalid"
              >
                {{ "account_save" | localise }}
              </button>
            </div>
            <div>
              <p *ngIf="updated" class="text-success mt-2">
                {{ "modif_profile_success" | localise }}
              </p>
              <p *ngIf="error" class="text-danger mt-2">
                {{ "modif_profile_failed" | localise }}
              </p>
              <p *ngIf="errorLogin" class="text-danger mt-2">
                La mise à jour de l'email n'a pas reussi à cause de 3 erreurs
                consécutives des identifiants saisis
              </p>
            </div>
          </form>
        </div>
        <div class="col-md-4">
          <h4
            class="text-right mt-1"
            [ngClass]="
              profile.isSeineMaritime
                ? 'p-seine-maritime-color-primary'
                : profile.isFidinam
                ? 'p-fidinam-color-primary'
                : 'p-color'
            "
          >
            {{ "agency_expl_2_title" | localise }}
            <i class="fa fa-map-marker"></i>
          </h4>
          <p class="mt-3">{{ "geolocation_paragraphe" | localise }}</p>
          <h4
            class="text-right mt-1"
            [ngClass]="
              profile.isSeineMaritime
                ? 'p-seine-maritime-color-primary'
                : profile.isFidinam
                ? 'p-fidinam-color-primary'
                : 'p-color'
            "
          >
            {{ "update_password" | localise }}
            <i class="fa fa-key"> </i>
          </h4>
          <small class="mt-1 mb-3 form-text text-muted text-right">
            {{ "account_upt" | localise }}
          </small>
          <form [formGroup]="passwordForm" (ngSubmit)="submitPwd()">
            <div class="form-group">
              <label class="sr-only" for="password">{{
                "account_password" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_password" | localise }}
                  </div>
                </div>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  formControlName="password"
                />
              </div>
              <div
                *ngIf="
                  passwordForm.get('password').hasError('required') &&
                  passwordForm.get('password').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_required" | localise }}
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="sr-only" for="newPassword">{{
                "account_new_password" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_new_password" | localise }}
                  </div>
                </div>
                <input
                  type="password"
                  class="form-control"
                  id="newPassword"
                  formControlName="newPassword"
                />
              </div>
              <div
                *ngIf="
                  passwordForm.get('newPassword').hasError('required') &&
                  passwordForm.get('newPassword').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_required" | localise }}
                </small>
              </div>
              <div
                *ngIf="
                  passwordForm.get('newPassword').hasError('minlength') &&
                  passwordForm.get('newPassword').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_min_password" | localise }}
                </small>
              </div>
            </div>
            <div class="form-group">
              <label class="sr-only" for="newPassword2">{{
                "account_confirm" | localise
              }}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ "account_confirm" | localise }}
                  </div>
                </div>
                <input
                  type="password"
                  class="form-control"
                  id="newPassword2"
                  formControlName="newPassword2"
                />
              </div>
              <div
                *ngIf="
                  passwordForm.get('newPassword2').hasError('required') &&
                  passwordForm.get('newPassword2').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_required" | localise }}
                </small>
              </div>
              <div
                *ngIf="
                  passwordForm.get('newPassword2').hasError('mustMatch') &&
                  passwordForm.get('newPassword2').touched
                "
              >
                <small class="text-danger">
                  {{ "account_error_passwords_do_not_match" | localise }}
                </small>
              </div>
            </div>
            <div>
              <button
                [ngClass]="
                  profile.isSeineMaritime
                    ? 'btn seine-maritime-color-secondary mb-2'
                    : profile.isFidinam
                    ? 'btn fidinam-color-secondary mb-2'
                    : 'btn my-btn mb-2'
                "
                [disabled]="passwordForm.invalid"
              >
                {{ "account_save" | localise }}
              </button>
            </div>
            <div>
              <p *ngIf="updatedPwd" class="text-success mt-2">
                {{ "modif_profile_success_pwd" | localise }}
              </p>
              <p *ngIf="errorPwd" class="text-danger mt-2">
                {{ "modif_profile_failed_pwd" | localise }}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="row ml-4 d-flex justify-content-between container-fluid mt-3"
  *ngIf="!profile.isSeineMaritime && !profile.isFidinam"
>
  <div class="col-md-3 card ml-n5">
    <div class="mt-3" *ngIf="proData">
      <h5 class="mb-3">{{ "sold" | localise }}</h5>
      <h3 class="p-color" *ngIf="proData.budget">
        {{ proData.budget.toFixed(2) }}
        <span style="font-size: 14px"> € </span>
      </h3>
      <p *ngIf="!proData.budget">{{ "no_budget" | localise }}</p>
      <br />
      <p class="text-muted">
        {{ "augmente_budget" | localise }}
      </p>

      <button class="w-100 btn border mb-2" (click)="virement()">
        {{ "virement" | localise }}
      </button>
      <button class="w-100 btn border mb-3" (click)="cheque()">
        {{ "check" | localise }}
      </button>
      <button class="w-100 btn my-btn mb-3" (click)="refund()">
        {{ "refund" | localise }}
      </button>
    </div>
  </div>
  <div class="col-md-9 card">
    <div class="mt-3">
      <h4>
        {{ "professionnal_paragraph" | localise }}
        <I
          ><small class="text-muted"
            >{{ "professionnal_paragraph_1" | localise }}
          </small></I
        >
      </h4>

      <!-- <ngx-select-pagination
        *ngIf="!!profileCommande"
        [collectionSize]="profileCommande.length"
        [page]="page"
        [pageSize]="pageSize"
        [(ngModel)]="page"
      >
      </ngx-select-pagination> -->

      <table class="table table-striped border">
        <thead>
          <tr>
            <th scope="col">{{ "date_deduction" | localise }}</th>
            <th scope="col">{{ "prestation" | localise }}</th>
            <th scope="col">{{ "description" | localise }}</th>
            <th scope="col">{{ "tasks_price_ttc" | localise }}</th>
            <th scope="col">{{ "date_commande" | localise }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let commande of profileCommande
                | slice
                  : (page - 1) * pageSize
                  : (page - 1) * pageSize + pageSize;
              let i = index
            "
          >
            <td class="p-color" *ngIf="commande.type != 'Interprétation'">
              {{
                commande.translationData.createdAt.toDate()
                  | date : "dd/MM/yyyy"
              }}
            </td>
            <td class="p-color" *ngIf="commande.type == 'Interprétation'">
              {{
                commande.interpretationData.missions.date.toDate()
                  | date : "dd/MM/yyyy"
              }}
            </td>
            <td>
              <span *ngIf="commande.type == 'Traduction classique'">
                {{ "traduction_classic" | localise }}
              </span>
              <span *ngIf="commande.type == 'Traduction assermentée'">
                {{ "traduction_assermentee" | localise }}
              </span>
              <span *ngIf="commande.type == 'Interprétation'">
                {{ "interpretation" | localise }}
              </span>
            </td>

            <td *ngIf="commande.type != 'Interprétation'">
              {{ commande.translationData.documentType }}
            </td>
            <td
              *ngIf="
                commande.type == 'Interprétation' &&
                commande.interpretationData.missions.intervention.id == 1
              "
            >
              {{ "menu_label_sur_place" | localise }}
            </td>
            <td
              *ngIf="
                commande.type == 'Interprétation' &&
                commande.interpretationData.missions.intervention.id == 2
              "
            >
              {{ "menu_label_tel" | localise }}
            </td>
            <td
              *ngIf="
                commande.type == 'Interprétation' &&
                commande.interpretationData.missions.intervention.id == 3
              "
            >
              {{ "menu_label_visio" | localise }}
            </td>
            <td class="p-color" *ngIf="commande.type != 'Interprétation'">
              {{ commande.translationData.prestationInfo.price.ttc.toFixed(2) }}
              €
            </td>
            <td class="p-color" *ngIf="commande.type == 'Interprétation'">
              {{ commande.interpretationData.price.priceHT.toFixed(2) }} €
            </td>
            <td *ngIf="commande.type != 'Interprétation'">
              {{
                commande.translationData.createdAt.toDate()
                  | date : "dd/MM/yyyy"
              }}
            </td>
            <td *ngIf="commande.type == 'Interprétation'">
              {{
                commande.interpretationData.sendTime.toDate()
                  | date : "dd/MM/yyyy"
              }}
            </td>
            <td>
              <button
                class="btn my-btn"
                [routerLink]="[
                  '..',
                  'commandes',
                  'traductions',
                  commande.translationData.prestationId
                ]"
                *ngIf="commande.type != 'Interprétation'"
              >
                <i class="fa fa-folder-open"></i>
                {{ "more_details" | localise }}
              </button>
              <button
                class="btn my-btn"
                [routerLink]="[
                  '..',
                  'commandes',
                  'interpretations',
                  commande.id
                ]"
                *ngIf="commande.type == 'Interprétation'"
              >
                <i class="fa fa-folder-open"></i>
                {{ "more_details" | localise }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex flex-row justify-content-between">
        <ngb-pagination
          *ngIf="!!profileCommande"
          [collectionSize]="profileCommande.length"
          [page]="page"
          [pageSize]="pageSize"
          (pageChange)="onPageChange($event, 1)"
          aria-label="Default pagination"
        >
        </ngb-pagination>
        <div>
          <button class="btn my-btn" [routerLink]="['..', 'commandes']">
            {{ "access_all_amount" | localise }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
