import { Component, OnInit } from "@angular/core";
import * as firebase from "firebase";
import { HieroBDD } from "../../../services/hierobdd.service";

@Component({
  selector: "card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit {
  constructor(private hiero: HieroBDD) {}

  ngOnInit(): void {}

  async test() {
    const fun = fetch(
      "https://us-central1-hierodev.cloudfunctions.net/allUsers"
    );

    const data = await fun;

    const result = await data.json();

    return console.log(
      result.filter((user) => user.uid === "4y9DkIK2HidbKpwHZ4cnDwzkKSD3")
    );
  }
}
