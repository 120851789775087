var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HieroBDD } from "./hierobdd.service";
import { COLLECTION } from "../../../common/src/bdd/utility/collections";
import { ProfessionnelService } from "./professionnel.service";
import { PrestationType } from "../../../common/src/bdd/interfaces/types";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
export class TranslationsService {
    constructor(hiero, pro) {
        this.hiero = hiero;
        this.pro = pro;
        this.commandTranslations = [];
        this.translationsSubject = new BehaviorSubject([]);
    }
    dispachTranslations() {
        this.translationsSubject.next(this.commandTranslations);
    }
    getTranslations() {
        return __awaiter(this, void 0, void 0, function* () {
            let proId = yield this.pro.getProfessionnelId();
            const userCommande = this.hiero.DB.collection(COLLECTION.PROFESSIONNEL_COMMANDES)
                .where("professionnelId", "==", proId)
                .orderBy("createdAt", "desc");
            return userCommande.get().then((snapshot) => {
                this.commandTranslations = snapshot.docs.map((doc) => {
                    let type;
                    if (doc.data().originLanguage === "classic") {
                        type = PrestationType.TRADUCTION_CLASSIQUE;
                    }
                    if (doc.data().originLanguage != "classic") {
                        type = PrestationType.TRADUCTION_ASSERMENTEE;
                    }
                    return {
                        type: type,
                        id: doc.id,
                        translationData: doc.data(),
                    };
                });
                this.dispachTranslations();
                return this.commandTranslations;
            });
        });
    }
    getTranslationsThisMonth() {
        return this.commandTranslations.filter((data) => data.translationData.createdAt.toDate().getMonth() ===
            new Date().getMonth());
    }
    getTranslationsLastMonth() {
        let translationsLastMonth = this.commandTranslations.filter((data) => {
            if (new Date().getMonth() === 0) {
                return (data.translationData.createdAt.toDate().getFullYear() ===
                    new Date().getFullYear() - 1 &&
                    data.translationData.createdAt.toDate().getMonth() === 11);
            }
            else
                return (data.translationData.createdAt.toDate().getMonth() ===
                    new Date().getMonth() - 1);
        });
        let classicLastMonth = translationsLastMonth.filter((value) => value.type === PrestationType.TRADUCTION_CLASSIQUE).length;
        let asserLastMonth = translationsLastMonth.filter((value) => value.type === PrestationType.TRADUCTION_ASSERMENTEE).length;
        let tab = [];
        tab[0] = classicLastMonth;
        tab[1] = asserLastMonth;
        return tab;
    }
}
