var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as firebase from "firebase";
import { environment } from "../../../../../environments/environment";
export class PaiementModalComponent {
    constructor(activeModal, formBuilder) {
        this.activeModal = activeModal;
        this.formBuilder = formBuilder;
    }
    ngOnInit() {
        this.createFormLogin();
    }
    createFormLogin() {
        this.payForm = this.formBuilder.group({
            solde: ["", Validators.required],
        });
    }
    onSubmit() {
        this.pay(this.payForm.value.solde);
    }
    pay(price) {
        return __awaiter(this, void 0, void 0, function* () {
            let db = firebase.firestore();
            const docRef = yield db
                .collection("customers")
                .doc(firebase.auth().currentUser.uid)
                .collection("checkout_sessions")
                .add({
                payment_method_types: ["sepa_debit"],
                line_items: [
                    {
                        price_data: {
                            currency: "eur",
                            product_data: {
                                name: "Augmentation du budget",
                            },
                            unit_amount: price * 100,
                        },
                        quantity: 1,
                    },
                ],
                mode: "payment",
                allow_promotion_codes: true,
                success_url: environment.resetRedirectUrl + "/app/profile",
                cancel_url: window.location.origin,
            });
            // Wait for the CheckoutSession to get attached by the extension
            docRef.onSnapshot((snap) => {
                const { error, url } = snap.data();
                if (error) {
                    // Show an error to your customer and
                    // inspect your Cloud Function logs in the Firebase console.
                    alert(`An error occured: ${error.message}`);
                }
                if (url) {
                    // We have a Stripe Checkout URL, let's redirect.
                    window.location.assign(url);
                }
            });
        });
    }
}
