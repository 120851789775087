<div class="container-fluid" *ngIf="client">
  <h2>{{ "consult_devis" | localise }}</h2>
  <hr />

  <table class="table table-striped mt-3">
    <thead>
      <tr>
        <th scope="col">{{ "section_date" | localise }}</th>
        <th scope="col">{{ "devis_number" | localise }}</th>
        <th scope="col" class="align-middle">
          {{ " type_doc_commande" | localise }}
        </th>
        <th scope="col">{{ "section_type_prestation" | localise }}</th>
        <th scope="col" class="align-middle">
          {{ "order_status" | localise }}
        </th>
        <th scope="col">{{ "devis" | localise }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let devis of allDevis | paginate: { itemsPerPage: 7, currentPage: p };
          let indexOfelement = index
        "
      >
        <td>
          <div
            [ngClass]="
              client.profile.isSeineMaritime
                ? 'seine-maritime-color-green badge rounded-pill p-2'
                : client.profile.isFidinam
                ? 'fidinam-color-green badge rounded-pill p-2'
                : 'badge rounded-pill my-color p-2'
            "
          >
            {{ devis.devisData.date.toDate() | date: "dd/MM/yyyy" }}
          </div>
        </td>
        <td>
          <span>{{ devis.devisData.numero }}</span>
        </td>
        <td *ngIf="devis.devisData.typePrestation === 'Interprétation'">
          <span>{{ devis.devisData.event }}</span>
        </td>
        <td *ngIf="devis.devisData.typePrestation !== 'Interprétation'">
          <span>{{ devis.devisData.documentType }}</span>
        </td>
        <td *ngIf="devis.devisData.typePrestation === 'Traduction assermentée'">
          {{ "traduction_assermentee" | localise }}
        </td>
        <td *ngIf="devis.devisData.typePrestation === 'Traduction classique'">
          {{ "traduction_classic" | localise }}
        </td>
        <td *ngIf="devis.devisData.typePrestation === 'Interprétation'">
          {{ "interpretation" | localise }}
        </td>
        <td
          class="text-success font-weight-bold font-italic"
          *ngIf="devis.devisData.state === 'accepted-by-client'"
        >
          <span>
            <i class="fa fa-check-circle mr-1"></i>
            {{ "devis_accept" | localise }}
          </span>
        </td>
        <td
          class="text-danger font-weight-bold font-italic"
          *ngIf="devis.devisData.state === 'refused-by-client'"
        >
          <span>
            <i class="fa fa-remove mr-1"></i>
            {{ "devis_refused" | localise }}</span
          >
        </td>
        <td
          class="text-waiting font-weight-bold font-italic"
          *ngIf="devis.devisData.state === 'waiting-for-validation'"
        >
          <span>
            <i class="fa fa-hourglass-half mr-1"></i>
            {{ "devis_waiting" | localise }}</span
          >
        </td>
        <td class="align-middle justify-content-around">
          <button
            [ngClass]="
              client.profile.isSeineMaritime
                ? 'btn seine-maritime-color-secondary mr-1'
                : client.profile.isFidinam
                ? 'btn fidinam-color-secondary mr-1'
                : 'btn btn-pro mr-1'
            "
            (click)="download(devis.id)"
          >
            <i class="fa fa-download mr-1"></i>
            {{ "devis_generate" | localise }}
          </button>

          <button
            class="btn btn-outline-success mr-1"
            *ngIf="
              devis.devisData.state === 'waiting-for-validation' &&
              !client.profile.isSeineMaritime &&
              !client.profile.isFidinam
            "
            (click)="sendCommand(devis.id)"
          >
            {{ "devis_validate_action" | localise }}
            <i class="fa fa-check-circle ml-1"></i>
          </button>
          <button
            class="btn btn-outline-danger"
            (click)="refusedCommand(devis.id)"
            *ngIf="
              devis.devisData.state === 'waiting-for-validation' &&
              !client.profile.isSeineMaritime &&
              !client.profile.isFidinam
            "
          >
            {{ "devis_refuse_action" | localise }}
            <i class="fa fa-remove ml-1"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="d-flex justify-content-center mt-4">
    <pagination-controls
      (pageChange)="p = $event"
      [ngClass]="
        client.profile.isSeineMaritime
          ? 'pagination-seine-maritime-color-secondary'
          : client.profile.isFidinam
          ? 'pagination-fidinam-color-secondary'
          : 'my-pagination'
      "
    >
    </pagination-controls>
  </div>
</div>
