import { Injectable } from "@angular/core";
import { HieroBDD } from "./hierobdd.service";
import { COLLECTION } from "../../../common/src/bdd/utility/collections";
import { ProfessionnelService } from "./professionnel.service";
import { PrestationType } from "../../../common/src/bdd/interfaces/types";
import { BehaviorSubject } from "rxjs/BehaviorSubject";

@Injectable()
export class TranslationsService {
  private commandTranslations: any[] = [];
  public translationsSubject: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >(<any[]>[]);

  constructor(private hiero: HieroBDD, private pro: ProfessionnelService) {}

  private dispachTranslations() {
    this.translationsSubject.next(this.commandTranslations);
  }

  public async getTranslations(): Promise<any> {
    let proId = await this.pro.getProfessionnelId();

    const userCommande = this.hiero.DB.collection(
      COLLECTION.PROFESSIONNEL_COMMANDES
    )
      .where("professionnelId", "==", proId)
      .orderBy("createdAt", "desc");
    return userCommande.get().then((snapshot) => {
      this.commandTranslations = snapshot.docs.map((doc) => {
        let type: PrestationType;
        if (doc.data().originLanguage === "classic") {
          type = PrestationType.TRADUCTION_CLASSIQUE;
        }
        if (doc.data().originLanguage != "classic") {
          type = PrestationType.TRADUCTION_ASSERMENTEE;
        }
        return {
          type: type,
          id: doc.id,
          translationData: doc.data(),
        };
      });
      this.dispachTranslations();
      return this.commandTranslations;
    });
  }

  public getTranslationsThisMonth() {
    return this.commandTranslations.filter(
      (data) =>
        data.translationData.createdAt.toDate().getMonth() ===
        new Date().getMonth()
    );
  }

  public getTranslationsLastMonth() {
    let translationsLastMonth = this.commandTranslations.filter((data) => {
      if (new Date().getMonth() === 0) {
        return (
          data.translationData.createdAt.toDate().getFullYear() ===
            new Date().getFullYear() - 1 &&
          data.translationData.createdAt.toDate().getMonth() === 11
        );
      } else
        return (
          data.translationData.createdAt.toDate().getMonth() ===
          new Date().getMonth() - 1
        );
    });

    let classicLastMonth = translationsLastMonth.filter(
      (value) => value.type === PrestationType.TRADUCTION_CLASSIQUE
    ).length;

    let asserLastMonth = translationsLastMonth.filter(
      (value) => value.type === PrestationType.TRADUCTION_ASSERMENTEE
    ).length;

    let tab = [];
    tab[0] = classicLastMonth;
    tab[1] = asserLastMonth;

    return tab;
  }
}
