var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { IUserProfile, } from "../../../../../common/src/bdd/interfaces/IUserProfile";
import { HieroBDD } from "../../../services/hierobdd.service";
import { GoogleGeo } from "../../../services/google.services";
import * as firebase from "firebase";
import { ProfilePictureService } from "../../../services/profile-picture.services";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { LoginModalComponent } from "./login-modal/login-modal.component";
import { CheckModalComponent } from "./check-modal/check-modal.component";
import { RefundModalComponent } from "./refund-modal/refund-modal.component";
import { PaiementModalComponent } from "./paiement-modal/paiment-modal.component";
import { ProfessionnelService } from "../../../services/professionnel.service";
import { InterpretationService } from "../../../services/interpretation.service";
import { PrestationType } from "../../../../../common/src/bdd/interfaces/types";
import { TranslationsService } from "../../../services/translations.service";
export class ProfileComponent {
    constructor(hiero, fb, pfpService, geo, router, modalService, professionnelService, translationsService, interpretationService) {
        this.hiero = hiero;
        this.fb = fb;
        this.pfpService = pfpService;
        this.geo = geo;
        this.router = router;
        this.modalService = modalService;
        this.professionnelService = professionnelService;
        this.translationsService = translationsService;
        this.interpretationService = interpretationService;
        this.updated = false;
        this.updatedPwd = false;
        this.isSent = false;
        this.errorImg = false;
        this.error = false;
        this.errorLogin = false;
        this.errorPwd = false;
        this.errorSent = false;
        this.busy = true;
        this.profileCommande = [];
        this.page = 1;
        this.pageAsser = 1;
        this.pageInter = 1;
        this.pageSize = 5;
        this.search = (text$) => text$.pipe(debounceTime(100), distinctUntilChanged(), switchMap((term) => (term.length < 2 ? [] : this.geo.geocode(term))));
        this.formatter = (loc) => loc && loc.address ? loc.address.formatted : "";
    }
    ngOnInit() {
        this.getUserData();
        // Add listeners for the data we want
        // These listeners will either fire immediately,
        // or they will fire when the data becomes available
        this.userSubs = this.hiero.Auth.WatchUser({
            next: (user) => {
                if (user) {
                    // We have a user, save a reference
                    this.userWatch = user;
                    if (this.userWatch) {
                        // The user is not null, watch for the user profile
                        this.profSubs = this.userWatch.WatchProfile({
                            next: (profile) => {
                                if (profile) {
                                    this.profile = profile;
                                    this.createForm(profile);
                                }
                                this.busy = false;
                            },
                        });
                    }
                }
            },
        });
        this.createFormPwd();
    }
    ngOnDestroy() {
        this.profSubs.unsubscribe();
        this.userSubs.unsubscribe();
    }
    getUserData() {
        return __awaiter(this, void 0, void 0, function* () {
            this.pro = firebase.auth().currentUser;
            this.photo = this.pro.photoURL;
            this.proId = yield this.professionnelService.getProfessionnelId();
            this.proData = yield this.professionnelService.getProfessionnelData();
            this.currentUser = this.hiero.Auth.User;
            this.getUserCommande().then((array) => (this.profileCommande = array));
            this.geo
                .geocode(this.proData.address.formatted)
                .then((x) => this.profileForm.get("address").setValue(x[0]));
            this.profileForm.get("address2").setValue(this.proData.address.extra);
            this.profileForm.get("poste").setValue(this.proData.poste);
            this.profileForm.get("service").setValue(this.proData.service);
        });
    }
    createForm(profile) {
        this.profileForm = this.fb.group({
            familyName: [profile.familyName, Validators.required],
            givenName: [profile.givenName, Validators.required],
            poste: ["", Validators.required],
            service: ["", Validators.required],
            address: ["", Validators.required],
            address2: [""],
            email: [this.userWatch.Email, [Validators.required, Validators.email]],
            tel: [profile.telephone, Validators.required],
        });
    }
    createFormPwd() {
        this.passwordForm = this.fb.group({
            password: ["", Validators.required],
            newPassword: ["", [Validators.required, Validators.minLength(8)]],
            newPassword2: ["", Validators.required],
        }, {
            validator: this.mustMatch("newPassword", "newPassword2"),
        });
    }
    mustMatch(controlName, matchingControlName) {
        return (formGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }
            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            }
            else {
                matchingControl.setErrors(null);
            }
        };
    }
    onFileSelected(event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.errorImg = false;
            if (event.target.files.length > 0) {
                const file = event.target.files[0];
                // store the image in the firebase storage at images/profile
                let storage = this.hiero.Storage;
                const fileRef = storage.ref("images/profile/").child(this.pro.uid);
                const result = yield fileRef.put(file);
                // get the image stored and update the profile picture
                yield storage
                    .ref(result.ref.fullPath)
                    .getDownloadURL()
                    .then((photo) => {
                    this.pro
                        .updateProfile({
                        photoURL: photo,
                    })
                        .then(() => {
                        this.photo = this.pro.photoURL;
                        this.pfpService.pfp$.next(this.photo);
                        //window.location.reload();
                    })
                        .catch((error) => (this.errorImg = true));
                });
            }
        });
    }
    submit() {
        this.updated = false;
        this.errorLogin = false;
        this.error = false;
        let formValue = this.profileForm.value;
        formValue.address.address.extra = formValue.address2;
        if (formValue.email != this.pro.email) {
            const modal = this.modalService.open(LoginModalComponent);
            modal.result.then((x) => {
                if (x === true) {
                    this.pro
                        .updateEmail(formValue.email)
                        .then(() => {
                        this.updateProfileInfo(formValue);
                        this.pro.sendEmailVerification();
                        this.router.navigate(["compte", "emailVerify", "2"]);
                    })
                        .catch((error) => {
                        // TO DO : what to do if update email failed?
                        console.log("update email failed");
                    });
                }
                // TO DO : what to do if login failed?
                if (x === false) {
                    this.errorLogin = true;
                }
            });
        }
        else {
            this.updateProfileInfo(formValue);
        }
    }
    submitPwd() {
        this.errorPwd = false;
        this.updatedPwd = false;
        const credentials = firebase.auth.EmailAuthProvider.credential(this.pro.email, this.passwordForm.value.password);
        this.pro
            .reauthenticateWithCredential(credentials)
            .then(() => {
            this.pro
                .updatePassword(this.passwordForm.value.newPassword)
                .then(() => {
                this.updatedPwd = true;
            })
                .catch((error) => {
                this.errorPwd = true;
            });
        })
            .catch((error) => (this.errorPwd = true));
    }
    updateProfileInfo(formValue) {
        this.currentUser
            .UpdateProfile({
            familyName: formValue.familyName,
            givenName: formValue.givenName,
            telephone: formValue.tel,
            email: formValue.email,
        })
            .then(() => {
            this.hiero.DB.collection("professionnels").doc(this.proId).update({
                address: formValue.address.address,
                coords: formValue.address.coords,
                poste: formValue.poste,
                service: formValue.service,
            });
            this.updated = true;
            this.profile.familyName = formValue.familyName;
            this.profile.givenName = formValue.givenName;
            this.proData.poste = formValue.poste;
            this.proData.service = formValue.service;
        })
            .catch((error) => {
            console.log(error);
            this.error = true;
        });
    }
    virement() {
        this.modalService.open(PaiementModalComponent);
    }
    cheque() {
        this.modalService.open(CheckModalComponent);
    }
    refund() {
        this.modalService.open(RefundModalComponent);
    }
    getUserCommande() {
        return __awaiter(this, void 0, void 0, function* () {
            let commandes = [];
            commandes = yield this.translationsService.getTranslations();
            yield this.interpretationService.getInterpretations().then((snap) => {
                snap.map((data) => commandes.push(data));
            });
            // order list by date
            commandes.sort((a, b) => {
                if (a.type === PrestationType.INTERPRETATION) {
                    if (b.type === PrestationType.INTERPRETATION)
                        return (b.interpretationData.missions.date.toDate() -
                            a.interpretationData.missions.date.toDate());
                    else
                        return (b.translationData.createdAt.toDate() -
                            a.interpretationData.missions.date.toDate());
                }
                else {
                    if (b.type === PrestationType.INTERPRETATION)
                        return (b.interpretationData.missions.date.toDate() -
                            a.translationData.createdAt.toDate());
                }
            });
            return commandes;
        });
    }
    onPageChange(pageNumber, switchnmbr) {
        switch (switchnmbr) {
            case 0:
                this.pageAsser = pageNumber;
                // console.log('pageCOm n:', this.pageCom)
                break;
            case 1:
                this.page = pageNumber;
                // console.log('pagePre n:', this.pagePre)
                break;
            case 2:
                this.pageInter = pageNumber;
                // console.log('pageUser n:', this.pageUser)
                break;
            /*case 3:
              this.pageTrad = pageNumber
              //console.log('pageUser n:', this.pageTrad)
              break; */
            default:
                break;
        }
    }
}
