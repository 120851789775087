var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, Validators } from "@angular/forms";
import { HieroBDD } from "../../../../services/hierobdd.service";
import { Professionnel } from "../../../../../../common/src/bdd/professionnel/Professionnel";
import { ProfessionnelServiceList } from "../../../../../../common/src/bdd/professionnel/ProfessionnelService";
import { Prestation } from "../../../../../../common/src/bdd/prestation/Prestation";
import Swal from "sweetalert2";
import * as firebase from "firebase";
import { LocalisationService } from "../../../../../../common/src/modules/localisation/localisation.service";
import { EmptyTraducteurProfile, } from "../../../../../../common/src/bdd/interfaces/ITraducteur";
import { environment } from "../../../../../environments/environment";
import { EnumDevisState } from "../../../../../../common/src/bdd/interfaces/IDevis";
export var EnumPrestationState;
(function (EnumPrestationState) {
    EnumPrestationState["Defining"] = "defining";
    EnumPrestationState["WaitingForTranslator"] = "waiting-for-translator";
    EnumPrestationState["CancelledByClient"] = "cancelled-by-client";
    EnumPrestationState["WaitingForPayment"] = "waiting-for-payment";
    EnumPrestationState["RefusedByTranslator"] = "refused-by-translator";
    EnumPrestationState["Translating"] = "translating";
    EnumPrestationState["WaitingForValidationFromClient"] = "waiting-for-client-to-validate";
    EnumPrestationState["Validated"] = "validated";
})(EnumPrestationState || (EnumPrestationState = {}));
export class CommandeComponent {
    constructor(activeModal, formBuilder, hiero, localisation) {
        this.activeModal = activeModal;
        this.formBuilder = formBuilder;
        this.hiero = hiero;
        this.localisation = localisation;
        this.EmptyPrestation = {
            uid: "",
            state: EnumPrestationState.WaitingForTranslator,
            srcLanguageIso639: "",
            destLanguageIso639: "",
            srcCountryCode: "",
            documents: [],
            traducteurId: null,
            traducteur: null,
            price: {
                traducteurHT: 0,
                hieroMarginPercent: 0,
                hieroMarginValue: 0,
                subtotalHT: 0,
                tva: 0,
                tvaValue: 0,
                ttc: 0,
            },
            deviceStorageId: "",
            sentToTranslatorAt: 0,
            cancelledByClientAt: 0,
            acceptedByTranslatorAt: 0,
            refusedByTranslatorAt: 0,
            dueAt: 0,
            paidAt: 0,
            completedAt: 0,
            validatedByClientAt: 0,
            lastModifiedAt: 0,
            createdAt: 0,
            isProfessionalPrestation: true,
        };
        this.EmptyDocument = {
            documentTypeId: "",
            deviceStorageId: "",
            original: [],
            translated: [],
            extra: [],
        };
        this.EmptyIFile = {
            name: "",
            deviceStorageId: "",
            downloadURL: "",
        };
        this.EmptyTraducteurTampon = {
            traducteur: EmptyTraducteurProfile,
            priceHT: 0,
            priceTTC: 0,
            tvaValue: 0,
        };
        this.srcLangList = [];
        this.destLangList = [];
        this.srcCountryList = [];
        this.isChecked = false;
        this.isExtraChecked = false;
        this.translatorList = [];
        this.fileLength = 0;
        this.tab = [];
        this.tabExtra = [];
        this.documentsFileUpload = [];
        this.FORFAIT = 32.5;
        this.TVA = 1.2;
        this.show = false;
        this.showLoading = false;
        this.progress = 0;
        this.pagesNumber = 1;
    }
    ngOnInit() {
        this.storage = this.hiero.Storage;
        this.createForm();
        this.getSrcLang();
        this.commandForm.get("type").valueChanges.subscribe((type) => {
            if (type === "asser")
                this.commandForm.get("originLang").enable();
            else {
                this.commandForm.get("originLang").setValue("");
                this.commandForm.get("originLang").disable();
            }
        });
        this.commandForm.get("srcLang").valueChanges.subscribe((lang) => {
            this.getDestLang(lang);
        });
        this.commandForm.get("isNumberPage").valueChanges.subscribe((isChecked) => {
            this.isChecked = isChecked;
            if (isChecked) {
                this.commandForm.get("docFile").setValue([]);
                this.selectedFiles = null;
                document.getElementById("input_file").removeAttribute("multiple");
            }
            else {
                this.commandForm.get("docFile").setValue([]);
                this.selectedFiles = null;
                document.getElementById("input_file").setAttribute("multiple", "true");
            }
        });
        this.commandForm
            .get("docFilePageNumber")
            .valueChanges.subscribe((number) => (this.pagesNumber = number));
        this.commandForm
            .get("isExtraFiles")
            .valueChanges.subscribe((isExtraChecked) => {
            this.isExtraChecked = isExtraChecked;
        });
    }
    createForm() {
        this.commandForm = this.formBuilder.group({
            type: ["", Validators.required],
            doctype: ["", Validators.required],
            srcLang: ["", Validators.required],
            destLang: ["", Validators.required],
            originLang: [{ value: "", disabled: true }, Validators.required],
            bon: [""],
            description: [""],
            urgency: ["", Validators.required],
            isNumberPage: [""],
            docFilePageNumber: ["", Validators.min(0)],
            docFile: [[], Validators.required],
            isExtraFiles: [""],
            extraDocFile: [[]],
        });
    }
    getSrcLang() {
        return __awaiter(this, void 0, void 0, function* () {
            const docRef = this.hiero.DB.collection("app_indexes").doc("services");
            return docRef
                .get()
                .then((snapshot) => {
                this.srcLangList = snapshot.get("languages");
            })
                .catch();
        });
    }
    getDestLang(lang) {
        return __awaiter(this, void 0, void 0, function* () {
            this.commandForm.get("destLang").setValue("");
            this.commandForm.get("originLang").setValue("");
            const traducteurDestListRef = yield this.hiero.DB.collection("traducteur_services")
                .where("srcLanguageIso639", "==", lang)
                .get();
            const temp = traducteurDestListRef.docs.map((doc) => doc.get("destLanguageIso639"));
            this.destLangList = yield temp.filter((v, i) => temp.indexOf(v) === i);
            this.srcCountryList = this.srcLangList
                .filter((l) => l.iso639 == lang)
                .map((_) => _.countries)
                .reduce((acc, val) => acc.concat(val), []);
        });
    }
    fileUpload(files) {
        this.fileLength = files.length;
        if (this.fileLength > 0)
            this.selectedFiles = files;
    }
    extraFileUpload(files) {
        if (files.length > 0)
            this.selectedExtraFiles = files;
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.formValue = this.commandForm.value;
            this.translatorList = [];
            // get traslators
            const traducteurListFilter = this.getTraducteurs({
                srcName: this.formValue.srcLang,
                destName: this.formValue.destLang,
            });
            // get traslators Id
            const tradIds = yield traducteurListFilter.then((data) => data.map((_) => {
                return _.traducteurId;
            }));
            // get Traslators' infos
            if (this.hiero.Auth.User.Profile.isANR) {
                const traducteurRef = yield this.hiero.DB.collection("traducteurs")
                    .where("uid", "==", environment.uidANR)
                    .get();
                traducteurRef.docs.map((_) => {
                    tradIds.forEach((element) => {
                        if (element === _.id) {
                            if (this.formValue.type === "asser") {
                                if (_.data().assermentation === "true" ||
                                    _.data().assermentation === true) {
                                    this.translatorList.push(_.data());
                                }
                            }
                            else {
                                this.translatorList.push(_.data());
                            }
                            //calcul tva priceHT and priceTTC foreach trad
                            if (this.isChecked &&
                                this.pagesNumber != null &&
                                this.pagesNumber > 0) {
                                this.calculPrice(this.pagesNumber, this.translatorList);
                            }
                            else {
                                this.calculPrice(this.fileLength, this.translatorList);
                            }
                        }
                    });
                });
            }
            else if (this.hiero.Auth.User.Profile.isSeineMaritime) {
                const traducteurRef = yield this.hiero.DB.collection("traducteurs")
                    .where("uid", "==", environment.uidSeineMaritime)
                    .get();
                traducteurRef.docs.map((_) => {
                    tradIds.forEach((element) => {
                        if (element === _.id) {
                            if (this.formValue.type === "asser") {
                                if (_.data().assermentation === "true" ||
                                    _.data().assermentation === true) {
                                    this.translatorList.push(_.data());
                                }
                            }
                            else {
                                this.translatorList.push(_.data());
                            }
                            //calcul tva priceHT and priceTTC foreach trad
                            if (this.isChecked &&
                                this.pagesNumber != null &&
                                this.pagesNumber > 0) {
                                this.calculPrice(this.pagesNumber, this.translatorList);
                            }
                            else {
                                this.calculPrice(this.fileLength, this.translatorList);
                            }
                        }
                    });
                });
            }
            else {
                const traducteurRef = yield this.hiero.DB.collection("traducteurs").get();
                traducteurRef.docs.map((_) => {
                    if (_.data().uid !== environment.uidANR &&
                        _.data().uid !== environment.uidSeineMaritime) {
                        tradIds.forEach((element) => {
                            if (element === _.id) {
                                if (this.formValue.type === "asser") {
                                    if (_.data().assermentation === "true" ||
                                        _.data().assermentation === true) {
                                        this.translatorList.push(_.data());
                                    }
                                }
                                else {
                                    this.translatorList.push(_.data());
                                }
                                //calcul tva priceHT and priceTTC foreach trad
                                if (this.isChecked &&
                                    this.pagesNumber != null &&
                                    this.pagesNumber > 0) {
                                    this.calculPrice(this.pagesNumber, this.translatorList);
                                }
                                else {
                                    this.calculPrice(this.fileLength, this.translatorList);
                                }
                            }
                        });
                    }
                });
            }
            this.show = true;
        });
    }
    getTraducteurs({ srcName, destName, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const traducteurServiceRef = yield this.hiero.DB.collection("traducteur_services")
                .where("srcLanguageIso639", "==", srcName)
                .where("destLanguageIso639", "==", destName)
                .get();
            const datas = traducteurServiceRef.docs.map((doc) => doc.data());
            return datas;
        });
    }
    calculPrice(pagesNumber, traducteurs) {
        this.translatorsCopies = traducteurs.map((element) => {
            if (element.defaultException) {
                const priceHT = pagesNumber * +element.defaultException;
                let TVAval = 0;
                let priceTTC = 0;
                if (this.hiero.Auth.User.Profile.isExpat) {
                    const priceMarge = (priceHT / 100) * 18;
                    TVAval = ((priceHT + priceMarge) / 100) * 10;
                    priceTTC = +(priceHT + TVAval + priceMarge).toFixed(2);
                }
                else {
                    TVAval = (priceHT / 100) * 20;
                    priceTTC = +(priceHT + TVAval).toFixed(2);
                }
                const copie = {
                    traducteur: element,
                    priceHT: priceHT,
                    tvaValue: TVAval,
                    priceTTC: priceTTC,
                };
                return copie;
            }
            if (!element.defaultException) {
                const priceHT = pagesNumber * this.FORFAIT;
                const TVAvaleur = (priceHT / 100) * 20;
                const priceTTC = priceHT + TVAvaleur;
                const copie = {
                    traducteur: element,
                    priceHT: priceHT,
                    tvaValue: TVAvaleur,
                    priceTTC: priceTTC,
                };
                return copie;
            }
        });
    }
    getTranslatorNameOnClickButton(traslatorName) {
        this.currentUser = this.hiero.Auth.User;
        this.getTraducteurId(traslatorName);
        const docRef = this.hiero.DB.collection("professionnels").where("uid", "==", this.currentUser.Id);
        docRef
            .get()
            .then((snapshot) => {
            this.professionnelId = snapshot.docs[0].id;
            this.professionnelData = snapshot.docs[0].data();
            if (traslatorName != null) {
                if (this.fileLength > 0) {
                    for (const indexe in this.selectedFiles) {
                        this.EmptyDocument.deviceStorageId = this.currentUser.Id;
                        if (indexe === "length") {
                            if (this.hiero.Auth.User.Profile.isANR ||
                                this.hiero.Auth.User.Profile.isExpat ||
                                this.professionnelData.isDevis ||
                                this.hiero.Auth.User.Profile.isSeineMaritime ||
                                this.hiero.Auth.User.Profile.isFidinam) {
                                this.sendDevis(traslatorName);
                            }
                            else {
                                this.sendCommand(traslatorName);
                            }
                        }
                    }
                }
            }
        })
            .catch();
    }
    // get the traslator's id
    getTraducteurId(traslatorName) {
        const docRefTrad = this.hiero.DB.collection("traducteurs").where("businessName", "==", traslatorName);
        return docRefTrad.get().then((snapshot) => {
            this.tradId = snapshot.docs[0].id;
            snapshot.docs.find((elt) => elt.id === this.tradId).data();
            return this.tradId;
        });
    }
    sendDevis(translatorName) {
        return __awaiter(this, void 0, void 0, function* () {
            this.createData(translatorName, true);
        });
    }
    sendCommand(translatorName) {
        return __awaiter(this, void 0, void 0, function* () {
            this.show = false;
            this.createData(translatorName, false);
        });
    }
    createData(translatorName, isDevis) {
        return __awaiter(this, void 0, void 0, function* () {
            this.showLoading = true;
            this.progress = 0;
            const pro = new Professionnel(this.currentUser, this.professionnelId);
            const commands = new ProfessionnelServiceList(pro);
            this.EmptyDocument.documentTypeId = this.formValue.doctype;
            this.isSelectedTrad = this.translatorsCopies.filter((el) => el.traducteur.businessName === translatorName);
            // send data files to firestorage
            Object.keys(this.selectedFiles).map((obj) => {
                const EmptyIFiles = {
                    name: "",
                    deviceStorageId: "",
                    downloadURL: "",
                };
                EmptyIFiles.deviceStorageId = this.selectedFiles[obj].name;
                const id = this.currentUser.Id +
                    "/" +
                    //this.traducteur.uid
                    this.currentUser.Id +
                    "/" +
                    // this.traducteur.uid this.traducteurName.replace(' ', '_')
                    this.currentUser.Id +
                    "/" +
                    EmptyIFiles.deviceStorageId;
                this.storageRef = this.hiero.Storage.ref(id);
                this.storageRef.put(this.selectedFiles[obj]).on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                    const float = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    this.progress = +float.toFixed();
                    console.log("Upload is " + this.progress + "% done");
                    this.storageRef.getDownloadURL().then((snap) => {
                        EmptyIFiles.downloadURL = snap;
                    });
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log("Upload is paused");
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log("Upload is running");
                            break;
                    }
                }, (error) => { }, () => {
                    this.loadingFile();
                });
                EmptyIFiles.name = this.selectedFiles[obj].name;
                EmptyIFiles.type = this.selectedFiles[obj].type;
                EmptyIFiles.deviceStorageId.replace(" ", "_") + obj;
                this.tab.push(EmptyIFiles);
            });
            if (this.selectedExtraFiles) {
                // send extra files to firestorage
                Object.keys(this.selectedExtraFiles).map((obj) => {
                    const EmptyIFiles = {
                        name: "",
                        deviceStorageId: "",
                        downloadURL: "",
                    };
                    EmptyIFiles.deviceStorageId =
                        "extra_" + this.selectedExtraFiles[obj].name;
                    const id = this.currentUser.Id +
                        "/" +
                        //this.traducteur.uid
                        this.currentUser.Id +
                        "/" +
                        // this.traducteur.uid this.traducteurName.replace(' ', '_')
                        this.currentUser.Id +
                        "/" +
                        "extra_" +
                        EmptyIFiles.deviceStorageId;
                    this.storageRef = this.hiero.Storage.ref(id);
                    this.storageRef.put(this.selectedExtraFiles[obj]).on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                        const float = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        this.progress = +float.toFixed();
                        console.log("Upload is " + this.progress + "% done");
                        this.storageRef.getDownloadURL().then((snap) => {
                            EmptyIFiles.downloadURL = snap;
                        });
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED: // or 'paused'
                                console.log("Upload is paused");
                                break;
                            case firebase.storage.TaskState.RUNNING: // or 'running'
                                console.log("Upload is running");
                                break;
                        }
                    }, (error) => { }, () => {
                        this.loadingFile();
                    });
                    EmptyIFiles.name = this.selectedExtraFiles[obj].name;
                    EmptyIFiles.type = this.selectedExtraFiles[obj].type;
                    EmptyIFiles.deviceStorageId.replace(" ", "_") + obj;
                    this.tabExtra.push(EmptyIFiles);
                });
            }
            this.EmptyDocument.original = this.tab;
            this.EmptyDocument.extra = this.tabExtra;
            const arrayDocuments = [];
            arrayDocuments[0] = this.EmptyDocument;
            this.EmptyPrestation.srcLanguageIso639 = this.formValue.srcLang;
            this.EmptyPrestation.price.ttc = this.isSelectedTrad[0].priceTTC;
            this.EmptyPrestation.price.traducteurHT = this.isSelectedTrad[0].priceHT;
            this.EmptyPrestation.price.tva = this.TVA;
            this.EmptyPrestation.price.tvaValue = this.isSelectedTrad[0].tvaValue;
            if (this.formValue.type === "asser")
                this.EmptyPrestation.srcCountryCode = this.formValue.originLang;
            else
                this.EmptyPrestation.srcCountryCode = "";
            this.EmptyPrestation.destLanguageIso639 = this.formValue.destLang;
            this.EmptyPrestation.traducteurId = translatorName;
            this.EmptyPrestation.documents = arrayDocuments;
            this.EmptyPrestation.traducteurId = this.tradId;
            this.EmptyPrestation.uid = this.tradId; // Id traductor
            this.EmptyPrestation.deviceStorageId = this.currentUser.Id; // id prestation device
            this.EmptyPrestation.traducteur = this.isSelectedTrad[0].traducteur;
            this.EmptyPrestation.isProfessionalPrestation = true;
            let typePrestation;
            if (this.formValue.type === "asser")
                typePrestation = "Traduction assermentée";
            else
                typePrestation = "Traduction classique";
            let originLangISO369;
            if (this.formValue.type === "asser")
                originLangISO369 = this.formValue.originLang;
            else
                originLangISO369 = "classic";
            if (isDevis) {
                // data of devis
                const numero = Math.floor(Math.random() * 1000000 + 1);
                let devis = {
                    numero: numero,
                    date: new Date(),
                    srcLang: this.formValue.srcLang,
                    destLang: this.formValue.destLang,
                    priceTTC: this.isSelectedTrad[0].priceTTC,
                    priceHT: this.isSelectedTrad[0].priceHT,
                    priceTVA: this.TVA,
                    priceTVAVal: this.isSelectedTrad[0].tvaValue,
                    traducteur: this.tradId,
                    traducteurName: translatorName,
                    pagesNumber: this.pagesNumber,
                    typePrestation: typePrestation,
                    professionnelId: this.professionnelId,
                    purchaseOrder: this.formValue.bon,
                    // special Devis
                    professionnelData: this.professionnelData,
                    professionnelUid: this.hiero.Auth.User.Id,
                    originLangISO369: originLangISO369,
                    documentType: this.formValue.doctype,
                    docFile: this.documentsFileUpload,
                    tradId: this.tradId,
                    budget: this.isSelectedTrad[0].priceTTC,
                    presta: this.EmptyPrestation,
                    urgency: this.formValue.urgency,
                    description: this.formValue.description,
                    state: EnumDevisState.WaitingForValidation,
                };
                if (this.hiero.Auth.User.Profile.isSeineMaritime)
                    devis["isDevisSeineMaritime"] = true;
                if (this.hiero.Auth.User.Profile.isFidinam)
                    devis["isDevisFidinam"] = true;
                if (this.hiero.Auth.User.Profile.isExpat)
                    devis["isDevisExpat"] = true;
                yield firebase
                    .firestore()
                    .collection("devis")
                    .add(devis)
                    .then(() => {
                    firebase
                        .firestore()
                        .collection("mail")
                        .add({
                        bcc: [
                            "B.balsomi@hiero-solution.com",
                            "a.luis@hiero-solution.com",
                            "w.moingt@hiero-solution.com",
                            "n.ziani@hiero-solution.com",
                        ],
                        message: {
                            subject: "Nouveau devis",
                            text: "Un nouveau devis est disponible",
                            html: `  <div style=" font-family: "Helvetica Neue", sans-serif; font-size: 20px;"> <div style="font-weight: bold"> <img src="https://firebasestorage.googleapis.com/v0/b/hiero-prod.appspot.com/o/Logos%2Fbird.png?alt=media&token=fa72f85d-1c09-48c1-ab47-ee7cde0eb80b" alt="" width="50" /> Hierø Solution </div> <hr /> <h3 style="text-align: center">Devis généré</h3> <p>Bonjour,</p><p style="line-height: 2; text-align:justify"> Un devis a été générer dans notre système par le client ${this.professionnelData.businessName}. Le numéros du devis est ${numero}. Retrouve les détails de ce devis dans ton espace admin. <p>Bien cordialement,</p> <p>L\'équipe Hierø</p> <hr /><div><h3 style= "text-align:center">Description du devis</h3><p > ${this.formValue.description}</p></div> </div> `,
                        },
                    })
                        .then(() => console.log("Queued email for delivery!"));
                });
            }
            else {
                const newPrestation = yield Prestation.Create(this.currentUser, this.EmptyPrestation);
                // data of facture
                yield firebase
                    .firestore()
                    .collection("facture")
                    .add({
                    numero: Math.floor(Math.random() * 1000000 + 1),
                    date: new Date(),
                    srcLang: this.formValue.srcLang,
                    destLang: this.formValue.destLang,
                    priceTTC: this.isSelectedTrad[0].priceTTC,
                    priceHT: this.isSelectedTrad[0].priceHT,
                    priceTVA: this.TVA,
                    priceTVAVal: this.isSelectedTrad[0].tvaValue,
                    traducteur: this.tradId,
                    traducteurName: translatorName,
                    pagesNumber: this.pagesNumber,
                    typePrestation: typePrestation,
                    professionnelId: this.professionnelId,
                    purchaseOrder: this.formValue.bon,
                    // special facture
                    prestation: newPrestation.Id,
                });
                // add information form professional command
                yield commands
                    .addService({
                    professionnelId: this.professionnelId,
                    srcLanguageIso639: this.formValue.srcLang,
                    originLanguage: originLangISO369,
                    destLanguageIso639: this.formValue.destLang,
                    documentType: this.formValue.doctype,
                    docFile: this.documentsFileUpload,
                    traducteurName: translatorName,
                    traducteurId: this.tradId,
                    budget: this.isSelectedTrad[0].priceTTC,
                    createdAt: new Date(),
                    prestationId: newPrestation.Id,
                    prestationInfo: this.EmptyPrestation,
                    pagesNumber: this.pagesNumber,
                    urgency: this.formValue.urgency,
                    description: this.formValue.description,
                    devisId: null,
                })
                    .then(() => {
                    firebase
                        .firestore()
                        .collection("mail")
                        .add({
                        bcc: [
                            "B.balsomi@hiero-solution.com",
                            "a.luis@hiero-solution.com",
                            "w.moingt@hiero-solution.com",
                            "n.ziani@hiero-solution.com",
                        ],
                        message: {
                            subject: "Nouvelle prestation",
                            text: "Nouvelle commande",
                            html: `  <div style=" font-family: "Helvetica Neue", sans-serif; font-size: 20px;"> <div style="font-weight: bold"> <img src="https://firebasestorage.googleapis.com/v0/b/hiero-prod.appspot.com/o/Logos%2Fbird.png?alt=media&token=fa72f85d-1c09-48c1-ab47-ee7cde0eb80b" alt="" width="50" /> Hierø Solution </div> <hr /> <h3 style="text-align: center">Nouvelle prestation</h3> <p>Bonjour,</p><p style="line-height: 2; text-align:justify"> Nous avons une nouvelle commande dans notre système du client ${this.professionnelData.businessName}. Le numéro de la prestation est ${newPrestation.Id}. Retrouve les détails de cette prestation dans ton espace admin. <p>Bien cordialement,</p> <p>L\'équipe Hierø</p> <hr /><div><h3 style= "text-align:center">Description</h3><p > ${this.formValue.description}</p></div> </div> `,
                        },
                    })
                        .then(() => console.log("Queued email for delivery!"));
                });
                this.updateBudget();
            }
        });
    }
    loadingFile() {
        this.show = false;
        this.showLoading = true;
        Swal.fire({
            title: this.localisation.localise("reset_password_send"),
        }).then(() => {
            this.activeModal.close();
        });
    }
    updateBudget() {
        if (this.professionnelData.budget > 0) {
            this.professionnelData.budget =
                this.professionnelData.budget - this.isSelectedTrad[0].priceTTC;
            this.hiero.DB.collection("professionnels")
                .doc(this.professionnelId)
                .update({
                budget: this.professionnelData.budget,
            });
        }
    }
}
