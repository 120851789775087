import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: "./check-modal.component.html",
  styleUrls: ["./check-modal.component.scss"],
})
export class CheckModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
