import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as firebase from "firebase";
import { environment } from "../../../../../environments/environment";

@Component({
  templateUrl: "./paiement-modal.component.html",
  styleUrls: ["./paiement-modal.component.scss"],
})
export class PaiementModalComponent implements OnInit {
  public payForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.createFormLogin();
  }

  private createFormLogin() {
    this.payForm = this.formBuilder.group({
      solde: ["", Validators.required],
    });
  }

  public onSubmit() {
    this.pay(this.payForm.value.solde);
  }

  public async pay(price) {
    let db = firebase.firestore();

    const docRef = await db
      .collection("customers")
      .doc(firebase.auth().currentUser.uid)
      .collection("checkout_sessions")
      .add({
        payment_method_types: ["sepa_debit"],
        line_items: [
          {
            price_data: {
              currency: "eur",
              product_data: {
                name: "Augmentation du budget",
              },
              unit_amount: price * 100,
            },
            quantity: 1,
          },
        ],
        mode: "payment",
        allow_promotion_codes: true,
        success_url: environment.resetRedirectUrl + "/app/profile",
        cancel_url: window.location.origin,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url }: any = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  }
}
