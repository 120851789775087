import { Injectable } from "@angular/core";
import { HieroBDD } from "./hierobdd.service";
import { COLLECTION } from "../../../common/src/bdd/utility/collections";
import { environment } from "../../environments/environment";
import { ITraducteur } from "../../../common/src/bdd/interfaces/ITraducteur";
import { ITraducteurCopie } from "../routes/app/commandes/commande/commande.component";

@Injectable()
export class TranslatorService {
  private translatorServices = [];
  private TVA: number = 1.2;

  constructor(private hiero: HieroBDD) {}

  public getSourceLanguage(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.hiero.DB.collection(COLLECTION.APP_INDEXES)
        .doc("services")
        .get()
        .then((snapshot) => {
          resolve(snapshot.get("languages"));
        })
        .catch(reject);
    });
  }

  private getTranslatorServices(srcLang) {
    return this.hiero.DB.collection(COLLECTION.TRADUCTEUR_SERVICES)
      .where("srcLanguageIso639", "==", srcLang)
      .get()
      .then((snapshot) => {
        this.translatorServices = snapshot.docs.map((doc) => doc.data());
      });
  }

  public async getDestinationLang(srcLang: string) {
    await this.getTranslatorServices(srcLang);

    // get all destination languages that have the source Language
    const destLang = this.translatorServices.map(
      (data) => data.destLanguageIso639
    );

    // return the destination languages without the duplicate
    return [...new Set(destLang)];
  }

  private async getInterpretersService(destLang: string): Promise<any> {
    let newTranslatorService = this.translatorServices.filter(
      (data) => data.destLanguageIso639 === destLang
    );

    // get all interpreters
    let interpreters = await this.getAllInterpreters();

    // filter the interpreter list with the one that offer the service asked for
    return interpreters
      .filter((inter) => inter)
      .filter((inter) =>
        newTranslatorService.some((doc) => doc.traducteurId === inter.id)
      );
  }

  private getAllInterpreters(): Promise<any> {
    if (this.hiero.Auth.User.Profile.isSeineMaritime)
      return this.hiero.DB.collection(COLLECTION.TRADUCTEURS)
        .where("uid", "==", environment.uidSeineMaritime)
        .get()
        .then((snap) => {
          return snap.docs
            .filter(
              (element) =>
                element.data().interpreter == "true" ||
                element.data().interpreter == true
            )
            .map((element) => {
              return {
                id: element.id,
                translatorData: element.data() as ITraducteur,
              };
            });
        });
    else if (this.hiero.Auth.User.Profile.isANR)
      return this.hiero.DB.collection(COLLECTION.TRADUCTEURS)
        .where("uid", "==", environment.uidANR)
        .get()
        .then((snap) => {
          return snap.docs
            .filter(
              (element) =>
                element.data().interpreter == "true" ||
                element.data().interpreter == true
            )
            .map((element) => {
              return {
                id: element.id,
                translatorData: element.data() as ITraducteur,
              };
            });
        });
    else
      return this.hiero.DB.collection(COLLECTION.TRADUCTEURS)
        .get()
        .then((snap) => {
          return snap.docs
            .filter(
              (element) =>
                element.data().interpreter == "true" ||
                element.data().interpreter == true
            )
            .map((element) => {
              if (
                element.data().uid != environment.uidSeineMaritime &&
                element.data().uid != environment.uidANR
              )
                return {
                  id: element.id,
                  translatorData: element.data() as ITraducteur,
                };
            });
        });
  }

  public async getProperInterpreters(destLang: string, extHours: string) {
    let interpreters = await this.getInterpretersService(destLang);
    return interpreters.map((element) => {
      if (element) {
        // calcul du prix par rapport au nombre d'heure de l'intervention
        let price = this.calculPrice(
          element.translatorData.interpretationPrice,
          extHours
        );

        const data = {
          translatorId: element.id,
          translatorData: element.translatorData,
          price: price,
        };

        return data;
      }
    });
  }

  private calculPrice(priceHour: number, extHours: string) {
    let d = extHours;
    let a = d.split(":"); // split it at the colons
    let time = +a[0] + +a[1] / 60;

    const priceHT = time * priceHour;
    let TVAval = 0;
    let priceTTC = 0;

    if (this.hiero.Auth.User.Profile.isExpat) {
      const priceMarge = (priceHT / 100) * 18;
      TVAval = ((priceHT + priceMarge) / 100) * 10;
      priceTTC = +(priceHT + TVAval + priceMarge).toFixed(2);
    } else {
      TVAval = (priceHT / 100) * 20;
      priceTTC = +(priceHT + TVAval).toFixed(2);
    }

    return {
      priceHT: priceHT,
      priceTVAVal: TVAval,
      priceTTC: priceTTC,
      priceTVA: this.TVA,
    };
  }

  public getTranslator(id: string) {
    return this.hiero.DB.collection(COLLECTION.TRADUCTEURS)
      .doc(id)
      .get()
      .then((snapshot) => {
        return {
          id: snapshot.id,
          data: snapshot.data(),
        };
      });
  }
}
