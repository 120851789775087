<div *ngIf="command || interpretation" class="mb-1">
  <div
    [ngClass]="
      hiero.Auth.User.Profile.isSeineMaritime
        ? 'my-div-seine-maritime border'
        : hiero.Auth.User.Profile.isFidinam
        ? 'my-div-fidinam border'
        : 'my-div border'
    "
  >
    <p class="clickable m-2" [routerLink]="['/app/commandes']">
      {{ "section_order_title" | localise }} /
      <span class="txt">
        {{ "commande_numero" | localise }}
        {{ command ? command.prestationId : interpretation.id }}
      </span>
    </p>
  </div>
  <div class="row container-fluid mt-3 justify-content-between">
    <div class="col-md-7" *ngIf="command">
      <div class="row border">
        <div class="my-p p-3">
          <h4 class="mb-3">
            {{ "progress_commande" | localise }}
          </h4>
          <button
            [ngClass]="
              hiero.Auth.User.Profile.isSeineMaritime
                ? 'btn my-btn-seine-maritime btn-default mr-3'
                : hiero.Auth.User.Profile.isFidinam
                ? 'btn my-btn-fidinam btn-default mr-3'
                : 'btn my-btn btn-default mr-3'
            "
          >
            {{ "status_orders" | localise }}
          </button>
          <span class="my-txt">{{
            command.prestationInfo.state | prestationStateName
          }}</span>
          <p class="my-text mt-3">
            {{ "single_commande_paragraphe" | localise }}
          </p>
          <div
            *ngIf="
              command.prestationInfo.state === 'waiting-for-client-to-validate'
            "
            class="m-1 d-flex flex-row-reverse"
          >
            <button class="btn my-btn1">
              {{ "finaliser_commande" | localise }}
            </button>
          </div>
        </div>
      </div>
      <div class="row border mt-3">
        <div class="my-p p-3">
          <h4 class="mb-3">
            {{ "intervention_details" | localise }}
          </h4>
          <p>
            <span>{{ " commande_receive" | localise }} </span> :
            <span>{{ command.createdAt.toDate() | date : short }} </span>
          </p>
          <p>
            <span>{{ "table_header_type_de_document" | localise }}</span> :
            <span>{{ command.documentType }}</span>
          </p>
          <p>
            <span>{{ "doc_a_traduire" | localise }}</span>
            <span> {{ command.pagesNumber }}</span>
          </p>
          <p>
            <span>{{ "table_header_nb_pages" | localise }} </span>
            <span> {{ command.pagesNumber }}</span>
          </p>
        </div>
        <div class="m-auto">
          <p>
            <span>{{ "trad_asser" | localise }} : </span>
            <span
              *ngIf="
                command.prestationInfo.traducteur.assermentation === true ||
                command.prestationInfo.traducteur.assermentation === 'true'
              "
              >{{ "prestation_document_confirm_oui" | localise }}</span
            >
            <span *ngIf="!command.prestationInfo.traducteur.assermentation">{{
              "prestation_document_confirm_non" | localise
            }}</span>
          </p>
          <p *ngIf="command.originLanguage != 'classic'">
            <span>{{ "pays_origine" | localise }} : </span>
            <span>{{ command.prestationInfo.srcCountryCode | country }} </span>
          </p>
          <p>
            <span>{{ "menu_langue" | localise }} : </span>
            <span
              >{{ command.srcLanguageIso639 | iso639ToNative }}
              {{ "services_dst_lang" | localise }}
              {{ command.destLanguageIso639 | iso639ToNative }}</span
            >
          </p>
          <p>
            <span>{{ "total_ht" | localise }} : </span>
            <span>{{ command.prestationInfo.price.traducteurHT }} €</span>
          </p>
          <p>
            <span>{{ "total_ttc" | localise }} : </span>
            <span>{{ command.prestationInfo.price.ttc }} €</span>
          </p>
        </div>
      </div>
    </div>

    <div class="col-md-7" *ngIf="interpretation">
      <div class="row border">
        <div class="my-p p-3">
          <h4 class="mb-3">
            {{ "progress_commande" | localise }}
          </h4>
          <button
            [ngClass]="
              hiero.Auth.User.Profile.isSeineMaritime
                ? 'btn my-btn-seine-maritime btn-default mr-3'
                : hiero.Auth.User.Profile.isFidinam
                ? 'btn my-btn-fidinam btn-default mr-3'
                : 'btn my-btn btn-default mr-3 '
            "
            style="pointer-events: none"
          >
            {{ "status_orders" | localise }}
          </button>
          <span class="my-txt">{{
            interpretation.interpretationData.state | prestationStateName
          }}</span>
          <p class="my-text mt-3">
            {{ "single_commande_paragraphe" | localise }}
          </p>
          <div
            *ngIf="
              interpretation.interpretationData.state ===
              'accepted-by-translator'
            "
            class="m-1 d-flex flex-row-reverse"
          >
            <button class="btn my-btn" (click)="getRooms()">
              {{ "start_visio" | localise }}
            </button>
          </div>
          <div
            *ngIf="
              interpretation.interpretationData.state ===
              'waiting-for-client-to-validate'
            "
            class="m-1 d-flex flex-row-reverse"
          >
            <button class="btn my-btn1">
              {{ "finaliser_commande" | localise }}
            </button>
          </div>
        </div>
      </div>
      <div class="row border mt-3">
        <div class="my-p p-3">
          <h4 class="mb-3">
            {{ "intervention_details" | localise }}
          </h4>
          <p>
            <span>{{ " commande_receive" | localise }} </span> :
            <span
              >{{
                interpretation.interpretationData.sendTime.toDate()
                  | date : "dd/MM/yyyy "
              }}
            </span>
          </p>
          <p>
            <span>{{ " type_intervention" | localise }} </span> :
            <span
              *ngIf="
                interpretation.interpretationData.missions.intervention.id == 1
              "
              >{{ "menu_label_sur_place" | localise }}
            </span>
            <span
              *ngIf="
                interpretation.interpretationData.missions.intervention.id == 2
              "
            >
              {{ "menu_label_tel" | localise }}
            </span>
            <span
              *ngIf="
                interpretation.interpretationData.missions.intervention.id == 3
              "
              >{{ "menu_label_visio" | localise }}
            </span>
          </p>

          <p>
            <span>{{ "menu_label_event_name" | localise }}</span> :
            <span>{{ interpretation.interpretationData.event }}</span>
          </p>
          <p>
            <span>{{ "menu_langue" | localise }} : </span>
            <span
              >{{
                interpretation.interpretationData.missions.srcLang
                  | iso639ToNative
              }}
              {{ "services_dst_lang" | localise }}
              {{
                interpretation.interpretationData.missions.destLang
                  | iso639ToNative
              }}</span
            >
          </p>
          <p>
            <span>{{ "total_ht" | localise }} : </span>
            <span>{{ interpretation.interpretationData.price.priceHT }} €</span>
          </p>
          <p>
            <span>{{ "total_ttc" | localise }} : </span>
            <span
              >{{ interpretation.interpretationData.price.priceTTC }} €</span
            >
          </p>
        </div>
        <div class="m-auto">
          <p>
            <span>{{ "intervention_time_date" | localise }} : </span>
            <span>{{
              interpretation.interpretationData.missions.date.toDate()
                | date : "short"
            }}</span>
          </p>
          <p>
            <span>{{ "delai_intervention" | localise }} : </span>
            <span>{{
              interpretation.interpretationData.missions.extHours
            }}</span>
          </p>
          <p
            *ngIf="
              interpretation.interpretationData.missions.intervention.id == 1
            "
          >
            <span>{{ "address_intervention" | localise }} : </span>
            <span>{{
              interpretation.interpretationData.missions.address
            }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4 border">
      <div class="my-p p-3">
        <div class="mb-3">
          <h4>{{ "prestation_order_messaging_title" | localise }}</h4>
        </div>
        <p
          [ngClass]="
            hiero.Auth.User.Profile.isSeineMaritime
              ? 'discuss-seine-maritime'
              : hiero.Auth.User.Profile.isFidinam
              ? 'discuss-fidinam'
              : 'discuss'
          "
        >
          {{ "commande_discuss" | localise }}
        </p>
        <form class="mt-5" [formGroup]="sendmessage" (ngSubmit)="sendMsg()">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Tapez votre message"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              formControlName="message"
            />
            <button
              [ngClass]="
                hiero.Auth.User.Profile.isSeineMaritime
                  ? 'btn my-btn-seine-maritime'
                  : hiero.Auth.User.Profile.isFidinam
                  ? 'btn my-btn-fidinam'
                  : 'btn my-btn'
              "
              type="button"
              id="button-addn"
            >
              {{ "send" | localise }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="row container-fluid mt-3">
    <div class="col border p-3" *ngIf="command">
      <div class="mb-3">
        <h4>{{ "visioner_doc" | localise }}</h4>
      </div>
      <p>
        <span>{{ "auth_doc" | localise }}</span> <br />
        <span>{{ "doc_bug" | localise }}</span>
      </p>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              {{ "prestation_order_document_type" | localise }}
            </th>
            <th scope="col">{{ "visioner_doc" | localise }}</th>
            <th scope="col">{{ "progress_commande" | localise }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="myTD">{{ command.documentType | uppercase }}</td>

            <!-- <td class="downloadUrl">
              <a
                href="{{
                  command.prestationInfo.documents[0].original[0].downloadURL
                }}"
                ><i class="fa fa-file-pdf-o"></i>
                {{ "section_consulter" | localise }}</a
              >
            </td> -->

            <td
              [ngClass]="
                hiero.Auth.User.Profile.isSeineMaritime
                  ? 'downloadUrl-seine-maritime'
                  : hiero.Auth.User.Profile.isFidinam
                  ? 'downloadUrl-fidinam'
                  : 'downloadUrl'
              "
            >
              <div
                class="btn-group ml-1"
                *ngFor="let doc of command.prestationInfo.documents[0].original"
              >
                <button
                  [ngClass]="
                    hiero.Auth.User.Profile.isSeineMaritime
                      ? 'btn btn-outline-seine-maritime'
                      : hiero.Auth.User.Profile.isFidinam
                      ? 'btn btn-outline-fidinam'
                      : 'btn btn-outline-primary'
                  "
                  (click)="getFile(doc.deviceStorageId)"
                >
                  <i class="fa fa-file-pdf-o"></i>
                  <!-- {{ "section_consulter" | localise }} -->
                  {{ doc.deviceStorageId }}
                </button>
              </div>
            </td>

            <td
              class="my-td-over"
              *ngIf="
                command.prestationInfo.state ===
                  'waiting-for-client-to-validate' ||
                command.prestationInfo.state === 'validated-pro'
              "
            >
              {{ "state_translated_order" | localise }}
              <div
                class="btn-group ml-1"
                *ngFor="
                  let doc of command.prestationInfo.documents[0].translated
                "
              >
                <button
                  class="btn btn-outline-success"
                  (click)="getFile(doc.deviceStorageId)"
                >
                  <i class="fa fa-file-pdf-o"></i>
                  {{ doc.deviceStorageId }}
                </button>
              </div>
            </td>
            <td
              class="my-td"
              *ngIf="
                command.prestationInfo.state !=
                  'waiting-for-client-to-validate' &&
                command.prestationInfo.state != 'validated-pro'
              "
            >
              {{ command.prestationInfo.state | prestationStateName }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Interpretation bloc -->

  <!-- <div *ngIf="loadInterpretation.length > 0">
    <div *ngFor="let inter of loadInterpretation">
      <div *ngIf="inter.data.state === 'waiting-for-translator'">
        <div class="my-div">
          <p class="m-1">
            {{ "section_order_title" | localise }} /
            <span> {{ "commande_numero" | localise }} {{ inter.id }}</span>
          </p>
        </div>
        <div class="row container-fluid mt-5">
          <div class="col-7 mr-5 border">
            <div class="d-flex">
              <div class="m-1">
                <p>{{ "progress_commande" | localise }}</p>
                <button class="btn my-btn btn-default mr-3">
                  {{ "status_orders" | localise }}
                </button>
                <span class="my-txt">{{
                  "accept_intervention" | localise
                }}</span>
                <p class="my-text mt-2">
                  {{ "single_commande_paragraphe" | localise }}
                </p>
              </div>
              <div class="m-1">
                <button class="btn my-btn">
                  {{ "final_intervention" | localise }}
                </button>
              </div>
            </div>
          </div>

          <div class="col-4 ml-1 border">
            <p class="m-1">
              {{ "prestation_order_messaging_title" | localise }}
              <span class="discuss">{{ "commande_discuss" | localise }}</span>
            </p>
            <form class="mt-5" [formGroup]="sendmessage" (ngSubmit)="sendMsg()">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Tapez votre message"
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  formControlName="message"
                />
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  id="button-adon"
                >
                  Envoyer
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="row container-fluid mt-5">
          <div class="col border">
            <div class="d-flex">
              <div class="my-p">
                <p class="m-2">{{ "intervention_details" | localise }}</p>
                <p class="m-2">
                  <span>{{ " commande_receive" | localise }} </span> :
                  <span
                    >{{ inter.data.missions.date | date: "dd/MM/YYYY" }}
                  </span>
                </p>
                <p class="m-2">
                  <span>{{ "type_intervention" | localise }}</span> :
                  <span>{{ inter.data.missions.intervention.name }}</span>
                </p>
                <p class="m-2">
                  <span>{{ "menu_langue" | localise }}</span>
                  <span>{{
                    inter.data.missions.srcLang.iso639 | iso639ToNative
                  }}</span>
                </p>
                <p class="m-2">
                  <span>{{ "delai_intervention" | localise }} </span>
                  <span>{{ inter.data.missions.extHours }}</span>
                </p>
              </div>
              <div class="m-auto">
                <p class="m-2">
                  <span>{{ "once_page_price_default" | localise }} : </span>
                  <span></span>
                </p>
                <p class="m-2">
                  <span>{{ "total_ht" | localise }} : </span>
                  <span>{{ inter.data.price }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- end interpretation-->
</div>
