<div class="row">
  <!-- BEGIN col-3 -->
  <div class="col-xl-4 col-md-6">
    <div class="card widget widget-stats bg-white">
      <div class="stats-icon stats-icon-lg">
        <i class="fa fa-language fa-fw"></i>
      </div>
      <div class="stats-content">
        <div class="stats-title text-dark">
          <span class="couleur">
            <i class="h5 fa fa-pencil fa-fw"></i>
          </span>
          <span class="h6 ml-3">{{ "dashboard_pro_asser" | localise }}</span>
        </div>
        <div class="stats-number text-dark">
          <span class="mr-3">{{ numberTradAsser }} </span>
          <span class="ml-5 h6 font-italic">
            {{ "dashboard_pro_as_of" | localise }}
            {{ dateTime | date : "dd/MM/yyyy" }}</span
          >
        </div>
        <div class="text-right mb-3">
          <span
            [ngClass]="
              rateTradAsser > 0
                ? 'ml-5 text-success font-weight-bold font-italic h6'
                : 'ml-5 text-danger font-weight-bold font-italic h6'
            "
            >{{ rateTradAsser > 0 ? "+" + rateTradAsser : rateTradAsser }}%
            {{ "dashboard_compared_last_month" | localise }}</span
          >
        </div>
        <div class="stats-progress progress">
          <div
            [ngClass]="
              rateTradAsser > 0
                ? 'progress-bar bg-success'
                : 'progress bar bg-danger'
            "
            [style.width.%]="rateTradAsser > 0 ? rateTradAsser : -rateTradAsser"
          ></div>
        </div>
        <div class="stats-desc text-dark">
          {{ "dashboard_pro_evolution_month" | localise }}
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-md-6">
    <div class="card widget widget-stats bg-white">
      <div class="stats-icon stats-icon-lg">
        <i class="fa fa-language fa-fw"></i>
      </div>
      <div class="stats-content">
        <div class="stats-title text-dark">
          <span class="couleur">
            <i class="h5 fa fa-pencil fa-fw"></i>
          </span>
          <span class="h6 ml-3">{{ "dashboard_pro_classic" | localise }}</span>
        </div>
        <div class="stats-number text-dark">
          <span class="mr-3">{{ numberTradClassic }} </span>
          <span class="ml-5 h6 font-italic">
            {{ "dashboard_pro_as_of" | localise }}
            {{ dateTime | date : "dd/MM/yyyy" }}</span
          >
        </div>
        <div class="text-right mb-3">
          <span
            [ngClass]="
              rateTradClassic > 0
                ? 'ml-5 text-success font-weight-bold font-italic h6'
                : 'ml-5 text-danger font-weight-bold font-italic h6'
            "
            >{{
              rateTradClassic > 0 ? "+" + rateTradClassic : rateTradClassic
            }}% {{ "dashboard_compared_last_month" | localise }}</span
          >
        </div>
        <div class="stats-progress progress">
          <div
            [ngClass]="
              rateTradClassic > 0
                ? 'progress-bar bg-success'
                : 'progress bar bg-danger'
            "
            [style.width.%]="
              rateTradClassic > 0 ? rateTradClassic : -rateTradClassic
            "
          ></div>
        </div>
        <div class="stats-desc text-dark">
          {{ "dashboard_pro_evolution_month" | localise }}
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-md-6">
    <div class="card widget widget-stats bg-white">
      <div class="stats-icon stats-icon-lg">
        <i class="fa fa-comment fa-fw"></i>
      </div>
      <div class="stats-content">
        <div class="stats-title text-dark">
          <span class="couleur">
            <i class="h5 fa fa-comment fa-fw"></i>
          </span>
          <span class="h6 ml-3">{{ "dashboard_pro_inter" | localise }}</span>
        </div>
        <div class="stats-number text-dark">
          <span class="mr-3">{{ numberInterpretation }} </span>
          <span class="ml-5 h6 font-italic">
            {{ "dashboard_pro_as_of" | localise }}
            {{ dateTime | date : "dd/MM/yyyy" }}</span
          >
        </div>
        <div class="text-right mb-3">
          <span
            [ngClass]="
              rateInter > 0
                ? 'ml-5 text-success font-weight-bold font-italic h6'
                : 'ml-5 text-danger font-weight-bold font-italic h6'
            "
            >{{ rateInter > 0 ? "+" + rateInter : rateInter }}%
            {{ "dashboard_compared_last_month" | localise }}</span
          >
        </div>
        <div class="stats-progress progress">
          <div
            [ngClass]="
              rateInter > 0
                ? 'progress-bar bg-success'
                : 'progress bar bg-danger'
            "
            [style.width.%]="rateInter > 0 ? rateInter : -rateInter"
          ></div>
        </div>
        <div class="stats-desc text-dark">
          {{ "dashboard_pro_evolution_month" | localise }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col-xl-8 col-lg-6">
    <div class="card widget widget-stats bg-white">
      <div class="row">
        <div class="col-xl-7 col-md-6">
          <div class="stats-icon stats-icon-lg"></div>
          <div class="stats-content">
            <div class="stats-title text-dark">
              <span class="couleur">
                <i class="h5 fa fa-briefcase fa-fw"></i>
              </span>
              <span class="h5 ml-3">{{ phrase }}</span>
            </div>
            <div class="text-dark mb-4">
              <span class="stats-number mr-1 couleur"
                >{{ budget.toFixed(2) }} &euro;
              </span>
              <span class="h6 font-italic text-center">
                <input
                  type="text"
                  [placeholder]="'dashboard_pro_budget' | localise"
                  disabled="true"
                />
                <input
                  type="text"
                  placeholder=" {{ deductedBudgetMonth.toFixed(2) }} &euro; "
                  disabled="true"
                  class="input2"
                />
              </span>
            </div>
            <div class="stats-number text-dark">
              <span class="h6 font-italic">
                {{ "dashboard_pro_amount_month" | localise }}
              </span>
              <span class="couleur">
                <i class="h5 fa fa-question-circle fa-fw"></i>
              </span>
            </div>

            <table class="table mt-3">
              <tbody>
                <ng-container *ngIf="commandList.length != 0; else elseCmdList">
                  <ng-container
                    *ngFor="let command of commandList; let i = index"
                  >
                    <tr class="table-light">
                      <td scope="row" *ngIf="i % 2 == 0">
                        <div class="englob">
                          <button
                            *ngIf="
                              commandList[i].translationData;
                              else interButton
                            "
                            class="btn btn-dark mt-n3 mr-2"
                          >
                            {{
                              commandList[i].translationData.budget.toFixed(2)
                            }}
                            &euro;
                          </button>
                          <ng-template #interButton>
                            <button class="btn btn-dark mt-n3 mr-2">
                              {{
                                commandList[
                                  i
                                ].interpretationData.price.priceTTC.toFixed(2)
                              }}
                              &euro;
                            </button>
                          </ng-template>
                          <div class="type_nom">
                            <div class="type">
                              {{
                                commandList[i].translationData
                                  ? commandList[i].translationData.documentType
                                      .length > 20
                                    ? commandList[
                                        i
                                      ].translationData.documentType.substr(
                                        0,
                                        20
                                      ) + "..."
                                    : commandList[i].translationData
                                        .documentType
                                  : commandList[i].interpretationData.missions
                                      .intervention.name.length > 20
                                  ? commandList[
                                      i
                                    ].interpretationData.missions.intervention.name.substr(
                                      0,
                                      17
                                    ) + "..."
                                  : commandList[i].interpretationData.missions
                                      .intervention.name
                              }}
                            </div>
                            <div
                              class="name"
                              *ngIf="
                                commandList[i].translationData;
                                else interName
                              "
                            >
                              <!-- {{command.originLanguage!="classic"? ('dashboard_traductor_asser' | localise): ('dashboard_traductor_classic' | localise)}} -->
                              {{
                                commandList[i].type === "Traduction assermentée"
                                  ? "traduction asser..."
                                  : "traduction classique"
                              }}
                            </div>
                            <ng-template #interName>
                              <div class="name">
                                {{ commandList[i].interpretationData.event }}
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </td>
                      <td scope="row" *ngIf="i % 2 == 0">
                        <div class="englob" *ngIf="commandList[i + 1]">
                          <button
                            *ngIf="
                              commandList[i + 1].translationData;
                              else interButton
                            "
                            class="btn btn-dark mt-n3 mr-2"
                          >
                            {{
                              commandList[i + 1].translationData.budget.toFixed(
                                2
                              )
                            }}
                            &euro;
                          </button>
                          <ng-template #interButton>
                            <button class="btn btn-dark mt-n3 mr-2">
                              {{
                                commandList[
                                  i + 1
                                ].interpretationData.price.priceTTC.toFixed(2)
                              }}
                              &euro;
                            </button>
                          </ng-template>
                          <div class="type_nom">
                            <div class="type">
                              {{
                                commandList[i + 1].translationData
                                  ? commandList[i + 1].translationData
                                      .documentType.length > 20
                                    ? commandList[
                                        i + 1
                                      ].translationData.documentType.substr(
                                        0,
                                        20
                                      ) + "..."
                                    : commandList[i + 1].translationData
                                        .documentType
                                  : commandList[i + 1].interpretationData
                                      .missions.intervention.name.length > 20
                                  ? commandList[
                                      i + 1
                                    ].interpretationData.missions.intervention.name.substr(
                                      0,
                                      17
                                    ) + "..."
                                  : commandList[i + 1].interpretationData
                                      .missions.intervention.name
                              }}
                            </div>
                            <div
                              class="name"
                              *ngIf="
                                commandList[i + 1].translationData;
                                else interName
                              "
                            >
                              {{
                                commandList[i].type === "Traduction assermentée"
                                  ? "traduction asser..."
                                  : "traduction classique"
                              }}
                            </div>
                            <ng-template #interName>
                              <div class="name">
                                {{
                                  commandList[i + 1].interpretationData.event
                                }}
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
                <ng-template #elseCmdList>
                  <div>{{ "dashboard_pro_no_amount_month" | localise }}</div>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-xl-5 col-md-6">
          <div class="stats-icon stats-icon-lg"></div>
          <div class="stats-content">
            <div class="stats-title text-dark">
              <span class="couleur">
                <i class="h5 fa fa-money fa-fw"></i>
              </span>
              <span class="h5 ml-3">{{
                "dashboard_pro_amount" | localise
              }}</span>
            </div>
            <div class="mb-4">
              <span class="stats-number couleur"
                >{{ deductedBudgetTotal.toFixed(2) }} &euro;
              </span>
              <span class="h6 font-italic text-center">
                <input
                  type="text"
                  [placeholder]="'dashboard_pro_amount_left' | localise"
                  disabled="disabled"
                  class="input1"
                />
                <input
                  type="text"
                  placeholder=" {{ budget.toFixed(2) }} &euro; "
                  disabled="true"
                  class="input2"
                />
              </span>
            </div>
            <div class="stats-number text-dark">
              <span class="h6 font-italic">
                {{ "dashboard_pro_amount_detail" | localise }}
              </span>
              <span class="couleur">
                <i class="h5 fa fa-question-circle fa-fw"></i>
              </span>
            </div>

            <div class="mt-4">
              <div class="couleur mb-3 justify-content-between">
                <span class="h5">{{ budgetAsser.toFixed(2) }} &euro; </span>
                <span style="font-size: 12px">{{
                  "traduction_assermentee" | localise
                }}</span>
              </div>
              <div class="text-info mb-3 justify-content-between">
                <span class="h5">{{ budgetClassic.toFixed(2) }} &euro; </span
                ><span style="font-size: 12px">
                  {{ "traduction_classic" | localise }}</span
                >
              </div>
              <div class="text-danger mb-3 justify-content-between">
                <span class="h5">{{ budgetInter.toFixed(2) }} &euro; </span>
                <span style="font-size: 12px">
                  {{ "interpretation" | localise }}</span
                >
              </div>
            </div>
            <div class="text-right mt-5" routerLink="../factures">
              <button class="btn btn-somme mt-5">
                {{ "dashboard_pro_amount_btn" | localise }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-lg-6">
    <div class="card">
      <canvas id="lineChart" height="425"></canvas>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col-xl-6 col-lg-6">
    <div class="card">
      <div class="font-weight-bold couleur mt-3 ml-2">
        <i class="mr-2 h4 fa fa-folder-open fa-fw h5"></i
        >{{ "dashboard_pro_command" | localise }}
      </div>
      <table class="table table-striped mt-3">
        <tbody>
          <ng-container *ngIf="commandList.length != 0; else elseCmdList2">
            <tr *ngFor="let command of commandList | slice : 0 : 3">
              <th scope="row">
                <div class="type mt-1">
                  <i class="h6 fa fa-folder-o fa-fw"></i>
                  {{
                    command.translationData
                      ? command.translationData.documentType
                      : command.interpretationData.event
                  }}
                </div>
                <div class="name ml-4">
                  Traducteur:
                  <span style="color: #06d0c5">
                    {{
                      command.translationData
                        ? command.translationData.traducteurName
                        : command.interpretationData.translatorName
                    }}
                  </span>
                </div>
              </th>
              <td>
                <div class="type mt-2">
                  {{ "order_language_from" | localise }}
                  {{
                    command.translationData
                      ? (command.translationData.srcLanguageIso639
                        | iso639ToNative)
                      : (command.interpretationData.missions.srcLang
                        | iso639ToNative)
                  }}
                </div>
                <div class="name">
                  {{ "order_language_to" | localise }}
                  {{
                    command.translationData
                      ? (command.translationData.destLanguageIso639
                        | iso639ToNative)
                      : (command.interpretationData.missions.destLang
                        | iso639ToNative)
                  }}
                </div>
              </td>
              <td>
                <div class="nom2">
                  {{ "dashboard_pro_command_date" | localise }}
                  <span class="name">
                    {{
                      command.translationData
                        ? (command.translationData.createdAt.toDate()
                          | date : "dd/MM/yyyy")
                        : (command.interpretationData.sendTime.toDate()
                          | date : "dd/MM/yyyy")
                    }}
                  </span>
                </div>
                <div
                  [style.color]="
                    command.translationData
                      ? command.translationData.prestationInfo.state ===
                          'validated' ||
                        command.translationData.prestationInfo.state ===
                          'validated-pro'
                        ? '#75af75'
                        : '#cf812d'
                      : command.interpretationData.state === 'validated' ||
                        command.interpretationData.state === 'validated-pro'
                      ? '#75af75'
                      : '#cf812d'
                  "
                >
                  <span class="text-dark nom2">
                    {{ "dashboard_pro_command_status" | localise }}</span
                  >

                  <span class="name">
                    {{
                      command.translationData
                        ? (
                            command.translationData.prestationInfo.state
                            | prestationStateName
                          ).length > 22
                          ? (
                              command.translationData.prestationInfo.state
                              | prestationStateName
                            ).substr(0, 19) + "..."
                          : (command.translationData.prestationInfo.state
                            | prestationStateName)
                        : (
                            command.interpretationData.state
                            | prestationStateName
                          ).length > 22
                        ? (
                            command.interpretationData.state
                            | prestationStateName
                          ).substr(0, 19) + "..."
                        : (command.interpretationData.state
                          | prestationStateName)
                    }}
                  </span>
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-template #elseCmdList2>
            <div class="ml-2">{{ "dashboard_pro_no_command" | localise }}</div>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>

  <div class="col-xl-6 col-lg-6">
    <div class="card">
      <div class="font-weight-bold couleur mt-3 ml-2">
        <i class="mr-2 h4 fa fa-folder-open fa-fw h5"></i
        >{{ "dashboard_pro_command_company" | localise }}
      </div>
      <table class="table table-striped mt-3">
        <tbody>
          <ng-container *ngIf="colleagueList.length != 0; else elseClgList">
            <tr *ngFor="let colleague of colleagueList | slice : 0 : 3">
              <th scope="row">
                <div class="type couleur">
                  <i class="fa fa-user-circle fa-fw"></i>
                  {{ colleague.name }}
                </div>
                <div class="name ml-4 font-italic mt-2">Professionnel</div>
              </th>
              <td>
                <div class="type mt-1 couleur">
                  {{ "dashboard_pro_command_company_date" | localise }}
                  <span class="">{{
                    colleague.data.createdAt
                      ? (colleague.data.createdAt.toDate()
                        | date : "dd/MM/yyyy")
                      : (colleague.data.sendTime.toDate() | date : "dd/MM/yyyy")
                  }}</span>
                </div>
                <div class="name mt-2">
                  {{ "tasks_price_ttc" | localise }}
                  {{
                    colleague.data.budget
                      ? colleague.data.budget.toFixed(2)
                      : colleague.data.priceTTC.toFixed(2)
                  }}
                  &euro;
                </div>
              </td>
              <td>
                <div>
                  {{ "dashboard_pro_command_company_type" | localise }}
                  <span class="type">
                    {{
                      colleague.data.originLanguage
                        ? colleague.data.originLanguage != "classic"
                          ? "traduction assermentée"
                          : "traduction classique"
                        : colleague.data.missions.intervention.name
                    }}
                  </span>
                </div>
                <div class="name mt-2">
                  <span class="text-dark nom2">
                    {{
                      "dashboard_pro_command_company_status" | localise
                    }}</span
                  >
                  <span class="font-italic text-danger">
                    {{
                      colleague.data.state
                        ? (colleague.data.state | prestationStateName).length >
                          22
                          ? (colleague.data.state | prestationStateName).substr(
                              0,
                              19
                            ) + "..."
                          : (colleague.data.state | prestationStateName)
                        : (
                            colleague.data.prestationInfo.state
                            | prestationStateName
                          ).length > 22
                        ? (
                            colleague.data.prestationInfo.state
                            | prestationStateName
                          ).substr(0, 19) + "..."
                        : (colleague.data.prestationInfo.state
                          | prestationStateName)
                    }}
                  </span>
                </div>
              </td>
              <td>
                <div></div>
                <i class="text-secondary h4 fa fa-comments fa-fw"></i>
                <i class="text-secondary h4 fa fa-folder-open fa-fw"></i>
              </td>
            </tr>
          </ng-container>
          <ng-template #elseClgList>
            <div class="ml-2">
              {{ "dashboard_pro_no_command_company" | localise }}
            </div>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
</div>
