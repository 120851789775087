<!-- mamadou -->
<div class="my-bg">
  <div class="container">
    <div class="row mt-5">
      <div class="col-8 col-md-8 mx-auto">
        <div class="banner">
          <img
            src="../../../../../assets/img/logo/seine_maritime_logo.png"
            alt="seine_maritime_logo"
            class="rounded mx-auto d-block"
            width="200px"
            height="200px"
          />
          <div class="d-flex p-20 flex-row-reverse">
            <app-language-dropdown class="lang btn"></app-language-dropdown>
          </div>
        </div>
        <div class="content border mb-3 ml-3">
          <div class="register-header">
            <div class="brand">
              <img
                src="../../../../../assets/img/logo/logo_hiero_small.png"
                style="margin-right: 10px; height: 60px"
                alt="logohiero"
              />
              {{ "signup_main_text" | localise }}
            </div>
          </div>
          <h4 class="pull-left mt-3 ml-3">
            {{ "reset_password_login" | localise }}
          </h4>
          <p class="pull-left mt-3 ml-3 txt">{{ "login_text" | localise }}</p>

          <form
            [formGroup]="loginForm.Group"
            novalidate
            class="margin-bottom-0"
            data-parsley-validate="true"
          >
            <app-form-input
              [parameters]="loginForm.GetInputElement('email')"
            ></app-form-input>
            <app-form-input
              [parameters]="loginForm.GetInputElement('password')"
            ></app-form-input>
            <app-form-general-error
              [submitForm]="loginForm"
            ></app-form-general-error>
            <div class="checkbox checkbox-css mb-5">
              <input type="checkbox" id="remember_me_checkbox" value="" />
              <label for="remember_me_checkbox">
                {{ "online" | localise }}
              </label>
            </div>
            <div class="d-flex flex-column my-div">
              <div class="login-buttons pull-right">
                <button
                  (click)="loginForm.Submit()"
                  [disabled]="!loginForm.Valid"
                  class="btn myColor"
                >
                  {{ "login_main_button" | localise }}
                </button>
              </div>
              <div class="m-t-20 m-b-20 p-b-20 text-inverse">
                <a
                  [routerLink]="['/compte', 'resetPassword']"
                  class="text-primary"
                  >{{ "login_forgot_password" | localise }}</a
                >
              </div>
            </div>
            <p class="text-center text-grey-darker my-copyright">
              {{ "login_main_copyright" | localise }}
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- end mamadou -->

<!--
<div class="login login-with-news-feed" style="min-height: 100vh">


  <div class="news-feed">
    <div class="news-image" style="background-image: url('../../../../assets/img/login-bg/login-bg-9.jpg')"></div>
    <div class="news-caption">
      <h4 class="caption-title">{{ 'login_caption_title_pro' | localise }}</h4>
      <p>
        {{ 'login_caption_subtitle_pro' | localise }}
      </p>
    </div>
  </div>
  <div class="right-content">
    <div class="d-flex p-20 flex-row-reverse">
      <app-language-dropdown></app-language-dropdown>
    </div>

    <div class="login-header">

      <div class="brand">
        <img src="../assets/img/logo/bird.png" height="60px" style="margin-right: 10px" />
        {{ 'login_main_logo' | localise }}
      </div>
      <div class="icon">
        <i class="fa fa-sign-in"></i>
      </div>
    </div>

    <div class="login-content">
      <h1>{{ 'login_main_title_pro' | localise }}</h1>
      <p>{{ 'login_main_subtitle_pro' | localise }}</p>
      <form [formGroup]="loginForm.Group" novalidate class="margin-bottom-0" data-parsley-validate="true">
        <h2 class="register-header">
          {{ 'login_main_connect' | localise }}
        </h2>
        <app-form-input [parameters]="loginForm.GetInputElement('email')"></app-form-input>
        <app-form-input [parameters]="loginForm.GetInputElement('password')"></app-form-input>
        <app-form-general-error [submitForm]="loginForm"></app-form-general-error>
        <div class="checkbox checkbox-css m-b-30">
          <input type="checkbox" id="remember_me_checkbox" value="" />
          <label for="remember_me_checkbox">
          Rester connecté
          </label>
        </div>
        <div class="login-buttons">
          <button (click)='loginForm.Submit()' [disabled]="!loginForm.Valid"
            class="btn btn-danger btn-block btn-lg">{{ 'login_main_button' | localise }}</button>
        </div>
        <div class="m-t-20 p-b-20 text-inverse">
          <a href="https://hiero-solution.com/solution-professionnelle/"
            class="text-success">{{ 'login_main_to_signup' | localise }}</a>
        </div>
        <div class="m-t-20 p-b-20 text-inverse">
          <a [routerLink]="['..', 'subscriptions']" class="text-success">{{ 'login_main_to_signup' | localise }}</a>
        </div>
        <div class="m-b-20 p-b-20 text-inverse">
          <a [routerLink]="['..', 'resetPassword']" class="text-primary">{{ 'login_forgot_password' | localise }}</a>
        </div>

        <hr />
        <p class="text-center text-grey-darker">
          {{ 'login_main_copyright' | localise }}
        </p>
      </form>
    </div>
  </div>
  <app-wait [show]='busy'></app-wait>
</div>
-->
