/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./card.component";
import * as i3 from "../../../services/hierobdd.service";
var styles_CardComponent = [i0.styles];
var RenderType_CardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardComponent, data: {} });
export { RenderType_CardComponent as RenderType_CardComponent };
export function View_CardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "btn btn-outline-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.test() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["All Users"]))], null, null); }
export function View_CardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "card", [], null, null, null, View_CardComponent_0, RenderType_CardComponent)), i1.ɵdid(1, 114688, null, 0, i2.CardComponent, [i3.HieroBDD], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardComponentNgFactory = i1.ɵccf("card", i2.CardComponent, View_CardComponent_Host_0, {}, {}, []);
export { CardComponentNgFactory as CardComponentNgFactory };
