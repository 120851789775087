var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import * as firebase from "firebase";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HieroBDD } from "../../../services/hierobdd.service";
import { InterpretationService } from "../../../services/interpretation.service";
import { HttpClient } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppModalConfirmComponent } from "../../../../../common/src/utility/modal/confirm-modal.component";
export class SingleCommandeComponent {
    constructor(route, router, hiero, interpretationService, http, modalService) {
        this.route = route;
        this.router = router;
        this.hiero = hiero;
        this.interpretationService = interpretationService;
        this.http = http;
        this.modalService = modalService;
    }
    ngOnInit() {
        console.log(this.hiero.Auth.User.Profile);
        this.route.paramMap.subscribe((paramMap) => __awaiter(this, void 0, void 0, function* () {
            if (!paramMap.has("id")) {
                this.router.navigate(["/commandes"]);
            }
            const type = paramMap.get("type");
            const docId = paramMap.get("id");
            if (type === "traductions")
                this.getSingleCommande(docId).then((data) => (this.command = data[0]));
            // console.log('Données dans ngOninit', this.loadCommande);
            else
                this.interpretation =
                    yield this.interpretationService.getInterpretation(docId);
        }));
        // initialize the form
        this.sendmessage = new FormGroup({
            message: new FormControl(null, {
                validators: [Validators.required],
            }),
        });
    }
    getSingleCommande(prestationID) {
        const docRef = this.hiero.DB.collection("professionnel_commandes")
            .where("prestationId", "==", prestationID)
            .get();
        return docRef.then((snapshot) => {
            return snapshot.docs.map((doc) => {
                // console.log('DATAAAAAAAAAAAA => ', doc.data());
                return doc.data();
            });
        });
    }
    getRooms() {
        let now = new Date();
        let date = this.interpretation.interpretationData.missions.date.toDate();
        let extHours = this.interpretation.interpretationData.missions.extHours;
        let [hour, min] = extHours.split("h");
        let end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + +hour, date.getMinutes() + +min);
        console.log(date);
        console.log(now < date);
        if (date < now && now < end) {
            //this.joinRoom()
            this.programmingMessage();
        }
        else if (now > date)
            this.isOverMessage();
        else
            this.notTheTimeMessage(date);
    }
    joinRoom() {
        //this.modalService.open(TutoSalonComponent); // *****created the component for tutorial's video***
        // window.open(URL);
        const headers = {
            Authorization: "Bearer my-token",
            "My-Custom-Header": "foobar",
        };
        const body = {
            roomName: this.interpretation.interpretationData.missions.roomName,
            identity: this.interpretation.interpretationData.professionnelId,
        };
        this.http
            .post("http://localhost:5000/join-room", body, { headers })
            .subscribe((data) => __awaiter(this, void 0, void 0, function* () {
            window.localStorage.setItem("settings", '{"audio": true,"video": true}');
            window.localStorage.setItem("token", data.token);
            this.router.navigate([body.roomName]);
        }));
    }
    programmingMessage() {
        return __awaiter(this, void 0, void 0, function* () {
            const modalRef = this.modalService.open(AppModalConfirmComponent, {
                centered: true,
            });
            modalRef.componentInstance.header = "Rejoindre la salle de réunion";
            modalRef.componentInstance.message = `Cette fonctionnalité est en cours de développement.`;
            modalRef.componentInstance.ok = "OK";
            modalRef.componentInstance.cancel = "Annuler";
        });
    }
    notTheTimeMessage(date) {
        return __awaiter(this, void 0, void 0, function* () {
            const modalRef = this.modalService.open(AppModalConfirmComponent, {
                centered: true,
            });
            modalRef.componentInstance.header = "Rejoindre la salle de réunion";
            modalRef.componentInstance.message = `Merci d'attendre le début de la réunion pour rejoindre la salle.
      Rappel: la réunion est programmée pour le ${date.toLocaleString()}.`;
            modalRef.componentInstance.ok = "OK";
            modalRef.componentInstance.cancel = "Annuler";
        });
    }
    isOverMessage() {
        return __awaiter(this, void 0, void 0, function* () {
            const modalRef = this.modalService.open(AppModalConfirmComponent, {
                centered: true,
            });
            modalRef.componentInstance.header = "Rejoindre la salle de réunion";
            modalRef.componentInstance.message =
                "La réunion est déjà terminée, vous ne pouvez plus rejoindre la salle";
            modalRef.componentInstance.ok = "OK";
            modalRef.componentInstance.cancel = "Annuler";
        });
    }
    sendMsg() {
        const msg = {
            uid: firebase.auth().currentUser.uid,
            message: this.sendmessage.value.message,
            sendAt: Date.now(),
        };
        // firebase.firestore().collection('messages').add(msg);
        // console.log(this.sendmessage.value.message);
        this.sendmessage.reset();
    }
    getFile(deviceStorageId) {
        console.log(deviceStorageId);
        let uidUser = firebase.auth().currentUser.uid;
        const file = uidUser + "/" + uidUser + "/" + uidUser + "/" + deviceStorageId;
        let newref = this.hiero.Storage.ref(file);
        let url = newref.getDownloadURL().then((data) => {
            window.open(data, "_blank");
        });
    }
}
