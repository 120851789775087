var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as firebase from "firebase/app";
export class EmailVerfiyComponent {
    constructor(route) {
        this.route = route;
        this.alert = false;
    }
    ngOnInit() {
        const id = this.route.snapshot.paramMap.has("id");
        this.route.paramMap.subscribe((paramMap) => __awaiter(this, void 0, void 0, function* () {
            if (!paramMap.has("id")) {
                // Redirect
                /* this.router.navigate(['/ticket']); */
                return;
            }
            this.id = +paramMap.get("id");
        }));
    }
    reverification() {
        firebase.auth().currentUser.sendEmailVerification();
        this.alert = true;
    }
}
