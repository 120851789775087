var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { HieroBDD } from "../../../../services/hierobdd.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GoogleGeo } from "../../../../services/google.services";
import Swal from "sweetalert2";
import { LocalisationService } from "../../../../../../common/src/modules/localisation/localisation.service";
import { EnumPrestationState } from "../../../../../../common/src/bdd/interfaces/IPrestation";
import { InterpretationService } from "../../../../services/interpretation.service";
import { ProfessionnelService } from "../../../../services/professionnel.service";
import { TranslatorService } from "../../../../services/translator.service";
import { DevisService } from "../../../../services/devis.service";
export const ROOM_LINK = "https://video-app-6852-1089-dev.twil.io?passcode=87123868521089";
/**
 * TOTO:In the urgency I make a copy paste from commandComponent functions but it is necessary to rewrite the functions
 *
 */
export class InterpreteComponent {
    constructor(activeModal, formBuilder, hiero, geo, localisation, interpretationService, professionnelService, translatorService, devisService) {
        this.activeModal = activeModal;
        this.formBuilder = formBuilder;
        this.hiero = hiero;
        this.geo = geo;
        this.localisation = localisation;
        this.interpretationService = interpretationService;
        this.professionnelService = professionnelService;
        this.translatorService = translatorService;
        this.devisService = devisService;
        this.lang = [];
        this.destLang = [];
        this.state = EnumPrestationState.WaitingForTranslator;
        this.show = false;
        this.showLoading = false;
        this.TVA = 1.2;
        this.typeIntervention = [
            {
                id: 1,
                name: this.localisation.localise("menu_label_sur_place"),
            },
            /* {
              id: 2,
              name: 'Par téléphone'
            },*/
            {
                id: 3,
                name: this.localisation.localise("menu_label_visio"),
            },
        ];
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.createForm();
            this.interForm
                .get("intervention")
                .valueChanges.subscribe((intervention) => {
                if (intervention.id === 1)
                    this.interForm.get("address").enable();
                else {
                    this.interForm.get("address").setValue("");
                    this.interForm.get("address").disable();
                }
            });
            this.lang = yield this.translatorService.getSourceLanguage();
            this.interForm.get("srcLang").valueChanges.subscribe((lang) => {
                this.getDestLang(lang);
            });
            this.getProfData();
        });
    }
    getProfData() {
        return __awaiter(this, void 0, void 0, function* () {
            this.professionnelData =
                yield this.professionnelService.getProfessionnelData();
            this.professionnelId = yield this.professionnelService.getProfessionnelId();
        });
    }
    createForm() {
        this.interForm = this.formBuilder.group({
            srcLang: ["", Validators.required],
            destLang: ["", Validators.required],
            event: ["", Validators.required],
            date: ["", Validators.required],
            time: ["", Validators.required],
            extHours: [
                "00:30",
                [
                    Validators.required,
                    Validators.pattern(/^(00:[3-5][0-9])|((0[1-9]|1[0-9]|2[0-3]):[0-5][0-9])$/),
                ],
            ],
            intervention: ["", Validators.required],
            address: [{ value: "", disabled: true }, Validators.required],
            bon: [""],
            context: ["", Validators.required],
        });
    }
    getDestLang(lang) {
        return __awaiter(this, void 0, void 0, function* () {
            this.interForm.get("destLang").setValue("");
            this.destLang = yield this.translatorService.getDestinationLang(lang);
        });
    }
    onSubmitForm() {
        return __awaiter(this, void 0, void 0, function* () {
            this.formValue = this.interForm.value;
            console.log("valeur du formulaire", this.interForm.value);
            this.translatorsCopies = yield this.translatorService.getProperInterpreters(this.formValue.destLang, this.formValue.extHours);
            this.show = true;
        });
    }
    getTranslatorNameOnClickButton(translatorId) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(translatorId);
            if (translatorId != null) {
                if (this.hiero.Auth.User.Profile.isANR ||
                    this.hiero.Auth.User.Profile.isExpat ||
                    this.professionnelData.isDevis ||
                    this.hiero.Auth.User.Profile.isSeineMaritime ||
                    this.hiero.Auth.User.Profile.isFidinam) {
                    this.createData(translatorId, true);
                }
                else {
                    this.createData(translatorId, false);
                }
            }
        });
    }
    createData(translatorId, isDevis) {
        return __awaiter(this, void 0, void 0, function* () {
            this.showLoading = true;
            const isSelectedTrad = this.translatorsCopies.find((el) => el.translatorId === translatorId);
            yield this.interpretationService.addInterpretation(this.formValue, isSelectedTrad, isDevis);
            this.loadingFile();
        });
    }
    // Pour geolocaliser l'adresse au moment de la saisie de l'interpretation
    /**
     * async getLocalisation(addr: string) {
      await this.geo.geocode(addr);
    }
     * */
    loadingFile() {
        this.show = false;
        this.showLoading = true;
        Swal.fire({
            title: this.localisation.localise("reset_password_send"),
        }).then(() => {
            this.activeModal.close();
        });
    }
}
