import { HttpClientModule } from "@angular/common/http";
import { LOCALE_ID, NgModule /*NO_ERRORS_SCHEMA*/ } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NotifierModule } from "angular-notifier";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { NgxFileDropModule } from "ngx-file-drop";
import {
  NgbActiveModal,
  NgbModule,
} from "../../../node_modules/@ng-bootstrap/ng-bootstrap";
import { PDFEditorComponent } from "../../common/src/components/pdfeditor/pdfeditor.component";
import { PDFInputComponent } from "../../common/src/components/pdfeditor/pdfinput/pdfinput.component";
import { PDFPreviewComponent } from "../../common/src/components/pdfpreview/pdfpreview.component";
import { LocalisationService } from "../../common/src/modules/localisation/localisation.service";
import { LocalisePipe } from "../../common/src/modules/localisation/localise.pipe";
import { Config } from "../../common/src/services/config.service";
import { Logger } from "../../common/src/utility/logger.class";
//
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoggedInGuard } from "./guards/loggedin.guard";
import { CountryPipe } from "./pipes/CountryPipe";
import { LanguagePipe } from "./pipes/LanguagePipe";
import { PrestationStatePipe } from "./pipes/PrestationStatePipe";
import { SortDateInterpretationPipe } from "./pipes/SortDateInterpretationPipe";
import { AccountComponent } from "./routes/account/account.component";
import { LoginComponent } from "./routes/account/login/login.component";
import { SignupComponent } from "./routes/account/signup/signup.component";
import { AppBaseComponent } from "./routes/app/appbase/appbase.component";
// import { DashboardComponent } from './routes/app/dashboard/dashboard.component';
import { RegisterAnfComponent } from "./routes/account/registerANF/register-anf.component";
// Environment

/*
import { HomeComponent } from './routes/app/home/home.component';
import { PrestationEditorComponent } from './routes/app/prestations/prestation/editor/prestationEditor.component';
import { PrestationComponent } from './routes/app/prestations/prestation/index/prestation.component';
import { PrestationBaseComponent } from './routes/app/prestations/prestation/prestationBase.component';
import { PrestationListComponent } from './routes/app/prestations/prestations.component';
*/
import { ProfileComponent } from "./routes/app/profile/profile.component";
import { SupportComponent } from "./routes/app/support/support.component";
import { BaseComponent } from "./routes/base/base.component";
import { NotFoundComponent } from "./routes/not_found/not_found.component";
import { ProfileCheckComponent } from "./routes/verify/profile/profilecheck.component";
import { VerifyBaseComponent } from "./routes/verify/verifybase/verifybase.component";
import { GoogleGeo } from "./services/google.services";
import { HieroBDD } from "./services/hierobdd.service";
import { AppWaitComponent } from "../../common/src/utility/app-wait/app-wait.component";
import { AppChatComponent } from "./utility/components/chat-component/chat.component";
import { AppDebounceInputComponent } from "../../common/src/utility/forms/app-debounce-input/app-debounce-input.component";
import { AppFormDropdownComponent } from "../../common/src/utility/forms/app-form-dropdown/app-form-dropdown.component";
import { AppFormOrderComponent } from "../../common/src/utility/forms/app-form-order/app-form-order.component"; //

import { AppFormGeneralErrorComponent } from "../../common/src/utility/forms/app-form-general-error/app-form-generl-error.component";
import { AppFormInputComponent } from "../../common/src/utility/forms/app-form-input/app-form-input.component";
import { AppFormTypeaheadComponent } from "../../common/src/utility/forms/app-form-typeahead/app-form-typeahead.component";
import { AppSwitchComponent } from "../../common/src/utility/forms/app-switch/app-switch.component";
import { AppModalConfirmComponent } from "../../common/src/utility/modal/confirm-modal.component";
import { LanguageDropdownComponent } from "../../common/src/utility/components/language-dropdown-component/language-dropdown.component";
import { ResetPasswordComponent } from "./routes/account/reset-password/reset-password.component";
import { AppModalManualAddressComponent } from "./utility/components/modal-manual-address/modal-manual-address.component";
import { ProfessionnelComponent } from "./routes/app/professionnel/professionnel.component";
import { CommandesComponent } from "./routes/app/commandes/commandes.component";
import { CommandeComponent } from "./routes/app/commandes/commande/commande.component";
// import { FileUploadModule } from "ng2-file-upload";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InterpreteComponent } from "./routes/app/commandes/interprete/interprete.component";
import { CommandeAssermenteComponent } from "./routes/app/commandes/commande-assermente/commande-assermente.component";
import { MaintenanceComponent } from "./routes/maintenance/maintenance.component";
import { SubscriptionComponent } from "./routes/app/subscription/subscription.component";
import { CardComponent } from "./routes/app/card/card.component";
import { SubscriptionProfessionnal } from "./services/subscription.services";
import { QuestionsComponent } from "./routes/app/questions/questions.component";
import { TicketComponent } from "./routes/app/ticket/ticket.component";
import { SingleTicketComponent } from "./routes/app/single-ticket/single-ticket.component";

import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import { SingleCommandeComponent } from "./routes/app/single-commande/single-commande.component";
import { FacturesComponent } from "./routes/app/factures/factures.component";
import { FactureDetailComponent } from "./routes/app/facture-detail/facture-detail.component";
import { NgxPrintModule } from "ngx-print";
import { TutoSalonComponent } from "./routes/app/tuto-salon/tuto-salon.component";
import { MatTabsModule } from "@angular/material/tabs";
import { DashboardComponent } from "./routes/app/dashboard/dashboard.component";
import { DevisComponent } from "./routes/app/devis/devis.component";
import { NgxPaginationModule } from "ngx-pagination";
import { ProfilePictureService } from "./services/profile-picture.services";
import { LoginModalComponent } from "./routes/app/profile/login-modal/login-modal.component";
import { EmailVerfiyComponent } from "./routes/account/email-verify/email-verify.component";
import { CheckModalComponent } from "./routes/app/profile/check-modal/check-modal.component";
import { RefundModalComponent } from "./routes/app/profile/refund-modal/refund-modal.component";
import { PaiementModalComponent } from "./routes/app/profile/paiement-modal/paiment-modal.component";
import { LoginSeineMaritimeComponent } from "./routes/account/seine-maritime/connect/login-seine-maritime.component";
import { RegistrationSeineMaritimeComponent } from "./routes/account/seine-maritime/registration/registration-seine-maritime.component";
import { LoginFidinamComponent } from "./routes/account/fidinam/connect/login-fidinam.component";
import { RegistrationFidinamComponent } from "./routes/account/fidinam/registration/registration-fidinam.component";
import { SpecialGuard } from "./guards/special.guard";
import { RegisterExpatComponent } from "./routes/account/register-expat/register-expat.component";
import { MeetingComponent } from "./routes/app/meeting/meeting.component";
import { InterpretationService } from "./services/interpretation.service";
import { DevisService } from "./services/devis.service";
import { ProfessionnelService } from "./services/professionnel.service";
import { FactureService } from "./services/facture.service";
import { TranslatorService } from "./services/translator.service";
import { TranslationsService } from "./services/translations.service";

registerLocaleData(localeFr, "fr");

// Set logging level
Logger.LogLevel = environment.logLevel;

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,

    BaseComponent,

    // Account
    AccountComponent,
    LoginComponent,
    SignupComponent,
    ResetPasswordComponent,
    RegisterAnfComponent,
    RegisterExpatComponent,

    // Verify
    VerifyBaseComponent,
    ProfileCheckComponent,

    // Moi
    // HomeComponent,
    SupportComponent,
    DashboardComponent,
    SubscriptionComponent,
    DevisComponent,
    //

    // App (logged in)
    AppBaseComponent,
    ProfileComponent,
    /*PrestationListComponent,
    PrestationBaseComponent,
    PrestationComponent,
    PrestationEditorComponent, */

    // Components
    AppWaitComponent,
    AppFormInputComponent,
    AppFormGeneralErrorComponent,
    AppFormDropdownComponent,
    AppFormTypeaheadComponent,
    AppSwitchComponent,
    AppFormOrderComponent,
    AppDebounceInputComponent,
    // NgbdSortableHeader, this's from services traductor componente delete by me (Brin)
    AppModalConfirmComponent,
    AppModalManualAddressComponent,
    AppChatComponent,
    LanguageDropdownComponent,

    PDFPreviewComponent,
    PDFEditorComponent,
    PDFInputComponent,

    // Pipes
    LanguagePipe,
    CountryPipe,
    PrestationStatePipe,
    LocalisePipe,
    SortDateInterpretationPipe,

    // professionnel
    ProfessionnelComponent,
    CommandesComponent,
    CommandeComponent,
    InterpreteComponent,
    CommandeAssermenteComponent,
    MaintenanceComponent,
    CardComponent,
    QuestionsComponent,
    TicketComponent,
    SingleTicketComponent,
    SingleCommandeComponent,
    FacturesComponent,
    FactureDetailComponent,
    TutoSalonComponent,
    LoginModalComponent,
    CheckModalComponent,
    RefundModalComponent,
    PaiementModalComponent,
    MeetingComponent,
    EmailVerfiyComponent,

    LoginSeineMaritimeComponent,
    RegistrationSeineMaritimeComponent,

    LoginFidinamComponent,
    RegistrationFidinamComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    NotifierModule,
    NgxFileDropModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    // FileUploadModule,
    BrowserAnimationsModule,
    NgxPrintModule,
    MatTabsModule,
    NgxPaginationModule,

    // MDBBootstrapModule.forRoot(),
  ],
  // schemas: [ NO_ERRORS_SCHEMA ],
  providers: [
    { provide: "CONFIG", useValue: environment },
    { provide: LOCALE_ID, useValue: "fr" },
    Config,
    HieroBDD,
    GoogleGeo,
    LocalisationService,
    LoggedInGuard,
    SpecialGuard,
    NgbActiveModal,
    SubscriptionProfessionnal,
    ProfilePictureService,
    InterpretationService,
    TranslationsService,
    DevisService,
    ProfessionnelService,
    FactureService,
    TranslatorService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AppModalConfirmComponent,
    AppModalManualAddressComponent,
    CommandeComponent,
    InterpreteComponent,
    CommandeAssermenteComponent,
    LoginModalComponent,
    CheckModalComponent,
    RefundModalComponent,
    PaiementModalComponent,
  ],
})
export class AppModule {}
