var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from 'rxjs';
const FIREBASE_MESSAGE_NOTIFICATIONS_CLIENT_COLLECTION = 'client_message_notifications';
const FIREBASE_MESSAGE_NOTIFICATIONS_TRANSLATOR_COLLECTION = 'traducteur_message_notifications';
const FIREBASE_MESSAGE_NOTIFICATIONS_PROFESSIONNEL_COLLECTION = 'professionnel_message_notifications';
export class MessageNotifications {
    constructor(doc, snapshot) {
        this._doc = doc;
        let not = {
            stored: 0,
            seen: 0
        };
        if (snapshot.exists) {
            not = snapshot.data();
        }
        this._notificationSubject = new BehaviorSubject(not);
        this._stopListeningMessages = doc.onSnapshot((newSnap) => {
            const notif = newSnap.data();
            this._notificationSubject.next(notif);
        });
    }
    get Id() {
        return this._doc.id;
    }
    get Unseen() {
        const data = this._notificationSubject.value;
        return Math.max(data.stored - data.seen, 0);
    }
    cleanup() {
        if (this._stopListeningMessages) {
            this._stopListeningMessages();
            this._stopListeningMessages = null;
        }
    }
    SetSeen() {
        return __awaiter(this, void 0, void 0, function* () {
            const not = this._notificationSubject.value;
            yield this._doc.update({
                seen: not.stored
            });
        });
    }
    WatchNotifications(observer) {
        return this._notificationSubject.subscribe(observer);
    }
    static getOrAdd(doc) {
        return __awaiter(this, void 0, void 0, function* () {
            let snapshot = yield doc.get();
            if (!snapshot.exists) {
                const not = {
                    stored: 0,
                    seen: 0
                };
                yield doc.set(not);
                snapshot = yield doc.get();
            }
            return snapshot;
        });
    }
    static InitForClientPrestation(user, prestationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const doc = user.DB.collection(FIREBASE_MESSAGE_NOTIFICATIONS_CLIENT_COLLECTION).doc(prestationId);
            const snapshot = yield MessageNotifications.getOrAdd(doc);
            return new MessageNotifications(doc, snapshot);
        });
    }
    static InitForTranslatorPrestation(translator, prestationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const doc = translator.User.DB.collection(FIREBASE_MESSAGE_NOTIFICATIONS_TRANSLATOR_COLLECTION).doc(prestationId);
            const snapshot = yield MessageNotifications.getOrAdd(doc);
            return new MessageNotifications(doc, snapshot);
        });
    }
    static InitForProfessionnelPrestation(professionnel, prestationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const doc = professionnel.User.DB.collection(FIREBASE_MESSAGE_NOTIFICATIONS_PROFESSIONNEL_COLLECTION).doc(prestationId);
            const snapshot = yield MessageNotifications.getOrAdd(doc);
            return new MessageNotifications(doc, snapshot);
        });
    }
}
