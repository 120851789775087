var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, OnDestroy } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommandeComponent } from "./commande/commande.component";
import { HieroBDD } from "../../../services/hierobdd.service";
import { InterpreteComponent } from "./interprete/interprete.component";
import { CommandeAssermenteComponent } from "./commande-assermente/commande-assermente.component";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ProfessionnelService } from "../../../services/professionnel.service";
import { InterpretationService } from "../../../services/interpretation.service";
import { PrestationType } from "../../../../../common/src/bdd/interfaces/types";
import { TranslationsService } from "../../../services/translations.service";
const URL = "";
export class CommandesComponent {
    constructor(modalService, hiero, router, http, pro, interpretationService, translationsService) {
        this.modalService = modalService;
        this.hiero = hiero;
        this.router = router;
        this.http = http;
        this.pro = pro;
        this.interpretationService = interpretationService;
        this.translationsService = translationsService;
        this.commandesListInterpretations = Array();
        this.page = 1;
        this.pageAsser = 1;
        this.pageInter = 1;
        this.hasBudget = true;
        this.pageSize = 10;
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.currentUser = this.hiero.Auth.User;
            yield this.getTraductionCommandes();
            this.getInterpretationsCommandes();
        });
    }
    ngOnDestroy() {
        if (this.interpretationSub)
            this.interpretationSub.unsubscribe();
        if (this.commandsSub)
            this.commandsSub.unsubscribe();
    }
    openModal(event) {
        if (event === 1) {
            this.modalService.open(CommandeComponent, { size: "lg" });
        }
        else if (event === 2) {
            this.modalService.open(InterpreteComponent, { size: "lg" });
        }
        else if (event === 0) {
            this.modalService.open(CommandeAssermenteComponent, { size: "lg" });
        }
    }
    isValideBudget() {
        if (this.professionelData.budget > 0) {
            this.hasBudget = false;
        }
    }
    getTraductionCommandes() {
        return __awaiter(this, void 0, void 0, function* () {
            this.professionelData = yield this.pro.getProfessionnelData();
            this.budget = this.professionelData.budget;
            console.log(this.budget);
            this.isValideBudget();
            this.translationsService.getTranslations();
            this.commandsSub = this.translationsService.translationsSubject.subscribe({
                next: (data) => {
                    this.assermentationList = data.filter((el) => el.type === PrestationType.TRADUCTION_ASSERMENTEE);
                    this.classicList = data.filter((el) => el.type === PrestationType.TRADUCTION_CLASSIQUE);
                },
            });
        });
    }
    getInterpretationsCommandes() {
        this.interpretationService.getInterpretations();
        this.interpretationSub =
            this.interpretationService.commandInterpretationsSubject.subscribe({
                next: (value) => {
                    this.commandesListInterpretations = value;
                },
            });
    }
    getRooms(interData) {
        //this.modalService.open(TutoSalonComponent); // *****created the component for tutorial's video***
        // window.open(URL);
        const headers = {
            Authorization: "Bearer my-token",
            "My-Custom-Header": "foobar",
        };
        const body = {
            roomName: interData.room,
            identity: interData.professionnel.uid,
        };
        this.http
            .post("http://localhost:5000/join-room", body, { headers })
            .subscribe((data) => __awaiter(this, void 0, void 0, function* () {
            window.localStorage.setItem("settings", '{"audio": true,"video": true}');
            window.localStorage.setItem("user", interData.professionnel.businessName);
            window.localStorage.setItem("token", data.token);
            this.router.navigate(["/app/interpretations/" + body.roomName]);
        }));
    }
    onPageChange(pageNumber, switchnmbr) {
        switch (switchnmbr) {
            case 0:
                this.pageAsser = pageNumber;
                break;
            case 1:
                this.page = pageNumber;
                break;
            case 2:
                this.pageInter = pageNumber;
                break;
            default:
                break;
        }
    }
    showDataDashboard(selected) {
        const showOrdersPro = document.getElementById("orders_pro");
        const showOrders = document.getElementById("orders");
        const showInterpretation = document.getElementById("interpretation_list");
        // const showUsers = document.getElementById('users_list')
        switch (selected) {
            case 0:
                showOrdersPro.classList.remove("showBloc");
                showOrders.classList.add("showBloc");
                showInterpretation.classList.add("showBloc");
                // showUsers.classList.add("showBloc")
                break;
            case 1:
                showOrdersPro.classList.add("showBloc");
                showOrders.classList.remove("showBloc");
                showInterpretation.classList.add("showBloc");
                // showUsers.classList.add("showBloc")
                break;
            case 2:
                showOrdersPro.classList.add("showBloc");
                showOrders.classList.add("showBloc");
                showInterpretation.classList.remove("showBloc");
                // showUsers.classList.add("showBloc")
                break;
            case 3:
                showOrdersPro.classList.remove("showBloc");
                showOrders.classList.remove("showBloc");
                showInterpretation.classList.remove("showBloc");
                // showUsers.classList.remove("showBloc")
                break;
            default:
                break;
        }
    }
}
