var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HieroBDD } from "./hierobdd.service";
import { ProfessionnelService } from "./professionnel.service";
import * as firebase from "firebase";
import { TypeProfessionnal, PrestationType, } from "../../../common/src/bdd/interfaces/types";
import { COLLECTION } from "../../../common/src/bdd/utility/collections";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { EnumDevisState, } from "../../../common/src/bdd/interfaces/IDevis";
export class DevisService {
    constructor(hiero, pro) {
        this.hiero = hiero;
        this.pro = pro;
        this.allDevis = [];
        this.allDevisSubject = new BehaviorSubject([]);
    }
    dispachAllDevis() {
        this.allDevisSubject.next(this.allDevis);
    }
    getAllDevis() {
        return __awaiter(this, void 0, void 0, function* () {
            let professionnelId = yield this.pro.getProfessionnelId();
            const request = this.hiero.DB.collection(COLLECTION.DEVIS)
                .where("professionnelId", "==", professionnelId)
                .orderBy("date", "desc");
            return request
                .get()
                .then((snapshot) => {
                this.allDevis = snapshot.docs.map((doc) => {
                    return {
                        id: doc.id,
                        devisData: doc.data(),
                    };
                });
                this.dispachAllDevis();
                return this.allDevis;
            })
                .catch();
        });
    }
    getADevis(id) {
        return this.hiero.DB.collection(COLLECTION.DEVIS)
            .doc(id)
            .get()
            .then((snap) => {
            return snap.data();
        });
    }
    sendDevisTranslation(formValue, translator, translatorName) {
        return __awaiter(this, void 0, void 0, function* () {
            /* let currentUser = this.hiero.Auth.User;
            let professionnelData = await this.pro.getProfessionnelData(currentUser.Id);
            let professionnelId = await this.pro.getProfessionnelId(currentUser.Id);
        
            let devis = {
              numero: Math.floor(Math.random() * 1000000 + 1),
              
              status: EnumPrestationState.WaitingForTranslator,
            };
        
            let typeProfessionnel = await this.pro.getTypeProfessionnel();
            switch (typeProfessionnel) {
              case TypeProfessionnal.expat:
                devis["isDevisExpat"] = true;
                break;
              case TypeProfessionnal.seineMaritime:
                devis["isDevisSeineMaritime"] = true;
                break;
              case TypeProfessionnal.fidinam:
                devis["isDevisFidinam"] = true;
                break;
              case TypeProfessionnal.normal:
                break;
            }
        
            await firebase.firestore().collection("devis").add(devis); */
        });
    }
    sendDevisInterpretation(event, formValue, selectedTranslator, price, bonDeCommande) {
        return __awaiter(this, void 0, void 0, function* () {
            let professionnelData = yield this.pro.getProfessionnelData();
            let professionnelId = yield this.pro.getProfessionnelId();
            let professionnelUid = this.hiero.Auth.User.Id;
            let devis = {
                numero: Math.floor(Math.random() * 1000000 + 1),
                date: new Date(),
                srcLang: formValue.srcLang,
                destLang: formValue.destLang,
                professionnelName: professionnelData.businessName,
                professionnelId: professionnelId,
                professionnelUid: professionnelUid,
                translatorName: selectedTranslator.translatorData.businessName,
                translatorId: selectedTranslator.translatorId,
                state: EnumDevisState.WaitingForValidation,
                price: price,
                typePrestation: PrestationType.INTERPRETATION,
                event: event,
                missions: formValue,
            };
            if (bonDeCommande)
                devis["purchaseOrder"] = bonDeCommande;
            let typeProfessionnel = yield this.pro.getTypeProfessionnel();
            console.log(typeProfessionnel);
            switch (typeProfessionnel) {
                case TypeProfessionnal.expat:
                    devis["isDevisExpat"] = true;
                    break;
                case TypeProfessionnal.seineMaritime:
                    devis["isDevisSeineMaritime"] = true;
                    break;
                case TypeProfessionnal.fidinam:
                    devis["isDevisFidinam"] = true;
                    break;
                case TypeProfessionnal.normal:
                    break;
            }
            console.log(devis);
            yield firebase
                .firestore()
                .collection(COLLECTION.DEVIS)
                .add(devis)
                .then(() => {
                firebase
                    .firestore()
                    .collection("mail")
                    .add({
                    bcc: [
                        "B.balsomi@hiero-solution.com",
                        "a.luis@hiero-solution.com",
                        "w.moingt@hiero-solution.com",
                        "n.ziani@hiero-solution.com",
                    ],
                    message: {
                        subject: "Nouveau devis",
                        text: "Un nouveau devis est disponible",
                        html: `<div style=" font-family: "Helvetica Neue", sans-serif; font-size: 20px;"> <div style="font-weight: bold"> <img src="https://firebasestorage.googleapis.com/v0/b/hiero-prod.appspot.com/o/Logos%2Fbird.png?alt=media&token=fa72f85d-1c09-48c1-ab47-ee7cde0eb80b" alt="" width="50" /> Hierø Solution </div> <hr /> <h3 style="text-align: center">Devis généré</h3> <p>Bonjour,</p><p style="line-height: 2; text-align:justify"> Un devis a été générer dans notre système par le client ${professionnelData.businessName}. Le numéros du devis est ${devis.numero}. Retrouve les détails de ce devis dans ton espace admin. <p>Bien cordialement,</p> <p>L\'équipe Hierø</p> <hr /><div><h3 style= "text-align:center">Description du devis</h3><p > ${formValue.context}</p></div> </div> `,
                    },
                })
                    .then(() => console.log("Queued email for delivery!"));
            });
        });
    }
    acceptDevis(id) {
        return new Promise((resolve, reject) => {
            this.hiero.DB.collection(COLLECTION.DEVIS)
                .doc(id)
                .update({
                state: EnumDevisState.AcceptedByClient,
                acceptedByClientAt: new Date(),
            })
                .then((value) => resolve("Mis à jours"))
                .catch(reject);
        });
    }
    refuseDevis(id) {
        return new Promise((resolve, reject) => {
            this.hiero.DB.collection(COLLECTION.DEVIS)
                .doc(id)
                .update({
                state: EnumDevisState.RefusedByClient,
                refusedByClientAt: new Date(),
            })
                .then((value) => resolve("Mis à jours"))
                .catch(reject);
        });
    }
}
