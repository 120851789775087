import { Component, NgZone } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ESignupType } from "../../../../../../common/src/bdd/user/AuthService";
import { LocalisationService } from "../../../../../../common/src/modules/localisation/localisation.service";
import {
  SubmitForm,
  ISubmitFormInputErrors,
} from "../../../../../../common/src/utility/forms/submitform.class";
import { HieroBDD } from "../../../../services/hierobdd.service";
import * as firebase from "firebase";
import swal from "sweetalert";

@Component({
  templateUrl: "./login-fidinam.component.html",
  styleUrls: ["./login-fidinam.component.scss"],
})
export class LoginFidinamComponent {
  busy: boolean;

  loginForm: SubmitForm;
  constructor(
    private fb: FormBuilder,
    private hiero: HieroBDD,
    private router: Router,
    private zone: NgZone,
    private localisation: LocalisationService
  ) {
    // this.router.navigate(['maintenance'])
    this.createForm();
  }
  createForm() {
    this.loginForm = new SubmitForm(
      this.fb,
      [
        {
          name: "email",
          value: "",
          validators: [Validators.required, Validators.email],
          type: "email",
          title: this.localisation.localise("login_main_email"),
          autocomplete: "email",
          placeholder: this.localisation.localise(
            "login_main_email_placeholder"
          ),
          help: this.localisation.localise("login_main_email_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise("login_error_required_field"),
            },
            <ISubmitFormInputErrors>{
              code: "email",
              message: this.localisation.localise("login_error_invalid_email"),
            },
            <ISubmitFormInputErrors>{
              code: "isEmail",
              message: this.localisation.localise("login_error_invalid_email"),
            },
            <ISubmitFormInputErrors>{
              code: "notFound",
              message: this.localisation.localise(
                "login_error_email_not_found"
              ),
            },
          ],
        },
        {
          name: "password",
          value: "",
          validators: [Validators.required],
          type: "password",
          title: this.localisation.localise("login_main_password"),
          autocomplete: "off",
          placeholder: this.localisation.localise(
            "login_main_password_placeholder"
          ),
          help: this.localisation.localise("login_main_password_help"),
          errors: [
            <ISubmitFormInputErrors>{
              code: "required",
              message: this.localisation.localise("login_error_required_field"),
            },
          ],
        },
      ],
      // Submit callback
      (data) => {
        this.busy = true;
        return this.hiero.Auth.login({
          email: data.email,
          password: data.password,
          type: ESignupType.EmailPassword,
        });
      },
      // Success callback
      () => {
        if (firebase.auth().currentUser.emailVerified === true) {
          console.log("Success.");
          this.router.navigate(["/", "app"]);
        } else {
          this.router.navigate(["compte/fidinam/login"]);
          swal({
            title: this.localisation.localise("non_valid_address"),
            text: this.localisation.localise("message_verification"),
            icon: "warning",
            buttons: [
              this.localisation.localise("retour_btn"),
              this.localisation.localise("validation_mail_resend"),
            ],
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              firebase.auth().currentUser.sendEmailVerification();
              swal(this.localisation.localise("validation_mail_success"), {
                icon: "success",
              });
            }
          });
        }
      },
      // Fail callback
      (err) => {
        // What to do with login failuer
        this.busy = false;
      },
      // Changes callback
      null
    );
  }
}
