<h3>
  Foire aux questions
  <small
    >Les réponses à vos questions sont peut être sur cette page. Dans le cas
    contraire n'hésitez pas à ouvrir un ticket</small
  >
</h3>
<div class="d-flex">
  <div class="col-8 mt-3 myDiv">
    <h4 class="mt-3">{{ "section_questions_frequentes" | localise }}</h4>
    <hr />
    <div>
      <h6
        [ngClass]="
          hiero.Auth.User.Profile.isSeineMaritime
            ? 'h6-seine-maritime-color-secondary'
            : hiero.Auth.User.Profile.isFidinam
            ? 'h6-fidinam-color'
            : 'my-h6'
        "
      >
        <i class="fa fa-question-circle"></i>
        {{ "section_questions_profil_entreprise" | localise }}
      </h6>
      <p>{{ "section_questions_paragraphe_one" | localise }}</p>
    </div>
    <hr />
    <div>
      <h6
        [ngClass]="
          hiero.Auth.User.Profile.isSeineMaritime
            ? 'h6-seine-maritime-color-secondary'
            : hiero.Auth.User.Profile.isFidinam
            ? 'h6-fidinam-color'
            : 'my-h6'
        "
      >
        <i class="fa fa-question-circle"></i>
        {{ "section_questions_original" | localise }}
      </h6>
      <p>{{ "section_questions_paragraphe_two" | localise }}</p>
    </div>
    <hr />
    <div>
      <h6
        [ngClass]="
          hiero.Auth.User.Profile.isSeineMaritime
            ? 'h6-seine-maritime-color-secondary'
            : hiero.Auth.User.Profile.isFidinam
            ? 'h6-fidinam-color'
            : 'my-h6'
        "
      >
        <i class="fa fa-question-circle"></i>
        {{ "section_questions_tarducteur" | localise }}
      </h6>
      <p>{{ "section_questions_paragraphe_three" | localise }}</p>
    </div>
    <hr />
    <div>
      <h6
        [ngClass]="
          hiero.Auth.User.Profile.isSeineMaritime
            ? 'h6-seine-maritime-color-secondary'
            : hiero.Auth.User.Profile.isFidinam
            ? 'h6-fidinam-color'
            : 'my-h6'
        "
      >
        <i class="fa fa-question-circle"></i>
        {{ "section_questions_price_deplacement" | localise }}
      </h6>
      <p>{{ "section_questions_paragraphe_four" | localise }}</p>
    </div>
    <hr />
    <div>
      <h6
        [ngClass]="
          hiero.Auth.User.Profile.isSeineMaritime
            ? 'h6-seine-maritime-color-secondary'
            : hiero.Auth.User.Profile.isFidinam
            ? 'h6-fidinam-color'
            : 'my-h6'
        "
      >
        <i class="fa fa-question-circle"></i>
        {{ "section_questions_gestions_files" | localise }}
      </h6>
      <p>{{ "section_questions_paragraphe_five" | localise }}</p>
    </div>
    <hr />
    <div>
      <h6
        [ngClass]="
          hiero.Auth.User.Profile.isSeineMaritime
            ? 'h6-seine-maritime-color-secondary'
            : hiero.Auth.User.Profile.isFidinam
            ? 'h6-fidinam-color'
            : 'my-h6'
        "
      >
        <i class="fa fa-question-circle"></i>
        {{ "section_questions_contact" | localise }}
      </h6>
      <p>{{ "section_questions_paragraphe_six" | localise }}</p>
    </div>
    <hr />
    <div>
      <h6
        [ngClass]="
          hiero.Auth.User.Profile.isSeineMaritime
            ? 'h6-seine-maritime-color-secondary'
            : hiero.Auth.User.Profile.isFidinam
            ? 'h6-fidinam-color'
            : 'my-h6'
        "
      >
        <i class="fa fa-question-circle"></i>
        {{ "section_questions_traductions" | localise }}
      </h6>
      <p>
        {{ "section_questions_paragraphe_seven" | localise }}
      </p>
    </div>
    <hr />
    <div>
      <h6
        [ngClass]="
          hiero.Auth.User.Profile.isSeineMaritime
            ? 'h6-seine-maritime-color-secondary'
            : hiero.Auth.User.Profile.isFidinam
            ? 'h6-fidinam-color'
            : 'my-h6'
        "
      >
        <i class="fa fa-question-circle"></i>
        {{ "section_questions_budget" | localise }}
      </h6>
      <p>{{ "section_questions_paragraphe_eight" | localise }}</p>
    </div>
  </div>

  <div class="col-4">
    <div class="card mt-3" style="width: 27rem">
      <div class="card-body">
        <h5 class="card-title">{{ "agency_support" | localise }}</h5>
        <div class="text-right">
          <h6
            class="card-subtitle mb-2 txt"
            [ngClass]="
              hiero.Auth.User.Profile.isSeineMaritime
                ? 'h6-seine-maritime-color-primary'
                : hiero.Auth.User.Profile.isFidinam
                ? 'h6-fidinam-color'
                : 'my-h6'
            "
          >
            {{ "agency_support_service_client" | localise }}
          </h6>
          <p class="card-text">
            <strong> {{ "support_tel" | localise }}</strong>
          </p>
          <p>{{ "agency_support_ouverture" | localise }}</p>
        </div>
        <p>
          {{ "agency_support_questions" | localise }}
          <a
            href="mailto:contact@hiero-support.com"
            [ngClass]="
              hiero.Auth.User.Profile.isSeineMaritime
                ? 'a-seine-maritime-color-green'
                : hiero.Auth.User.Profile.isFidinam
                ? 'h6-fidinam-color'
                : 'my-a'
            "
            >contact@hiero-support.com</a
          >
        </p>
      </div>
    </div>
    <div class="card mt-5" style="width: 27rem">
      <div class="card-body">
        <h5 class="card-title">{{ "agency_support_guide" | localise }}</h5>
        <!-- <h6 class="card-subtitle mb-2 ">Numéro de téléphone- Services Clients</h6> -->
        <div class="text-right text-justify">
          <p class="card-text">{{ "agency_support_guide_p" | localise }}</p>

          <p>
            <a
              href="#"
              [ngClass]="
                hiero.Auth.User.Profile.isSeineMaritime
                  ? 'a-seine-maritime-color-green'
                  : hiero.Auth.User.Profile.isFidinam
                  ? 'h6-fidinam-color'
                  : 'my-a'
              "
            >
              {{ "agency_support_download_guide" | localise }}</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
