<div class="container-fluid bg-dark">
  <div class="section-border border-primary">
    <div class="p-2">
      <h5 class="text-light">Nom de la salle : {{ roomName }}</h5>
    </div>
    <!-- Audio / Video -->
    <section class="actions d-flex flex-row justify-content-center">
      <div>
        <button
          class="btn btn-success btn-lg mr-2"
          id="audio"
          (click)="audioChange()"
          *ngIf="audioFlag"
        >
          <i class="fa fa-microphone"></i>
        </button>
        <button
          class="btn btn-danger btn-lg mr-2"
          id="audio"
          (click)="audioChange()"
          *ngIf="!audioFlag"
        >
          <i class="fa fa-microphone-slash"></i>
        </button>
        <button
          class="btn btn-success btn-lg mr-2"
          id="video"
          (click)="videoChange()"
          *ngIf="videoFlag"
        >
          <i class="fa fa-video-camera"></i>
        </button>
        <button
          class="btn btn-danger btn-lg mr-2"
          id="video"
          (click)="videoChange()"
          *ngIf="!videoFlag"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-camera-video-off-fill"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l6.69 9.365zm-10.114-9A2.001 2.001 0 0 0 0 5v6a2 2 0 0 0 2 2h5.728L.847 3.366zm9.746 11.925-10-14 .814-.58 10 14-.814.58z"
            />
          </svg>
        </button>
        <button
          id="disconect"
          class="btn btn-lg btn-danger lift ms-auto"
          data-bs-toggle="modal"
          data-bs-target="#leaveSession"
          (click)="leaveSession()"
        >
          <i class="fa fa-sign-out"></i>
        </button>
      </div>
    </section>
    <br />

    <article
      class="container d-flex flex-row justify-content-around"
      id="videoContainer"
    >
      <div id="local" class="mr-3">
        <div *ngIf="!videoFlag">
          <img
            src="../../../../assets/img/user/user-meeting.png"
            alt="video not available"
            title="video not available"
            width="640"
            height="480"
          />
          <h6 class="text-light">{{ name }}</h6>
        </div>
        <div id="local-media">
          <div id="local-media-container"></div>
          <h6 class="text-light">{{ name }}</h6>
        </div>
      </div>
      <div id="remote">
        <div id="remoteNotVideo">
          <img
            src="../../../../assets/img/user/user-meeting.png"
            alt="video not available"
            title="video not available"
            width="640"
            height="480"
          />
        </div>
        <div id="remote-media">
          <div id="remote-media-container"></div>
        </div>
      </div>
    </article>

    <div id="alert" class="container"></div>

    <!-- Modal -->
    <div
      class="modal fade bg-dark"
      id="leaveSession"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content bg-dark">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Are you sure?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>If you accept you leave the session.</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" id="Understood" class="btn btn-primary">
              Understood
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->
  </div>
</div>
