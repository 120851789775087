<div class="modal-body">
  <div class="container">
    <div class="mb-3">
      <h3>{{ "commander_interprete" | localise }}</h3>
    </div>
    <form [formGroup]="interForm" (ngSubmit)="onSubmitForm()">
      <!-- Type d'intervention-->
      <div class="form-group">
        <label class="col-form-label" for="intervention">
          {{ "menu_label_intervention" | localise }}
        </label>
        <select
          class="form-group custom-select my-1 mr-sm-2 mb-0"
          formControlName="intervention"
          id="intervention"
        >
          <option value="" disabled>
            {{ "select_choose" | localise }}
          </option>
          <option *ngFor="let inter of typeIntervention" [ngValue]="inter">
            {{ inter.name }}
          </option>
        </select>
        <div
          *ngIf="
            interForm.get('intervention').hasError('required') &&
            interForm.get('intervention').touched
          "
        >
          <small class="text-danger">
            {{ "account_error_required" | localise }}
          </small>
        </div>
      </div>

      <div class="form-group">
        <label class="col-form-label" for="event">
          {{ "menu_label_event_name" | localise }}
        </label>
        <input
          type="text"
          class="form-control"
          id="event"
          formControlName="event"
        />
        <div
          *ngIf="
            interForm.get('event').hasError('required') &&
            interForm.get('event').touched
          "
        >
          <small class="text-danger">
            {{ "account_error_required" | localise }}
          </small>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm">
          <label class="col-form-label" for="srcLang">
            {{ "label_origin_lang" | localise }}
          </label>
          <select
            class="form-group custom-select mb-0"
            id="srcLang"
            formControlName="srcLang"
          >
            <option value="" disabled>
              {{ "select_choose" | localise }}
            </option>
            <option *ngFor="let l of lang" [ngValue]="l.iso639">
              {{ l.iso639 | iso639ToNative }}
            </option>
          </select>
          <div
            *ngIf="
              interForm.get('srcLang').hasError('required') &&
              interForm.get('srcLang').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
        </div>

        <div class="col-sm">
          <label class="col-form-label" for="destLang">
            {{ "order_language_to" | localise }}
          </label>
          <select
            class="form-group custom-select mb-0"
            id="destLang"
            formControlName="destLang"
          >
            <option value="" disabled>
              {{ "select_choose" | localise }}
            </option>
            <option *ngFor="let lang of destLang" [ngValue]="lang">
              {{ lang | iso639ToNative }}
            </option>
          </select>
          <div
            *ngIf="
              interForm.get('destLang').hasError('required') &&
              interForm.get('destLang').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="col-form-label" for="address">
          {{ "agency_setup_address" | localise }}
        </label>
        <input
          type="text"
          class="form-control"
          id="address"
          formControlName="address"
        />
        <div
          *ngIf="
            interForm.get('address').hasError('required') &&
            interForm.get('address').touched
          "
        >
          <small class="text-danger">
            {{ "account_error_required" | localise }}
          </small>
        </div>
      </div>

      <div class="form-group">
        <label class="col-form-label" for="bon">
          {{ "bon_commande" | localise }}
        </label>
        <input
          type="text"
          class="form-control"
          id="bon"
          formControlName="bon"
        />
      </div>

      <div class="form-group row">
        <div class="col-sm">
          <label class="col-form-label" for="date">
            {{ "account_hist_pro_date" | localise }}
          </label>
          <input
            class="form-control"
            type="date"
            id="date"
            formControlName="date"
          />
          <div
            *ngIf="
              interForm.get('date').hasError('required') &&
              interForm.get('date').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
        </div>

        <div class="col-sm">
          <label class="col-form-label" for="time">
            {{ "hours" | localise }}
          </label>
          <input
            class="form-control"
            type="time"
            id="time"
            formControlName="time"
          />
          <div
            *ngIf="
              interForm.get('time').hasError('required') &&
              interForm.get('time').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
        </div>
      </div>

      <div class="form-group row justify-content-between mb-0">
        <label class="col-form-label" for="extHours">
          {{ "nb_heures_estimee" | localise }}
        </label>
        <input
          class="form-control col-md-5 mr-3"
          type="time"
          id="extHours"
          formControlName="extHours"
        />
      </div>
      <div
        class="d-flex flex-row-reverse"
        *ngIf="
          interForm.get('extHours').hasError('pattern') &&
          interForm.get('extHours').touched
        "
      >
        <small class="text-danger">
          {{ "intervention_exthour_pattern_error" | localise }}
        </small>
      </div>

      <!--
      <div class="form-group">
        <label class="my-1 mr-2" for="contactDeplacement" disabled> Déplacement </label>
        <input type="checkbox" id="contactDeplacement" formControlName="contactDeplacement" value="Deplacement" disabled>
      </div>
      <div class="pull-rigth">
        <label class="my-1 mr-2" for="contactTel"> Par téléphone </label>
        <input type="checkbox" id="contactTel" formControlName="contactTel" value="telephone">
      </div>
     -->

      <div class="form-group mt-3">
        <label class="my-1 mr-2" for="context">
          {{ " table_hader_details " | localise }}
        </label>
        <textarea
          class="form-control"
          id="context"
          formControlName="context"
          rows="10"
          cols="35"
        ></textarea>
        <div
          *ngIf="
            interForm.get('context').hasError('required') &&
            interForm.get('context').touched
          "
        >
          <small class="text-danger">
            {{ "account_error_required" | localise }}
          </small>
        </div>
      </div>

      <div>
        <div class="mb-3 d-flex justify-content-end">
          <button
            [ngClass]="
              hiero.Auth.User.Profile.isSeineMaritime
                ? 'btn seine-maritime-color-secondary'
                : hiero.Auth.User.Profile.isFidinam
                ? 'btn fidinam-color-secondary'
                : 'btn my-color'
            "
            [disabled]="interForm.invalid"
          >
            {{ "find_interpertor" | localise }}
          </button>
        </div>
      </div>
    </form>
    <table class="table table-responsive w-auto" *ngIf="show">
      <thead>
        <tr>
          <th>{{ "order_traductor_delegate" | localise }}</th>
          <th>{{ "agency_address_city" | localise }}</th>
          <th>{{ "tasks_price_ttc" | localise }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="translatorList?.length == 0">
          {{
            "no_traductor" | localise
          }}
          <i>{{ "no_traductor_message" | localise }}</i>
        </tr>
        <tr *ngFor="let translator of translatorsCopies">
          <td nowrap>{{ translator.translatorData.businessName }}</td>
          <td nowrap>
            {{ translator.translatorData.address.city }}
          </td>
          <td>{{ translator.price.priceTTC.toFixed(2) }} €</td>
          <td>
            <button
              [ngClass]="
                hiero.Auth.User.Profile.isSeineMaritime
                  ? 'btn seine-maritime-color-secondary'
                  : hiero.Auth.User.Profile.isFidinam
                  ? 'btn fidinam-color-secondary'
                  : 'btn my-color'
              "
              (click)="getTranslatorNameOnClickButton(translator.translatorId)"
            >
              {{ "select" | localise }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <div class="load-wrapp" *ngIf="showLoading">
      <div class="load-5">
        <p>En cours ...</p>
        <div class="ring-2">
          <div class="ball-holder">
            <div class="ball"></div>
          </div>
        </div>
     </div>
  </div> -->
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
  >
    Close
  </button>
</div>
