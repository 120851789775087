var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ElementRef, NgZone, OnDestroy, OnInit, } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { EnumPrestationState } from "../../../../../common/src/bdd/interfaces/IPrestation";
import { LocalisationService } from "../../../../../common/src/modules/localisation/localisation.service";
import { HieroBDD } from "../../../services/hierobdd.service";
import { TitleExtractor, } from "../../../../../common/src/utility/title_extractor.class";
import { IsValidProfile } from "../../verify/profile/profilecheck.component";
import * as firebase from "firebase";
import { ProfilePictureService } from "../../../services/profile-picture.services";
import { filter, map } from "rxjs/operators";
export class AppBaseComponent {
    constructor(hiero, router, route, notifier, zone, localisation, pfpService) {
        this.hiero = hiero;
        this.router = router;
        this.route = route;
        this.notifier = notifier;
        this.zone = zone;
        this.localisation = localisation;
        this.pfpService = pfpService;
        this.titles = [];
        this.isShow = true;
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd), map(() => {
            let child = this.route.firstChild;
            while (child) {
                if (child.firstChild) {
                    child = child.firstChild;
                }
                else if (child.snapshot.data && child.snapshot.data["showNav"]) {
                    return child.snapshot.data["showNav"];
                }
                else {
                    return null;
                }
            }
            return null;
        }))
            .subscribe((customData) => {
            customData === "false" ? (this.isShow = false) : (this.isShow = true);
        });
        this._titleExtractor = new TitleExtractor(route, (key) => {
            // Replace strings here if necessary
            return "";
        }, (titles) => {
            this.titles = titles;
        });
        this._eventSubs = hiero.WatchPrestationEvent({
            next: (event) => {
                let msg = null;
                switch (event.to) {
                    case EnumPrestationState.WaitingForTranslator:
                        msg = localisation.localise("notifications_new_task");
                        break;
                    case EnumPrestationState.Translating:
                        msg = localisation.localise("notifications_task_confirmed");
                        break;
                    case EnumPrestationState.Validated:
                        msg = localisation.localise("notifications_task_validated");
                        break;
                }
                if (msg) {
                    this.notifier.notify("success", msg);
                }
            },
        });
    }
    getTitle() {
        if (this.titles && this.titles.length > 0) {
            const titleKey = this.titles[this.titles.length - 1].title;
            return this.localisation.localise(titleKey);
        }
        else {
            return "";
        }
    }
    ngOnInit() {
        /* if (this.router.url.includes("/app/interpretations/")) {
          this.isShow = false;
        } */
        this.pfpService.pfp$.subscribe((newPic) => (this.photo = newPic));
        this._subscription = this.hiero.Auth.WatchUser({
            next: (user) => {
                this.user = user;
                if (this.user) {
                    this.user.WatchProfile({
                        next: (profile) => {
                            this.profile = profile;
                        },
                    });
                }
            },
        });
        // ESSENTIAL: PROFESSIONNEL DETAILS
        this._professionnelProfile = this.hiero.WatchProfessionnelProfile({
            next: (profile) => {
                if (profile) {
                    this.professionnel = profile;
                    console.log(this.professionnel);
                    if (firebase.auth().currentUser.emailVerified === true ||
                        new Date(firebase.auth().currentUser.metadata.creationTime).getTime() < 1648116134530) {
                    }
                    else if (firebase.auth().currentUser.emailVerified === false &&
                        new Date(firebase.auth().currentUser.metadata.creationTime).getTime() >= 1648116134530) {
                        this.router.navigate(["compte", "connexion"]);
                    }
                    if (!IsValidProfile(profile)) {
                        this.router.navigate(["verify"]);
                    }
                    else {
                        // Check if we have already defined some services.
                        this._professionnelSub = this.hiero.WatchProfessionnel({
                            next: (professionnel) => __awaiter(this, void 0, void 0, function* () {
                                const hasServices = yield professionnel.hasServicesOrders();
                                this._professionnelSub.unsubscribe();
                                // if (!hasServices) {
                                //   this.router.navigate(['app', 'commandes']);
                                // }
                            }),
                        });
                    }
                }
            },
        });
        this._titleExtractor.update();
        this._routerSubs = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this._titleExtractor.update();
            }
        });
    }
    ngOnDestroy() {
        this._subscription.unsubscribe();
        this._eventSubs.unsubscribe();
        this._professionnelProfile.unsubscribe();
        this._routerSubs.unsubscribe();
        if (this._professionnelSub) {
            this._professionnelSub.unsubscribe();
        }
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.hiero.Auth.logout();
            if (this.profile.isSeineMaritime)
                this.router.navigate(["compte", "seine-maritime", "login"]);
            else if (this.profile.isFidinam)
                this.router.navigate(["compte", "fidinam", "login"]);
            else
                this.router.navigate(["compte", "connexion"]);
        });
    }
    toggleSidebar() {
        this.sidebar.nativeElement.style = "width: 30px";
    }
}
