import { Component, OnInit, Inject, ElementRef } from "@angular/core";
import { HieroBDD } from "../../../services/hierobdd.service";
import { Chart } from "chart.js";

import { LocalisationService } from "../../../../../common/src/modules/localisation/localisation.service";
import { Localisation } from "../../../../../common/src/bdd/settings/Localisation";
import { ProfessionnelService } from "../../../services/professionnel.service";
import { InterpretationService } from "../../../services/interpretation.service";
import { TranslationsService } from "../../../services/translations.service";
import { InterprationDocument } from "../../../../../common/src/bdd/interfaces/IInterpretation";
import { PrestationType } from "../../../../../common/src/bdd/interfaces/types";
import { IProfessionnel } from "../../../../../common/src/bdd/interfaces/IProfessionnel";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  LineChart = [];
  public numberTradClassic: number = 0;
  public numberTradAsser: number = 0;
  public numberInterpretation: number = 0;
  public dateTime: Date = new Date();
  public rateTradClassic: number = 0;
  public rateTradAsser: number = 0;
  public rateInter: number = 0;
  public budget: number = 0;
  public deductedBudgetTotal: number = 0;
  public deductedBudgetMonth: number = 0;
  public budgetClassic: number = 0;
  public budgetAsser: number = 0;
  public budgetInter: number = 0;
  public commandList = [];
  private evolTradClassic = [];
  private evolTradAsser = [];
  private evolInter = [];
  public colleagueList = [];
  public phrase: string = "";
  private translationsCommand;
  private translationsThisMonth = [];
  private translationsAsserThisMonth = [];
  private translationsClassicThisMonth = [];
  private interpretationsCommand;
  private interpretationsThisMonth: InterprationDocument[];
  private proData: IProfessionnel;

  /** TO DO
   * how to get month in each language
   * toLocateString ==> return the locate language
   */
  public monthYear: string =
    this.dateTime.toLocaleString("default", {
      month: "long",
    }) +
    " " +
    this.dateTime.getFullYear();

  constructor(
    private hiero: HieroBDD,
    private localisation: LocalisationService,
    private elementRef: ElementRef,
    private professionnelService: ProfessionnelService,
    private interpretationService: InterpretationService,
    private translationsService: TranslationsService
  ) {}

  async ngOnInit() {
    this.phrase = await this.budgetPerMonth();

    //this.OrdersPlacedByMyCompany();

    await this.translationData();
    await this.interpretationData();

    this.proData = await this.professionnelService.getProfessionnelData();
    // get the pro's budget
    let bud = this.proData.budget;
    if (bud) this.budget = bud;

    this.deductedBudgetTotal = await this.getDeductedBudget();

    this.deductedBudgetMonth = await this.getDeductedBudgetThisMonth();

    this.commandList = await this.getAllCommandsThisMonth();

    this.evolTradClassic = await this.evolutionOfTrad(
      PrestationType.TRADUCTION_CLASSIQUE
    );
    this.evolTradAsser = await this.evolutionOfTrad(
      PrestationType.TRADUCTION_ASSERMENTEE
    );
    this.evolInter = await this.evolutionOfInter();

    this.getColleaguesCommands().then((data) => {
      this.colleagueList = data;
    });

    let htmlRef = this.elementRef.nativeElement.querySelector(`#lineChart`);
    this.LineChart = new Chart(htmlRef, {
      type: "line",
      data: {
        labels: [
          this.localisation.localise("dashboard_month_janv"),
          this.localisation.localise("dashboard_month_fevr"),
          this.localisation.localise("dashboard_month_mars"),
          this.localisation.localise("dashboard_month_avr"),
          this.localisation.localise("dashboard_month_mai"),
          this.localisation.localise("dashboard_month_juin"),
          this.localisation.localise("dashboard_month_juill"),
          this.localisation.localise("dashboard_month_aout"),
          this.localisation.localise("dashboard_month_sept"),
          this.localisation.localise("dashboard_month_oct"),
          this.localisation.localise("dashboard_month_nov"),
          this.localisation.localise("dashboard_month_dec"),
        ],
        datasets: [
          {
            label: this.localisation.localise("traduction_assermentee"),
            data: this.evolTradAsser,
            fill: false,
            lineTension: 0.2,
            borderColor: "#702963",
            borderWidth: 2,
          },
          {
            label: this.localisation.localise("traduction_classic"),
            data: this.evolTradClassic,
            fill: false,
            lineTension: 0.2,
            borderColor: "#6AA7A4",
            borderWidth: 2,
          },
          {
            label: this.localisation.localise("interpretation"),
            data: this.evolInter,
            fill: false,
            lineTension: 0.2,
            borderColor: "#ff7c79",
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          text: this.localisation.localise("dashboard_pro_command_year"),
          display: true,
        },
        legend: {
          labels: {
            fontSize: 12,
          },
          padding: {
            bottom: 5,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }

  private async getAllCommandsThisMonth() {
    let commands;
    commands = await this.getCommands();

    let commandFiltred = commands.filter((v) => {
      if (v.translationData)
        return (
          v.translationData.prestationInfo.state != "validated-pro" &&
          v.translationData.prestationInfo.state != "validated"
        );
      else if (v.interpretationData)
        return (
          v.interpretationData.state != "validated-pro" &&
          v.interpretationData.state != "validated"
        );
    });

    if (commandFiltred.length < 6) return commandFiltred;
    else return commandFiltred.slice(0, 6);
  }

  private async translationData() {
    // get all translations commands
    this.translationsCommand = await this.translationsService.getTranslations();

    // get this month's translations commands
    this.translationsThisMonth =
      this.translationsService.getTranslationsThisMonth();

    // this month's Classic translations commands
    this.translationsClassicThisMonth = this.translationsThisMonth.filter(
      (value) => value.type === PrestationType.TRADUCTION_CLASSIQUE
    );

    // this month's Asser translations commands
    this.translationsAsserThisMonth = this.translationsThisMonth.filter(
      (value) => value.type === PrestationType.TRADUCTION_ASSERMENTEE
    );

    // get the number of Classic translations commands for this month
    this.numberTradClassic = this.translationsClassicThisMonth.length;

    // get the number of Asser translations commands for this month
    this.numberTradAsser = this.translationsAsserThisMonth.length;

    // get the budget of Classic translations commands for this month
    this.translationsClassicThisMonth.forEach(
      (data) => (this.budgetClassic += data.translationData.budget)
    );

    // get the budget of Asser translations commands for this month
    this.translationsAsserThisMonth.forEach(
      (data) => (this.budgetAsser += data.translationData.budget)
    );

    this.rateMonthNumberTrad().then((tab) => {
      this.rateTradClassic = tab[0];
      this.rateTradAsser = tab[1];
    });
  }

  private async interpretationData() {
    // get all interpretation commands
    this.interpretationsCommand =
      await this.interpretationService.getInterpretations();

    // get this month's interpretation commands
    this.interpretationsThisMonth =
      this.interpretationService.getInterpretationsThisMonth();

    // get the number of interpretation commands for this month
    this.numberInterpretation = this.interpretationsThisMonth.length;

    // get the budget of interpretation commands for this month
    this.interpretationsThisMonth.forEach(
      (data) => (this.budgetInter += data.interpretationData.price.priceTTC)
    );

    this.rateInter = await this.rateMonthNumberInter();
  }

  // Compare number of traslations for this month and the last one and calculate the evolution rate
  async rateMonthNumberTrad() {
    //  get last month's translations
    let vd = await this.translationsService.getTranslationsLastMonth();

    let vdc: number = vd[0];
    let vda: number = vd[1];

    let tab = [];
    // classic
    if (!(vdc === 0)) {
      let ratec = ((this.numberTradClassic - vdc) / vdc) * 100;
      tab[0] = Math.round(ratec * 100) / 100;
    } else if (vdc === 0 && !(this.numberTradClassic === 0)) {
      tab[0] = 100;
    } else {
      tab[0] = 0;
    }
    // asser
    if (!(vda === 0)) {
      let ratea = ((this.numberTradAsser - vda) / vda) * 100;
      tab[1] = Math.round(ratea * 100) / 100;
    } else if (vda === 0 && !(this.numberTradAsser === 0)) {
      tab[1] = 100;
    } else {
      tab[1] = 0;
    }

    return tab;
  }

  // Compare number of interpretations for this month and the last one and calculate the evolution rate
  async rateMonthNumberInter() {
    //  get last month's interpretations
    let vd: number =
      await this.interpretationService.getInterpretationsLastMonth();

    if (!(vd === 0)) {
      let rate = ((this.numberInterpretation - vd) / vd) * 100;
      return Math.round(rate * 100) / 100;
    } else if (vd === 0 && !(this.numberInterpretation === 0)) {
      return 100;
    } else {
      return 0;
    }
  }

  // get all deducted budget
  async getDeductedBudget() {
    let deductBudget: number = 0;

    // all deducted budget for translation commands
    this.translationsCommand.forEach(
      (element) => (deductBudget += element.translationData.budget)
    );

    // all deducted budget for interpretation commands
    this.interpretationsCommand.forEach(
      (element) => (deductBudget += element.interpretationData.price.priceTTC)
    );

    return deductBudget;
  }

  // get this month's deducted budget
  async getDeductedBudgetThisMonth() {
    let deductBudget: number = 0;

    // this month's deducted budget for translation commands
    this.translationsThisMonth.forEach(
      (data) => (deductBudget += data.translationData.budget)
    );

    // this month's deducted budget for interpretation commands
    this.interpretationsThisMonth.forEach(
      (data) => (deductBudget += data.interpretationData.price.priceTTC)
    );

    return deductBudget;
  }

  // get this month's commands
  async getCommands() {
    let listCommand = [];

    this.translationsThisMonth.map((data) => {
      listCommand.push(data);
    });
    this.interpretationsThisMonth.map((data) => {
      listCommand.push(data);
    });

    listCommand.sort(this.sortCommands);

    return listCommand;
  }

  //  Evolution of the number of Asser/classic traslations in the current year
  async evolutionOfTrad(type: string) {
    let data = [];
    for (let i = 0; i < 12; i++) {
      let tab = this.translationsCommand
        .filter((data) => data.type === type)
        .filter(
          (data) =>
            data.translationData.createdAt.toDate().getMonth() === i &&
            i <= new Date().getMonth()
        );

      let numberTranslationEachMonth = tab.length;

      data.push(numberTranslationEachMonth);
    }

    return data;
  }

  //  Evolution of the number of Interpretations in the current year
  async evolutionOfInter() {
    let data = [];
    for (let i = 0; i < 12; i++) {
      let tab = this.interpretationsCommand.filter(
        (data) =>
          data.interpretationData.sendTime.toDate().getMonth() === i &&
          i <= new Date().getMonth()
      );

      let numberInterpretationEachMonth = tab.length;

      data.push(numberInterpretationEachMonth);
    }
    return data;
  }

  async getColleagues() {
    let proEmail = this.proData.email;
    let businessName = this.proData.businessName;
    let tabCol = [];

    // get this pro's colleagues
    const missions = this.hiero.DB.collection("professionnels").where(
      "businessName",
      "==",
      businessName
    );
    return missions.get().then((trouve) => {
      let tab = trouve.docs;
      tab.map((col) => {
        if (col.data().email != proEmail) {
          tabCol[col.id] = col.data();
        }
      });
      return tabCol;
    });
  }

  async getColleaguesCommands() {
    let colTab = await this.getColleagues();
    let colCommandList = [];
    let colleagueList = [];
    let name = "";

    for (let [key, value] of Object.entries(colTab)) {
      const proInfo = await this.hiero.DB.collection("users").get();
      proInfo.docs.map((_) => {
        if (value.uid === _.id) {
          name = _.data().profile.familyName + " " + _.data().profile.givenName;
        }
      });
      const proTrads = await this.hiero.DB.collection("professionnel_commandes")
        .where("professionnelId", "==", key)
        .get();
      proTrads.docs.map((_) => {
        colCommandList.push({
          name: name,
          data: _.data(),
        });
      });

      const proInter = await this.hiero.DB.collection(
        "interpretations_commandes"
      )
        .where("professionnelId", "==", key)
        .get();
      proInter.docs.map((_) => {
        colCommandList.push({
          name: name,
          interpretationData: _.data(),
        });
      });
    }

    colCommandList.sort(this.sortCommands);

    return colCommandList;
  }

  // function to sort all commands by date (from recent to old)
  private sortCommands = (x, y) => {
    if (x.translationData)
      if (y.translationData)
        return (
          y.translationData.createdAt.toDate() -
          x.translationData.createdAt.toDate()
        );
      else
        return (
          y.interpretationData.sendTime.toDate() -
          x.translationData.createdAt.toDate()
        );
    else {
      if (y.translationData)
        return (
          y.translationData.createdAt.toDate() -
          x.interpretationData.sendTime.toDate()
        );
      else
        return (
          y.interpretationData.sendTime.toDate() -
          x.interpretationData.sendTime.toDate()
        );
    }
  };

  budgetPerMonth() {
    let ladate = new Date();
    let phrase = "";
    let tab_mois;

    switch (this.localisation.CurrentLanguageISO639) {
      case "fr":
        tab_mois = new Array(
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre"
        );
        phrase =
          this.localisation.localise("dashboard_pro_budget_month") +
          tab_mois[ladate.getMonth()] +
          " " +
          new Date().getFullYear();
        return phrase;

      case "en":
        tab_mois = new Array(
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        );
        phrase =
          "Budget for the month of " +
          tab_mois[ladate.getMonth()] +
          " " +
          new Date().getFullYear();
        return phrase;

      case "es":
        tab_mois = new Array(
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        );
        phrase =
          "Presupuesto para el " +
          tab_mois[ladate.getMonth()] +
          " de " +
          new Date().getFullYear();
        return phrase;

      case "pt":
        tab_mois = new Array(
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        );
        phrase =
          "Orçamento para o mês de " +
          tab_mois[ladate.getMonth()] +
          " de " +
          new Date().getFullYear();
      default:
        return phrase;
    }
  }

  async OrdersPlacedByMyCompany() {
    await this.hiero.DB.collection("professionnel_commandes")
      .where("professionnelId", "==", "KWAL0rlVK9btE0rJVekG")
      .get();
  }
}
