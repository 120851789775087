import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HieroBDD } from "../../../../services/hierobdd.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GoogleGeo } from "../../../../services/google.services";
import * as firebase from "firebase";
import { ITraducteur } from "../../../../../../common/src/bdd/interfaces/ITraducteur";
import Swal from "sweetalert2";
import { LocalisationService } from "../../../../../../common/src/modules/localisation/localisation.service";
import { ITraducteurCopie } from "../commande/commande.component";
import { IProfessionnel } from "../../../../../../common/src/bdd/interfaces/IProfessionnel";
import { Professionnel } from "../../../../../../common/src/bdd/professionnel/Professionnel";
import { ProfessionnelServiceList } from "../../../../../../common/src/bdd/professionnel/ProfessionnelService";
import { environment } from "../../../../../environments/environment";
import { EnumPrestationState } from "../../../../../../common/src/bdd/interfaces/IPrestation";
import { InterpretationService } from "../../../../services/interpretation.service";
import { ProfessionnelService } from "../../../../services/professionnel.service";
import { TranslatorService } from "../../../../services/translator.service";
import { DevisService } from "../../../../services/devis.service";

export const ROOM_LINK =
  "https://video-app-6852-1089-dev.twil.io?passcode=87123868521089";

@Component({
  selector: "app-interprete",
  templateUrl: "./interprete.component.html",
  styleUrls: ["./interprete.component.scss"],
})

/**
 * TOTO:In the urgency I make a copy paste from commandComponent functions but it is necessary to rewrite the functions
 *
 */
export class InterpreteComponent implements OnInit {
  public interForm: FormGroup;
  public lang = [];
  public destLang = [];
  public translatorsCopies: [];
  private formValue: any;
  private state = EnumPrestationState.WaitingForTranslator;
  public show = false;
  private professionnelId: string;
  private professionnelData: IProfessionnel;
  showLoading = false;
  private TVA: number = 1.2;
  private tradId: string;

  public typeIntervention = [
    {
      id: 1,
      name: this.localisation.localise("menu_label_sur_place"),
    },
    /* {
      id: 2,
      name: 'Par téléphone'
    },*/
    {
      id: 3,
      name: this.localisation.localise("menu_label_visio"),
    },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public hiero: HieroBDD,
    private geo: GoogleGeo,
    private localisation: LocalisationService,
    private interpretationService: InterpretationService,
    private professionnelService: ProfessionnelService,
    private translatorService: TranslatorService,
    private devisService: DevisService
  ) {}

  async ngOnInit() {
    this.createForm();
    this.interForm
      .get("intervention")
      .valueChanges.subscribe((intervention) => {
        if (intervention.id === 1) this.interForm.get("address").enable();
        else {
          this.interForm.get("address").setValue("");
          this.interForm.get("address").disable();
        }
      });

    this.lang = await this.translatorService.getSourceLanguage();

    this.interForm.get("srcLang").valueChanges.subscribe((lang) => {
      this.getDestLang(lang);
    });
    this.getProfData();
  }

  private async getProfData() {
    this.professionnelData =
      await this.professionnelService.getProfessionnelData();
    this.professionnelId = await this.professionnelService.getProfessionnelId();
  }

  private createForm(): void {
    this.interForm = this.formBuilder.group({
      srcLang: ["", Validators.required],
      destLang: ["", Validators.required],
      event: ["", Validators.required],
      date: ["", Validators.required],
      time: ["", Validators.required],
      extHours: [
        "00:30",
        [
          Validators.required,
          Validators.pattern(
            /^(00:[3-5][0-9])|((0[1-9]|1[0-9]|2[0-3]):[0-5][0-9])$/
          ),
        ],
      ],
      intervention: ["", Validators.required],
      address: [{ value: "", disabled: true }, Validators.required],
      bon: [""],
      context: ["", Validators.required],
    });
  }

  public async getDestLang(lang) {
    this.interForm.get("destLang").setValue("");
    this.destLang = await this.translatorService.getDestinationLang(lang);
  }

  public async onSubmitForm() {
    this.formValue = this.interForm.value;
    console.log("valeur du formulaire", this.interForm.value);
    this.translatorsCopies = await this.translatorService.getProperInterpreters(
      this.formValue.destLang,
      this.formValue.extHours
    );

    this.show = true;
  }

  public async getTranslatorNameOnClickButton(translatorId: string) {
    console.log(translatorId);

    if (translatorId != null) {
      if (
        this.hiero.Auth.User.Profile.isANR ||
        this.hiero.Auth.User.Profile.isExpat ||
        this.professionnelData.isDevis ||
        this.hiero.Auth.User.Profile.isSeineMaritime ||
        this.hiero.Auth.User.Profile.isFidinam
      ) {
        this.createData(translatorId, true);
      } else {
        this.createData(translatorId, false);
      }
    }
  }

  private async createData(translatorId: string, isDevis: boolean) {
    this.showLoading = true;

    const isSelectedTrad: any = this.translatorsCopies.find(
      (el: any) => el.translatorId === translatorId
    );

    await this.interpretationService.addInterpretation(
      this.formValue,
      isSelectedTrad,
      isDevis
    );

    this.loadingFile();
  }

  // Pour geolocaliser l'adresse au moment de la saisie de l'interpretation
  /**
   * async getLocalisation(addr: string) {
    await this.geo.geocode(addr);
  }
   * */

  public loadingFile() {
    this.show = false;
    this.showLoading = true;
    Swal.fire({
      title: this.localisation.localise("reset_password_send"),
    }).then(() => {
      this.activeModal.close();
    });
  }
}
