<div
  id="page-container"
  [ngClass]="isShow ? 'page-sidebar-fixed page-header-fixed' : ''"
>
  <div id="header" class="header navbar-default" *ngIf="isShow">
    <nav
      class="navbar navbar-expand-lg navbar-light bg-white justify-content-between"
    >
      <a
        *ngIf="!profile.isSeineMaritime && !profile.isFidinam"
        [routerLink]="['/app/dashboard']"
        class="navbar-brand"
      >
        <img
          src="../assets/img/logo/bird.png"
          height="80px"
          style="margin-right: 10px"
        />{{ "main_header_title" | localise }}
        <font size="3">{{ "main_header_subtitle_pro" | localise }}</font>
      </a>
      <a
        *ngIf="profile.isSeineMaritime"
        [routerLink]="['/app/commandes']"
        class="navbar-brand"
      >
        <img
          src="../assets/img/logo/logo_hiero_small.png"
          height="80px"
          style="margin-right: 10px"
        />{{ "main_header_title" | localise }}
        <font size="3">{{ "main_header_subtitle_pro" | localise }}</font>
      </a>
      <a
        *ngIf="profile.isFidinam"
        [routerLink]="['/app/commandes']"
        class="navbar-brand"
      >
        <img
          src="../assets/img/logo/bird1.png"
          height="80px"
          style="margin-right: 10px"
        />{{ "main_header_title" | localise }}
        <font size="3">{{ "main_header_subtitle_pro" | localise }}</font>
      </a>

      <div class="d-flex flex-row align-items-center">
        <div class="nav-item dropdown navbar-user">
          <app-language-dropdown></app-language-dropdown>
        </div>

        <div dropdown class="nav-item dropdown navbar-user">
          <a
            href="javascript:;"
            dropdownToggle
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
          >
            <img
              *ngIf="photo == null"
              src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
              alt="imageProfile"
              class="rounded-circle border pfp"
            />
            <img
              class="rounded-circle border pfp"
              *ngIf="photo"
              [src]="photo"
              alt="imageProfile"
            />
            <span class="d-none d-md-inline"
              >{{ profile.givenName }} {{ profile.familyName }}</span
            >
          </a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
            <!--
              <a [routerLink]="['traducteur']" class="dropdown-item">Éditer mon profil</a>
              <a href="javascript:;" class="dropdown-item">Règles de confidentialité & Conditions d'utilisation</a>
              <div class="dropdown-divider"></div>
            -->
            <a href="javascript:;" (click)="logout()" class="dropdown-item">{{
              "main_header_user_menu_logout" | localise
            }}</a>
          </div>
        </div>
        <div *ngIf="profile.isSeineMaritime">
          <img
            src="../../../../assets/img/logo/seine_maritime_logo.png"
            alt="logoSeineMaritime"
            class="img-fluid"
            width="50px"
          />
        </div>
        <div *ngIf="profile.isFidinam">
          <img
            src="../../../../assets/img/logo/fidinam_logo.png"
            alt="logoSeineMaritime"
            class="img-fluid"
            width="70px"
          />
        </div>
      </div>
    </nav>
  </div>

  <!--  --- --- --- -->

  <!--
    <div class="navbar-header">
      <a [routerLink]="['home']" class="navbar-brand"><img src="../assets/img/logo/bird.png" height="80px">   Hierø - <font size="3"> Plateforme traducteur </font></a>
      <button type="button" class="navbar-toggle" data-click="sidebar-toggled">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>

    <ul class="navbar-nav navbar-right">


      <li class="dropdown">
        <a href="javascript:;" data-toggle="dropdown" class="dropdown-toggle f-s-14">
          <i class="fa fa-bell"></i>
          <span class="label"></span>
        </a>
        <ul class="dropdown-menu media-list dropdown-menu-right">
          <li class="dropdown-header">NOTIFICATIONS</li>
          <li class="media">
            <a href="javascript:;">
              <div class="media-left">
                <i class="fa fa-plus media-object bg-silver-darker"></i>
              </div>
              <div class="media-body">
                <h6 class="media-heading"> Hierø</h6>
                <div class="text-muted f-s-11">version 1.0</div>
              </div>
            </a>
          </li>

        </ul>
      </li>

      <li dropdown class="dropdown navbar-user">
        <a dropdownToggle class="dropdown-toggle" data-toggle="dropdown">
          <img src="../assets/img/user/user-icon.png" alt="" />
          <span class="d-none d-md-inline">{{profile.givenName}} {{profile.familyName}}</span> <b class="caret"></b>
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <a [routerLink]="['traducteur']" class="dropdown-item">Éditer mon profil</a>
          <a href="javascript:;" class="dropdown-item">Règles de confidentialité & Conditions d'utilisation</a>
          <div class="dropdown-divider"></div>
          <a (click)="logout()" class="dropdown-item">Se déconnecter</a>
        </div>
      </li>
    </ul>
    -->

  <!-- begin #sidebar -->
  <div
    id="sidebar"
    [ngClass]="
      profile.isSeineMaritime
        ? 'sidebar seine-maritime-color-primary'
        : profile.isFidinam
        ? 'sidebar fidinam-color-primary'
        : 'sidebar'
    "
    *ngIf="isShow"
  >
    <!-- begin sidebar scrollbar -->
    <div data-scrollbar="true" data-height="100%">
      <!-- begin sidebar user -->
      <ul class="nav">
        <li
          [ngClass]="
            profile.isSeineMaritime
              ? 'nav-profile seine-maritime-color-primary'
              : profile.isFidinam
              ? 'nav-profile fidinam-color-primary'
              : 'nav-profile'
          "
        >
          <!--<a href="javascript:;" data-toggle="nav-profile">
          <div class="cover with-shadow"></div>-->
          <!-- <div class="image">
            <img src="../assets/img/user/user-icon-inverted.png" alt="" />
          </div> -->
          <div class="info">
            {{ professionnel.businessName }}
            <small>{{ "main_left_menu_title_pro" | localise }}</small>
          </div>
          <!--</a>-->
        </li>
        <!--
          <li>
          <ul class="nav nav-profile">
            <li><a (click)="logout()"><i class="fa fa-cog"></i> Se déconnecter </a></li>
            <li><a href="javascript:;"><i class="fa fa-question-circle"></i> Aide</a></li>
          </ul>
        </li>
        -->
      </ul>
      <!-- end sidebar user -->
      <!-- begin sidebar nav -->
      <ul class="nav">
        <li class="nav-header">
          {{ "main_left_menu_section_navigation" | localise }}
        </li>
        <!--
        <li
          [routerLink]="['home']"
          [routerLinkActive]="['active']"
          class="clickable shadow-none"
        >
          <a><i class="fa fa-circle-o-notch"></i> <span>Accueil</span></a>
        </li>
        <li
          [routerLink]="['dashboard']"
          [routerLinkActive]="['active']"
          class="clickable shadow-none"
        >
          <a><i class="fa fa-line-chart"></i> <span>Tableau de bord</span></a>
        </li>
        -->
        <li
          [routerLink]="['dashboard']"
          [routerLinkActive]="['active']"
          class="clickable shadow-none"
          *ngIf="!profile.isSeineMaritime && !profile.isFidinam"
        >
          <a
            ><i id="fa" class="fa fa-table"></i>
            <span>{{
              "main_left_menu_section_navigation_dashboard" | localise
            }}</span></a
          >
        </li>

        <li
          [routerLink]="['commandes']"
          class="clickable shadow-none"
          [ngClass]="
            profile.isSeineMaritime
              ? router.isActive('/app/commandes', true)
                ? 'li-seine-maritime-color'
                : ''
              : profile.isFidinam
              ? router.isActive('/app/commandes', true)
                ? 'li-fidinam-color'
                : ''
              : router.isActive('/app/commandes', true)
              ? 'li-color'
              : ''
          "
        >
          <a
            ><i class="fa fa-globe"></i
            ><span>{{
              "main_left_menu_section_navigation_order" | localise
            }}</span></a
          >
        </li>

        <li
          [routerLink]="['devis']"
          class="clickable shadow-none"
          *ngIf="
            profile.isANR ||
            profile.isExpat ||
            professionnel.isDevis ||
            profile.isSeineMaritime ||
            profile.isFidinam
          "
          [ngClass]="
            profile.isSeineMaritime
              ? router.isActive('/app/devis', true)
                ? 'li-seine-maritime-color'
                : ''
              : profile.isFidinam
              ? router.isActive('/app/devis', true)
                ? 'li-fidinam-color'
                : ''
              : router.isActive('/app/devis', true)
              ? 'li-color'
              : ''
          "
        >
          <a
            ><i class="fa fa-folder-open"></i
            ><span>
              {{ "main_left_menu_section_navigation_devis" | localise }}
            </span></a
          >
        </li>

        <li class="nav-header">
          {{ "main_left_menu_section_parameters" | localise }}
        </li>

        <li
          [routerLink]="['professionnel']"
          class="clickable shadow-none"
          [ngClass]="
            profile.isSeineMaritime
              ? router.isActive('/app/professionnel', true)
                ? 'li-seine-maritime-color'
                : ''
              : profile.isFidinam
              ? router.isActive('/app/professionnel', true)
                ? 'li-fidinam-color'
                : ''
              : router.isActive('/app/professionnel', true)
              ? 'li-color'
              : ''
          "
        >
          <a
            ><i class="fa fa-address-card"></i
            ><span>{{
              "main_left_menu_section_parameters_agency" | localise
            }}</span></a
          >
        </li>
        <!--
        <li
          [routerLink]="['services']"
          [routerLinkActive]="['active']"
          class="clickable shadow-none"
        >
          <a><i class="fa fa-cart-plus"></i><span>{{ 'main_left_menu_section_parameters_services' | localise }}</span></a>
        </li>
        -->
        <li
          [routerLink]="['profile']"
          class="clickable shadow-none"
          [ngClass]="
            profile.isSeineMaritime
              ? router.isActive('/app/profile', true)
                ? 'li-seine-maritime-color'
                : ''
              : profile.isFidinam
              ? router.isActive('/app/profile', true)
                ? 'li-fidinam-color'
                : ''
              : router.isActive('/app/profile', true)
              ? 'li-color'
              : ''
          "
        >
          <a
            ><i class="fa fa-male"></i
            ><span>{{
              "main_left_menu_section_parameters_user" | localise
            }}</span></a
          >
        </li>
        <li
          [routerLink]="['factures']"
          [routerLinkActive]="['active']"
          class="clickable shadow-none"
          *ngIf="!profile.isSeineMaritime && !profile.isFidinam"
        >
          <a
            ><i class="fa fa-list-alt"></i
            ><span>
              {{
                "main_menu_left_section_navigation_factures" | localise
              }}</span
            ></a
          >
        </li>
        <li
          [routerLink]="['support']"
          class="clickable shadow-none"
          [ngClass]="
            profile.isSeineMaritime
              ? router.isActive('/app/support', true)
                ? 'li-seine-maritime-color'
                : ''
              : profile.isFidinam
              ? router.isActive('/app/support', true)
                ? 'li-fidinam-color'
                : ''
              : router.isActive('/app/support', true)
              ? 'li-color'
              : ''
          "
        >
          <a
            ><i class="fa fa-cogs"></i>
            <span>{{
              "main_left_menu_section_parameters_support" | localise
            }}</span></a
          >
        </li>
        <!--<li [routerLink]="['commandes']" [routerLinkActive]="['active']" class="clickable shadow-none">
          <a><i class="fa fa-globe"></i><span>{{ 'main_left_menu_section_navigation_order' | localise }}</span></a>
        </li>-->

        <li class="clickable shadow-none mt-3 ml-1">
          <h6 class="text-white m-auto">
            {{ " main_menu_left_section_navigation_assistance " | localise }}
          </h6>
        </li>
        <hr />
        <!-- <li
          [routerLink]="['card']"
          [routerLinkActive]="['active']"
          class="clickable shadow-none"
        >
          <a
            ><i class="fa fa-credit-card-alt"></i
            ><span>{{
              "main_left_menu_section_parameters_card" | localise
            }}</span></a
          >
        </li> -->
        <!--<li [routerLink]="['ticket']" [routerLinkActive]="['active']" class="clickable shadow-none">
          <a><i class="fa fa-question-circle"></i><span>{{ 'main_menu_left_section_navigation_ticket' | localise }}</span></a>
        </li> -->
        <li
          [routerLink]="['questions']"
          class="clickable shadow-none"
          [ngClass]="
            profile.isSeineMaritime
              ? router.isActive('/app/questions', true)
                ? 'li-seine-maritime-color'
                : ''
              : profile.isFidinam
              ? router.isActive('/app/questions', true)
                ? 'li-fidinam-color'
                : ''
              : router.isActive('/app/questions', true)
              ? 'li-color'
              : ''
          "
        >
          <a
            ><i class="fa fa-info-circle"></i
            ><span>{{
              "main_menu_left_section_navigation_question" | localise
            }}</span></a
          >
        </li>
        <!-- begin sidebar minify button -->
        <!--<li><a href="javascript:;" class="sidebar-minify-btn" data-click="sidebar-minify"><i class="fa fa-angle-double-left"></i></a></li>-->
        <!-- end sidebar minify button -->
      </ul>
      <!-- end sidebar nav -->
    </div>
    <!-- end sidebar scrollbar -->
  </div>
  <div class="sidebar-bg" *ngIf="isShow"></div>
  <!-- end #sidebar -->

  <!-- begin #content -->
  <div id="content" [ngClass]="isShow ? 'content content-full-width' : ''">
    <!-- begin profile -->
    <!--
      <div class="profile">
        <div class="profile-header">
          <div class="profile-header-cover"></div>
          <div class="profile-header-content">
            <div class="profile-header-img">
              <img src="../assets/img/user/user-image.png" alt="">
            </div>
            <div class="profile-header-info">
              <h4 class="m-t-10 m-b-5">{{profile.givenName}} {{profile.familyName}}</h4>

              <p class="m-b-10">Traducteur assermenté</p>
              <a [routerLink]="['profile']" class="btn btn-xs btn-yellow"> &#9998; Éditer mon profil</a>
            </div>
          </div>
          <ul class="profile-header-tab nav nav-tabs">
            <li class="nav-item"><a class="nav-link" data-toggle="tab"></a></li>
          </ul>
        </div>
      </div>
      -->
    <div class="profile" *ngIf="isShow">
      <div class="profile-header">
        <div class="profile-header-cover"></div>
        <div class="profile-header-content">
          {{ getTitle() }}
        </div>
      </div>
    </div>

    <div [ngClass]="isShow ? 'container-fluid pl-4 pt-4' : ''">
      <div class="row">
        <div class="col">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <notifier-container></notifier-container>
</div>
