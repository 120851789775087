<div class="modal-body">
  <div class="container">
    <div class="mb-3">
      <h3 class="text-center">{{ "refund" | localise }}</h3>
    </div>
    <div class="mt-5">
      <p>
        {{ "info_refund" | localise }}
      </p>
      <div>
        <div class="border m-3 p-2 text-center font-weight-bold">
          <p>{{ "support_email" | localise }}</p>
          <p>
            <img
              height="20px"
              width="20px"
              src="../../../assets/img/flags/france.png"
            />
            {{ "support_tel" | localise }}
          </p>
          <p>
            <img
              height="20px"
              width="20px"
              src="../../../assets/img/flags/picto-es.ico"
            />
            {{ "support_tel_es" | localise }}
          </p>
          <p>
            <img
              height="20px"
              width="20px"
              src="../../../assets/img/flags/picto-en.ico"
            />
            {{ "support_tel_en" | localise }}
          </p>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close()"
  >
    {{ "close_modal" | localise }}
  </button>
</div>
