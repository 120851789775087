<div class="modal-body">
  <div class="container">
    <div class="mb-3">
      <h3>{{ "virement" | localise }}</h3>
    </div>
    <div class="mt-5">
      <p>
        {{ "virement_message" | localise }}
      </p>
      <form [formGroup]="payForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label class="sr-only" for="solde">{{
            "facture_montant" | localise
          }}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                {{ "facture_montant" | localise }}
              </div>
            </div>
            <input
              type="number"
              class="form-control"
              id="solde"
              formControlName="solde"
            />
          </div>
          <div
            *ngIf="
              payForm.get('solde').hasError('required') &&
              payForm.get('solde').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
        </div>
        <button class="btn my-btn" [disabled]="payForm.invalid">
          {{ "paiement_button" | localise }}
        </button>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('close')"
  >
    {{ "close_modal" | localise }}
  </button>
</div>
