import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Professionnel } from '../../../../common/src/bdd/professionnel/Professionnel';
//import { Traducteur } from '../../../../common/src/bdd/traducteur/Traducteur';

import { HieroBDD } from '../../services/hierobdd.service';

@Component({
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})

export class BaseComponent implements OnInit, OnDestroy {

  private _subscription: Subscription;

  constructor(
    private hiero: HieroBDD,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {

    // WATCH LOG-IN STATUS
    this._subscription = this.hiero.WatchProfessionnelPresent(
      {
        next: (traducteur: Professionnel) => {

          if (traducteur != null) {
            this.router.navigate(['app']);
          } else {
            this.router.navigate(['compte', 'connexion']);
          }

        }
      }
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
