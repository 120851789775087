<div class="modal-body">
  <div class="container">

    <div class="row" >
      <div class="modal-body">
        <h5>{{'interpretation_info' | localise}}</h5>
        <p>
          {{'interpretation_paragraphe_1' | localise}}
        </p>
        <li>
          {{'interpretation_paragraphe_2' | localise}}
          <strong>{{'room_conf' | localise}} </strong>
          {{'interpretation_paragraphe_3' | localise}}
        </li>
        <li>  {{'interpretation_paragraphe_4' | localise}} <strong> {{'room_inter_pro' | localise}}</strong> {{'interpretation_paragraphe_5' | localise}}</li>
        <hr>
        <p>{{'interpretation_paragraphe_6' | localise}}</p>
        {{'interpretation_paragraphe_7' | localise}}
        <hr>
        <p> <strong>!NB: </strong> {{'interpretation_paragraphe_8' | localise}}
        </p>
        <hr>
        <p>{{ 'interpretation_paragraphe_9' | localise }}</p>
        <button class="btn btn btn-light" (click)="copyContent(ROOM_LINK)" value="{{ ROOM_LINK }}">{{ 'interpretation_paragraphe_10' | localise}}</button>
        <hr>
        <p>
          <a href="mailto:hamshakour93@gmail.com">{{ 'interpretation_paragraphe_11' | localise }}</a>
        </p>
        <hr>
        <!-- <h5>Les rooms</h5>
        <p><a href="https://urlz.fr/fTFM" class="tooltip-test" title="Tooltip" target="_blank">Copier coller ce lien dans le mail</a> and <a href="mailto:" class="tooltip-test" title="Tooltip">invitation</a> have tooltips on hover.</p>
        <hr> -->
        <h5>{{'conf' | localise}}</h5>
        <p> <strong>{{'warning' | localise}}</strong>{{'warning_1' | localise}} </p>

        <button class="btn btn-success" (click)="getRoom()">{{'join_room' | localise}}</button>
        <hr>

        <h5>{{'rooms' | localise}}</h5>
        <p> <strong>{{'warning' | localise}} </strong>{{'warning_1' | localise}} </p>

        <button class="btn btn-success" (click)="getRoom()">{{'join_room' | localise}}</button>



      </div>
    </div>

  </div>
</div>


