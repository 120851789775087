<div class="my-bg">
  <div class="container">
    <div class="row mt-5">
      <div class="col-8 col-md-8 mx-auto">
        <div class="banner">
          <img
            src="../../../../../assets/img/logo/fidinam_logo.png"
            alt="seine_maritime_logo"
            class="rounded mx-auto d-block"
            width="200px"
            height="50px"
          />

          <div class="d-flex p-20 flex-row-reverse">
            <app-language-dropdown class="lang btn"></app-language-dropdown>
          </div>
        </div>
        <div class="content border mb-3 ml-3">
          <div class="register-header">
            <div class="brand">
              <img
                src="../../../../../assets/img/logo/bird1.png"
                style="margin-right: 10px; height: 60px"
                alt="logohiero"
              />{{ "signup_main_text" | localise }}
            </div>
          </div>
          <p class="pull-left mt-3 ml-3">
            {{ "signup_main_title" | localise }}
          </p>
          <form
            [formGroup]="signupForm.Group"
            novalidate
            class="margin-bottom-0 ml-3"
            data-parsley-validate="true"
          >
            <app-form-input
              [parameters]="signupForm.GetInputElement('email')"
            ></app-form-input>
            <app-form-input
              [parameters]="signupForm.GetInputElement('password')"
            ></app-form-input>
            <app-form-input
              [parameters]="signupForm.GetInputElement('confirm')"
            ></app-form-input>
            <app-form-input
              [parameters]="signupForm.GetInputElement('familyName')"
            ></app-form-input>
            <app-form-input
              [parameters]="signupForm.GetInputElement('givenName')"
            ></app-form-input>
            <app-form-input
              [parameters]="signupForm.GetInputElement('telephone')"
            ></app-form-input>
            <app-form-general-error
              [submitForm]="signupForm"
            ></app-form-general-error>
            <div class="checkbox checkbox-css m-b-30">
              <div class="checkbox checkbox-css m-b-30">
                <input type="checkbox" id="agreement_checkbox1" value="" />
                <label for="agreement_checkbox1">
                  {{ "newsletter" | localise }}
                </label>
              </div>
            </div>
            <!--
            <div class="checkbox checkbox-css m-b-30">
              <div class="checkbox checkbox-css m-b-30">
                <input type="checkbox" id="agreement_checkbox" value="" required>
                <label for="agreement_checkbox">
                  {{'cdu' | localise }} <a href="#">{{'cdu1' | localise }}</a> {{'cdu3' | localise }}
                  <a href="#">{{'cdu2' | localise }}</a>
                </label>
              </div>
            </div>
            -->
            <button
              (click)="signupForm.Submit()"
              [disabled]="!signupForm.Valid"
              class="btn pull-right myColor"
            >
              {{ "signup_main_button" | localise }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
<div class="register register-with-news-feed" style="min-height: 100vh">
  <div class="news-feed">
    <div class="news-image" style="background-image: url(../../../../assets/img/login-bg/login-bg.png)"></div>
    <div class="news-caption">
      <h4 class="caption-title">{{ 'signup_caption_title_pro' | localise }}</h4>
      <p>
        {{ 'signup_caption_subtitle' | localise }}
      </p>
    </div>
  </div>
  <div class="right-content">
    <div class="d-flex p-20 flex-row-reverse">
      <app-language-dropdown></app-language-dropdown>
    </div>

    <div class="register-header">
      <div class="brand">
        <img src="../../../../assets/img/logo/bird.png"
             style="margin-right: 10px; height: 60px"/>{{ 'signup_main_logo' | localise }}
      </div>
    </div>
    <div class="register-content">
      <h1>{{ 'signup_main_title' | localise }}</h1>
      <p>{{ 'signup_main_subtitle_pro' | localise }}</p>
      <form [formGroup]="signupForm.Group" novalidate class="margin-bottom-0" data-parsley-validate="true">
        <app-form-input [parameters]="signupForm.GetInputElement('email')"></app-form-input>
        <app-form-input [parameters]="signupForm.GetInputElement('password')"></app-form-input>
        <app-form-input [parameters]="signupForm.GetInputElement('confirm')"></app-form-input>
        <app-form-input [parameters]="signupForm.GetInputElement('familyName')"></app-form-input>
        <app-form-input [parameters]="signupForm.GetInputElement('givenName')"></app-form-input>
        <app-form-input [parameters]="signupForm.GetInputElement('telephone')"></app-form-input>
        <app-form-general-error [submitForm]="signupForm"></app-form-general-error>
        <div class="checkbox checkbox-css m-b-30">
          <div class="checkbox checkbox-css m-b-30">
            <input type="checkbox" id="agreement_checkbox" value="">
            <label for="agreement_checkbox">
            En cliquant ici, vous acceptez nos <a href="#">conditions d'utilisation</a>
            </label>
          </div>
        </div>
        <div class="register-buttons ">
          <button (click)='signupForm.Submit()' [disabled]="!signupForm.Valid"
                  class="btn btn-block btn-lg myColor">{{ 'signup_main_button' | localise }}</button>
        </div>
        <div class="m-t-20 m-b-20 p-b-20 text-inverse">
          <a [routerLink]="['..', 'connexion']">{{ 'signup_main_to_signup' | localise }}</a>
        </div>
        <app-wait [show]='busy'></app-wait>
        <hr/>
        <p class="text-center">
          {{ 'signup_main_copyright' | localise }}
        </p>
      </form>
    </div>
  </div>
</div>
-->
