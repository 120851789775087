import { Component, OnInit, OnDestroy } from "@angular/core";
import { HieroBDD } from "../../../services/hierobdd.service";
import * as firebase from "firebase";
import * as jsPDF from "jspdf";
import { LocalisationService } from "../../../../../common/src/modules/localisation/localisation.service";
import { Language } from "../../../../../common/src/bdd/utility/Language";
import { Professionnel } from "../../../../../common/src/bdd/professionnel/Professionnel";
import { ProfessionnelServiceList } from "../../../../../common/src/bdd/professionnel/ProfessionnelService";
import { IPrestation } from "../../../../../common/src/bdd/interfaces/IPrestation";
import { IDocument } from "../../../../../common/src/bdd/interfaces/IDocument";
import { IFile } from "../../../../../common/src/bdd/interfaces/IFile";
import { ITraducteurCopie } from "../commandes/commande/commande.component";
import { EmptyTraducteurProfile } from "../../../../../common/src/bdd/interfaces/ITraducteur";
import Swal from "sweetalert2";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Prestation } from "../../../../../common/src/bdd/prestation/Prestation";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import * as i18nIsoLanguages from "@cospired/i18n-iso-languages";
import { InterpretationService } from "../../../services/interpretation.service";
import { DevisService } from "../../../services/devis.service";
import { Subscription } from "rxjs/Subscription";
import { ProfessionnelService } from "../../../services/professionnel.service";
import { EnumDevisState } from "../../../../../common/src/bdd/interfaces/IDevis";
import { PrestationType } from "../../../../../common/src/bdd/interfaces/types";

var doc = new jsPDF();
var pageHeight =
  doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

let img = new Image();
img.src = "../assets/img/logo/fact.png";

let tamponRefused = new Image();
tamponRefused.src =
  "../../../../assets/img/tampon/image_2022-05-03_12-49-42.png";

let tamponAccepted = new Image();

tamponAccepted.src = "../../../../assets/img/tampon/MicrosoftTeams-image.png";

export enum EnumPrestationState {
  Defining = "defining",
  WaitingForTranslator = "waiting-for-translator",
  CancelledByClient = "cancelled-by-client",
  WaitingForPayment = "waiting-for-payment",
  RefusedByTranslator = "refused-by-translator",
  Translating = "translating",
  WaitingForValidationFromClient = "waiting-for-client-to-validate",
  Validated = "validated",
}

@Component({
  selector: "devis",
  templateUrl: "./devis.component.html",
  styleUrls: ["./devis.component.scss"],
})
export class DevisComponent implements OnInit, OnDestroy {
  EmptyPrestation: IPrestation = {
    uid: "",
    state: EnumPrestationState.WaitingForTranslator,
    srcLanguageIso639: "",
    destLanguageIso639: "",
    srcCountryCode: "",
    documents: [],
    traducteurId: null,
    traducteur: null,
    price: {
      traducteurHT: 0,
      hieroMarginPercent: 0,
      hieroMarginValue: 0,
      subtotalHT: 0,
      tva: 0,
      tvaValue: 0,
      ttc: 0,
    },

    deviceStorageId: "",

    sentToTranslatorAt: 0,
    cancelledByClientAt: 0,
    acceptedByTranslatorAt: 0,
    refusedByTranslatorAt: 0,
    dueAt: 0,
    paidAt: 0,
    completedAt: 0,
    validatedByClientAt: 0,
    lastModifiedAt: 0,
    createdAt: 0,
    isProfessionalPrestation: false,
  };

  public allDevis = Array();
  private singleDevis;
  public client;
  private clientPro;
  private professionnelId: string;
  public show: boolean = false;
  public showLoading: boolean = false;
  public progress: number = 0;
  public accept: boolean = false;
  public refused: boolean = false;
  public p: number = 1;
  public allDevisSub: Subscription;

  constructor(
    private hiero: HieroBDD,
    public activeModal: NgbActiveModal,
    private localisation: LocalisationService,
    private router: Router,
    public _location: Location,
    private interpretationService: InterpretationService,
    private professionnelService: ProfessionnelService,
    private devisService: DevisService
  ) {}

  async ngOnInit(): Promise<void> {
    this.getDevis();

    this.client = await this.getClientData();

    this.clientPro = await this.adressPro();
  }

  async getDevis() {
    this.devisService.getAllDevis();
    this.allDevisSub = this.devisService.allDevisSubject.subscribe({
      next: (value: any[]) => {
        this.allDevis = value;
      },
    });
  }

  async getClientData() {
    const currentUser = firebase.auth().currentUser;
    const user = this.hiero.DB.collection("users").doc(currentUser.uid);

    const snap = await user.get();

    return snap.data();
  }

  async adressPro() {
    const currentUser = this.hiero.Auth.User;
    const docRef = await this.hiero.DB.collection("professionnels")
      .where("uid", "==", currentUser.Id)
      .get();

    let pro = docRef.docs;

    this.professionnelId = pro[0].id;

    return pro[0].data();
  }

  public async sendCommand(id) {
    this.singleDevis = await this.devisService.getADevis(id);

    const pro: Professionnel = new Professionnel(
      this.hiero.Auth.User,
      this.professionnelId
    );

    if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION) {
      await this.interpretationService.sendInterpretation(
        this.singleDevis.event,
        this.singleDevis.missions,
        this.singleDevis.translatorName,
        this.singleDevis.translatorId,
        this.singleDevis.price,
        id
      );
    } else {
      const commands = new ProfessionnelServiceList(pro);

      this.EmptyPrestation.srcLanguageIso639 = this.singleDevis.srcLang;
      this.EmptyPrestation.price.ttc = this.singleDevis.priceTTC;
      this.EmptyPrestation.price.traducteurHT = this.singleDevis.priceHT;
      this.EmptyPrestation.price.tva = this.singleDevis.priceTVA;
      this.EmptyPrestation.price.tvaValue = this.singleDevis.priceTVAVal;
      if (this.singleDevis.originLangISO369 !== "classic")
        this.EmptyPrestation.srcCountryCode = this.singleDevis.originLangISO369;
      else this.EmptyPrestation.srcCountryCode = "";
      this.EmptyPrestation.destLanguageIso639 = this.singleDevis.destLang;
      this.EmptyPrestation.traducteurId = this.singleDevis.tradId;
      this.EmptyPrestation.documents = this.singleDevis.presta.documents;
      this.EmptyPrestation.uid = this.singleDevis.tradId; // Id traductor
      this.EmptyPrestation.deviceStorageId = firebase.auth().currentUser.uid; // id prestation device
      this.EmptyPrestation.traducteur = this.singleDevis.presta.traducteur;
      this.EmptyPrestation.isProfessionalPrestation = true;

      const newPrestation = await Prestation.Create(
        this.hiero.Auth.User,
        this.EmptyPrestation
      );

      let facture = {
        numero: Math.floor(Math.random() * 1000000 + 1),
        date: new Date(),
        devisId: id,
        srcLang: this.singleDevis.srcLang,
        destLang: this.singleDevis.destLang,
        priceTTC: this.singleDevis.priceTTC,
        priceHT: this.singleDevis.priceHT,
        priceTVA: this.singleDevis.priceTVA,
        priceTVAVal: this.singleDevis.priceTVAVal,
        traducteur: this.singleDevis.traducteur,
        traducteurName: this.singleDevis.traducteurName,
        pagesNumber: this.singleDevis.pagesNumber,
        prestation: newPrestation.Id,
        typePrestation: this.singleDevis.typePrestation,
        professionnelId: this.singleDevis.professionnelId,
        purchaseOrder: this.singleDevis.purchaseOrder,
      };

      if (this.client.profile.isExpat) facture["isFactureExpat"] = true;

      let command = {
        professionnelId: this.singleDevis.professionnelId,
        srcLanguageIso639: this.singleDevis.srcLang,
        originLanguage: this.singleDevis.originLangISO369,
        destLanguageIso639: this.singleDevis.destLang,
        documentType: this.singleDevis.documentType,
        docFile: this.singleDevis.docFile,
        traducteurName: this.singleDevis.traducteurName,
        traducteurId: this.singleDevis.tradId,
        budget: this.singleDevis.budget,
        createdAt: new Date(),
        prestationId: newPrestation.Id,
        prestationInfo: this.EmptyPrestation,
        pagesNumber: this.singleDevis.pagesNumber,
        urgency: this.singleDevis.urgency,
        description: this.singleDevis.description,
        devisId: id,
      };

      if (this.client.profile.isExpat) command["isCommandExpat"] = true;

      await commands.addService(command);

      await firebase
        .firestore()
        .collection("mail")
        .add({
          bcc: [
            "B.balsomi@hiero-solution.com",
            "a.luis@hiero-solution.com",
            "w.moingt@hiero-solution.com",
            "n.ziani@hiero-solution.com",
          ],
          message: {
            subject: "Devis validé",
            text: "Nouvelle commande",
            html: `  <div style=" font-family: "Helvetica Neue", sans-serif; font-size: 20px;"> <div style="font-weight: bold"> <img src="https://firebasestorage.googleapis.com/v0/b/hiero-prod.appspot.com/o/Logos%2Fbird.png?alt=media&token=fa72f85d-1c09-48c1-ab47-ee7cde0eb80b" alt="" width="50" /> Hierø Solution </div> <hr /> <h3 style="text-align: center">Devis validé</h3> <p>Bonjour,</p><p style="line-height: 2; text-align:justify"> Nous avons une nouvelle commande dans notre système du client ${this.clientPro.businessName}. Le numéro de la prestation est ${newPrestation.Id}. Retrouve les détails de cette prestation dans ton espace admin. <p>Bien cordialement,</p> <p>L\'équipe Hierø</p> <hr /><div><h3 style= "text-align:center">Description</h3><p > ${this.singleDevis.description}</p></div> </div> `,
          },
        })
        .then(() => console.log("Queued email for delivery!"));

      // await  this.updateBudget();

      await firebase.firestore().collection("facture").add(facture);
      this.updateBudget(this.singleDevis.priceTTC);
    }
    await this.devisService.acceptDevis(id);

    await this.router
      .navigateByUrl("/refresh", { skipLocationChange: true })
      .then(() => {
        this.router.navigate([decodeURI(this._location.path())]);
      });
  }

  updateBudget(price: number) {
    if (this.clientPro.budget > 0) {
      this.clientPro.budget = this.clientPro.budget - price;

      this.hiero.DB.collection("professionnels")
        .doc(this.professionnelId)
        .update({
          budget: this.clientPro.budget,
        });
    }
  }

  public async refusedCommand(id) {
    await this.devisService.refuseDevis(id);

    await this.router
      .navigateByUrl("/refresh", { skipLocationChange: true })
      .then(() => {
        this.router.navigate([decodeURI(this._location.path())]);
      });
  }

  public loadingFile() {
    this.show = false;
    this.showLoading = true;
    Swal.fire({
      title: this.localisation.localise("reset_password_send"),
    }).then(() => {
      this.activeModal.close();
    });
  }

  ngOnDestroy(): void {
    this.allDevisSub.unsubscribe();
  }

  public async download(id) {
    let currentLang = this.localisation.CurrentLanguageISO639;

    i18nIsoLanguages.registerLocale(
      require("@cospired/i18n-iso-languages/langs/" + currentLang + ".json")
    );

    this.singleDevis = await this.devisService.getADevis(id);

    var doc = new jsPDF();

    // ####### Adresse de Hiero
    doc.setFontSize(12);
    doc.setFontType("bold");
    doc.text(this.localisation.localise("support_company"), 10, 10);

    doc.setFontSize(10);
    doc.setFontType("normal");
    doc.text(this.localisation.localise("support_addr1"), 10, 15);
    doc.text(this.localisation.localise("support_addr2"), 10, 20);
    doc.text(this.localisation.localise("support_tel"), 10, 25);
    doc.text(this.localisation.localise("support_email"), 10, 30);

    //##### Logo

    if (this.client.profile.isSeineMaritime) {
      img.src = "../assets/img/logo/fact-blue.png";
    }
    if (this.client.profile.isFidinam) {
      img.src = "../assets/img/logo/fact-fidinam.png";
    }
    doc.addImage(img, "png", 150, 10, 50, 40);

    // ##### Titre facture
    if (this.client.profile.isSeineMaritime) doc.setTextColor("#004158");
    else if (this.client.profile.isFidinam) doc.setTextColor("#d2163e");
    else doc.setTextColor("#702963");
    doc.setFontSize(20);
    doc.setFontType("bold");
    doc.text(this.localisation.localise("devis").toUpperCase(), 10, 70);
    doc.setFontType("normal");
    doc.setTextColor(255, 0, 0);
    doc.setFontSize(30);

    // Infos sous le titre de la facture:
    doc.setTextColor("black");
    doc.setFontSize(10);
    doc.setFontType("bold");
    doc.text(this.localisation.localise("devis_numero").toUpperCase(), 20, 80);
    doc.text(
      this.localisation.localise("devis_expiration").toUpperCase(),
      20,
      85
    );

    this.singleDevis.purchaseOrder
      ? doc.text(this.localisation.localise("bon_commande"), 20, 100)
      : null;

    doc.setFontType("normal");

    doc.text(`${this.singleDevis.numero}`, 60, 80);
    doc.text(
      new Date(this.singleDevis.date.seconds * 1000).toLocaleDateString(
        "fr-FR"
      ),
      60,
      85
    );

    this.singleDevis.purchaseOrder
      ? doc.text(this.singleDevis.purchaseOrder, 60, 100)
      : null;

    // ADRESSE DE FACTURATION

    doc.setFontType("bold");
    doc.text(
      this.localisation.localise("account_adresse").toUpperCase(),
      130,
      80
    );
    doc.setFontType("normal");
    doc.text(this.clientPro.businessName, 130, 87);
    doc.text(
      this.client.profile.givenName +
        " " +
        this.client.profile.familyName.toUpperCase(),
      130,
      92
    );
    doc.text(
      this.clientPro.address.number + " " + this.clientPro.address.street,
      130,
      97
    );
    doc.text(
      this.clientPro.address.code + " " + this.clientPro.address.city,
      130,
      102
    );
    doc.text(this.clientPro.address.country, 130, 107);

    // Ligne horizontal
    if (this.client.profile.isSeineMaritime) doc.setDrawColor("#004158");
    else if (this.client.profile.isFidinam) doc.setDrawColor("#d2163e");
    else doc.setDrawColor("#702963");
    doc.line(pageWidth - 20, 120, 20, 120);
    // doc.line(190, 151, 30, 151);

    // ####### Le tableau

    // # En tête
    if (this.client.profile.isSeineMaritime) doc.setFillColor(0, 155, 181);
    else if (this.client.profile.isFidinam) doc.setFillColor(210, 22, 62);
    else doc.setFillColor(112, 41, 99);

    doc.rect(20, 125, pageWidth - 40, 10, "F");

    doc.setTextColor("#FFFFFF");

    doc.text(this.localisation.localise("facture_qte").toUpperCase(), 21, 131);

    doc.text(
      this.localisation.localise("commande_produit").toUpperCase(),
      33,
      131
    );

    doc.text(
      this.localisation.localise("commande_desc").toUpperCase(),
      73,
      131
    );

    doc.text(
      this.localisation.localise("tasks_price_tva").toUpperCase(),
      127,
      131
    );

    doc.text(
      this.localisation.localise("facture_price").toUpperCase(),
      147,
      129
    );
    doc.text(
      this.localisation.localise("facture_unitaire").toUpperCase(),
      147,
      133
    );

    doc.text(
      this.localisation.localise("facture_montant").toUpperCase(),
      171,
      131
    );

    // # La data

    doc.setTextColor("black");

    doc.text(
      `${this.singleDevis.pagesNumber ? this.singleDevis.pagesNumber : 1}`,
      21,
      143
    ); //  QTE

    if (this.singleDevis.typePrestation === "Interprétation")
      var strArr = doc.splitTextToSize(this.singleDevis.event, 35);
    else var strArr = doc.splitTextToSize(this.singleDevis.documentType, 35);
    doc.text(strArr, 33, 143); //  Product
    let type: string = "";
    if (this.singleDevis.typePrestation === "Interprétation")
      type = this.localisation.localise("interpretation");
    if (this.singleDevis.typePrestation === "Traduction assermentée")
      type = this.localisation.localise("traduction_assermentee");
    if (this.singleDevis.typePrestation === "Traduction classique")
      type = this.localisation.localise("traduction_classic");

    var strDes = doc.splitTextToSize(
      type +
        " " +
        this.localisation.localise("prestation_order_from").toLowerCase() +
        " " +
        i18nIsoLanguages
          .getName(this.singleDevis.srcLang, currentLang)
          .toLowerCase() +
        " " +
        this.localisation.localise("order_language_to").toLowerCase() +
        " " +
        i18nIsoLanguages
          .getName(this.singleDevis.destLang, currentLang)
          .toLowerCase(),
      40
    );
    doc.text(strDes, 73, 143); // Description (faire la gestion de l'espace direcetement avec substr() et \n)

    doc.text("20 %", 127, 143); // TVA

    if (this.singleDevis.typePrestation === "Interprétation")
      doc.text(this.singleDevis.price.priceHT.toFixed(2), 152, 143);
    else
      doc.text(
        (
          this.singleDevis.priceHT.toFixed(2) /
          (this.singleDevis.pagesNumber ? this.singleDevis.pagesNumber : 1)
        ).toFixed(2),
        152,
        143
      );

    if (this.singleDevis.typePrestation === "Interprétation")
      doc.text(this.singleDevis.price.priceHT.toFixed(2), pageWidth - 30, 143);
    // Montant total
    else doc.text(this.singleDevis.priceHT.toFixed(2), pageWidth - 30, 143); // Montant total

    if (this.client.profile.isSeineMaritime) doc.setDrawColor("#004158");
    else if (this.client.profile.isFidinam) doc.setDrawColor("#d2163e");
    else doc.setDrawColor("#702963");
    // doc.line(190, 185, 30, 185);
    doc.line(pageWidth - 20, 155, 20, 155);

    // ##### Table des totaux
    //# Col 1

    doc.text(this.localisation.localise("total_ht").toUpperCase(), 127, 165);
    doc.text(this.localisation.localise("total_tva").toUpperCase(), 127, 175);
    doc.text(this.localisation.localise("total_ttc").toUpperCase(), 127, 185);
    doc.text(
      this.localisation.localise("facute_solde").toUpperCase(),
      127,
      195
    );

    // # Col 2

    if (this.singleDevis.typePrestation === "Interprétation") {
      doc.text(this.singleDevis.price.priceHT.toFixed(2), pageWidth - 30, 165);
      doc.text(
        this.singleDevis.price.priceTVAVal.toFixed(2),
        pageWidth - 30,
        175
      );
      doc.text(this.singleDevis.price.priceTTC.toFixed(2), pageWidth - 30, 185);
    } else {
      doc.text(this.singleDevis.priceHT.toFixed(2), pageWidth - 30, 165);
      doc.text(this.singleDevis.priceTVAVal.toFixed(2), pageWidth - 30, 175);
      doc.text(this.singleDevis.priceTTC.toFixed(2), pageWidth - 30, 185);
    }

    if (this.singleDevis.state === EnumDevisState.RefusedByClient) {
      doc.addImage(tamponRefused, "png", 30, 158, 45, 40);
    } else if (this.singleDevis.state === EnumDevisState.AcceptedByClient) {
      doc.addImage(tamponAccepted, "png", 30, 158, 45, 40);
    }

    if (this.client.profile.isSeineMaritime) doc.setTextColor("#004158");
    else if (this.client.profile.isFidinam) doc.setTextColor("#d2163e");
    else doc.setTextColor("#702963");
    doc.setFontSize(15);
    doc.setFontType("bold");
    if (this.singleDevis.typePrestation === "Interprétation")
      doc.text(
        this.singleDevis.price.priceTTC.toFixed(2) + " " + "EUR",
        165,
        195
      );
    else doc.text(this.singleDevis.priceTTC.toFixed(2) + " " + "EUR", 165, 195);

    doc.setFontType("normal");

    // Recap TVA
    doc.setFontSize(12);
    doc.setTextColor("black");
    doc.text(this.localisation.localise("recap_tva").toUpperCase(), 20, 208);
    doc.setFontSize(10);

    // doc.text('MONTANT DE TVA', )

    // doc.text('BASE HT', )

    // doc.text()

    doc.setTextColor("black");

    // Lignes inférieures

    // doc.setDrawColor('#702963')
    // doc.line(30, 230, 190, 230)
    // doc.line(20, 201, pageWidth-20, 201)
    if (this.client.profile.isSeineMaritime) doc.setFillColor(0, 155, 181);
    else if (this.client.profile.isFidinam) doc.setFillColor(210, 22, 62);
    else doc.setFillColor(112, 41, 99);

    doc.rect(20, 211, pageWidth - 40, 10, "F");

    doc.setTextColor("#FFFFFF");
    doc.text(this.localisation.localise("recap_taux").toUpperCase(), 30, 217);
    doc.text(this.localisation.localise("recap_mt_tva").toUpperCase(), 90, 217);
    doc.text(
      this.localisation.localise("recap_base_ht").toUpperCase(),
      170,
      217
    );

    doc.setTextColor("black");

    doc.text(
      this.localisation.localise("facture_tva_fr").toUpperCase(),
      25,
      227
    );
    if (this.singleDevis.typePrestation === "Interprétation") {
      doc.text(this.singleDevis.price.priceTVAVal.toFixed(2), 100, 227);
      doc.text(this.singleDevis.price.priceHT.toFixed(2), 179, 227);
    } else {
      doc.text(this.singleDevis.priceTVAVal.toFixed(2), 100, 227);
      doc.text(this.singleDevis.priceHT.toFixed(2), 179, 227);
    }

    // Pénalité et etc...
    doc.setFontType("normal");
    doc.setFontSize(10);

    doc.setTextColor("black");
    doc.text(
      this.localisation.localise("condition_paragraphe_1"),
      pageWidth / 2,
      245,
      {
        align: "center",
      }
    );
    doc.text(
      this.localisation.localise("condition_paragraphe_2"),
      pageWidth / 2,
      250,
      {
        align: "center",
      }
    );
    doc.text(
      this.localisation.localise("condition_paragraphe_3"),
      pageWidth / 2,
      255,
      { align: "center" }
    );

    // Footer

    if (this.client.profile.isSeineMaritime) doc.setDrawColor("#004158");
    else if (this.client.profile.isFidinam) doc.setDrawColor("#d2163e");
    else doc.setDrawColor("#702963");
    doc.line(0, pageHeight - 16, pageWidth, pageHeight - 16);
    doc.setFontSize(8);
    doc.text(
      this.localisation.localise("capital_sasu") +
        " - " +
        this.localisation.localise("agency_address_number") +
        " " +
        this.localisation.localise("rcs_infos") +
        " - " +
        this.localisation.localise("agency_address_number") +
        " " +
        this.localisation.localise("siret") +
        " - " +
        this.localisation.localise("agency_address_number") +
        " " +
        this.localisation.localise("tva_fr"),
      pageWidth / 2,
      pageHeight - 10,
      { align: "center" }
    );

    doc.save(
      this.localisation.localise("devis") +
        "_" +
        this.singleDevis.numero +
        ".pdf"
    );
  }
}
