<div class="row">
  <p class="ml-3 h4">{{ "consult_orders" | localise }}</p>
  <div class="col d-flex justify-content-end">
    <div>
      <!-- <button
        class="btn my-color mb-3 ml-2"
        (click)="openModal(0)"
        [disabled]="hasBudget"
      >
        {{ "new_order_ass" | localise }}
      </button> -->
      <button
        [ngClass]="
          currentUser.Profile.isSeineMaritime
            ? 'btn seine-maritime-color-secondary mb-3 ml-2'
            : currentUser.Profile.isFidinam
            ? 'btn fidinam-color-secondary mb-3 ml-2'
            : 'btn my-color mb-3 ml-2'
        "
        (click)="openModal(1)"
        [disabled]="
          !currentUser.Profile.isSeineMaritime &&
          !currentUser.Profile.isFidinam &&
          hasBudget
        "
      >
        {{ "new_order" | localise }}
      </button>
      <button
        [ngClass]="
          currentUser.Profile.isSeineMaritime
            ? 'btn seine-maritime-color-secondary mb-3 ml-2'
            : currentUser.Profile.isFidinam
            ? 'btn fidinam-color-secondary mb-3 ml-2'
            : 'btn my-color mb-3 ml-2'
        "
        (click)="openModal(2)"
        [disabled]="
          !currentUser.Profile.isSeineMaritime &&
          !currentUser.Profile.isFidinam &&
          hasBudget
        "
      >
        {{ "commander_interprete" | localise }}
      </button>
    </div>
  </div>
  <!--<div class="col">
    <div ngbDropdown class="d-inline-block">
      <button class="btn my-color mb-3" id="dropdownBasic1" ngbDropdownToggle> {{ 'menu' | localise}} </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="showDataDashboard(0)"> {{ 'menu_label_traductor_asser' | localise}} </button>
        <button ngbDropdownItem (click)="showDataDashboard(1)"> {{ 'menu_label_traductor_classic' | localise}} </button>
        <button ngbDropdownItem (click)="showDataDashboard(2)"> {{ 'menu_label_interprestation' | localise}} </button>
        <button ngbDropdownItem (click)="showDataDashboard(3)"> {{ 'menu_label_all' | localise}} </button>
      </div>
    </div> 
  </div>-->
</div>
<hr />
<div
  *ngIf="
    !currentUser.Profile.isSeineMaritime &&
    !currentUser.Profile.isFidinam &&
    hasBudget
  "
>
  <span>
    <h4>
      {{ "message_no_budget" | localise }}
      <span *ngIf="budget == 0 || budget == null; else elseBlock"> 0 (€) </span>
      <ng-template #elseBlock> {{ budget }} (€) </ng-template>
      {{ "message_contact" | localise }}
    </h4>
  </span>
  <hr />
</div>

<mat-tab-group
  [ngClass]="
    currentUser.Profile.isSeineMaritime
      ? 'mat-seine-maritime'
      : currentUser.Profile.isFidinam
      ? 'mat-fidinam'
      : 'mat-normal'
  "
>
  <!-- assermentation bloc -->
  <mat-tab>
    <div id="orders_pro">
      <ng-template mat-tab-label>
        <h6>
          {{ "main_left_menu_title_asser" | localise }}
        </h6>
      </ng-template>

      <table class="table table-striped border mt-4">
        <thead>
          <tr>
            <th scope="col" class="align-middle">
              {{ "tasks_sent_on" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ " type_doc_commande" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "order_language_from" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "pays_origine" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "order_language_to" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "page_number" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "tasks_price_ttc" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "order_status" | localise }}
            </th>

            <th scope="col" class="align-middle">
              {{ "order_traductor_delegate" | localise }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let commande of assermentationList
                | slice
                  : (pageAsser - 1) * pageSize
                  : (pageAsser - 1) * pageSize + pageSize
            "
            class="clickable"
            [routerLink]="[
              'traductions',
              commande.translationData.prestationId
            ]"
          >
            <td class="align-middle">
              <div
                [ngClass]="
                  currentUser.Profile.isSeineMaritime
                    ? 'badge seine-maritime-color-secondary p-2'
                    : currentUser.Profile.isFidinam
                    ? 'badge fidinam-color-secondary p-2'
                    : 'badge my-color p-2'
                "
              >
                {{
                  commande.translationData.createdAt.toDate()
                    | date: "dd/MM/yyyy "
                }}
              </div>
            </td>
            <td class="align-middle">
              {{ commande.translationData.documentType }}
            </td>
            <td class="align-middle">
              {{ commande.translationData.srcLanguageIso639 | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ commande.translationData.originLanguage | country }}
            </td>
            <td class="align-middle">
              {{ commande.translationData.destLanguageIso639 | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ commande.translationData.pagesNumber }}
            </td>
            <td class="align-middle">
              {{ commande.translationData.budget.toFixed(2) }}
            </td>

            <td class="align-middle">
              <span
                style="color: #935e10"
                *ngIf="
                  commande.translationData.prestationInfo.state ==
                  'waiting-for-translator'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
              <span
                style="color: #257f25"
                *ngIf="
                  commande.translationData.prestationInfo.state == 'translating'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
              <span
                style="color: #257f25"
                *ngIf="
                  commande.translationData.prestationInfo.state ==
                  'validated-pro'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  commande.translationData.prestationInfo.state ==
                  'waiting-for-client-to-validate'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  commande.translationData.prestationInfo.state ==
                  'refused-by-translator'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  commande.translationData.prestationInfo.state ==
                  'cancelled-by-client'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  commande.translationData.prestationInfo.state == 'validated'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
            </td>

            <td class="align-middle">
              {{ commande.translationData.traducteurName }}
            </td>
          </tr>
        </tbody>
      </table>
      <ngb-pagination
        *ngIf="!!assermentationList"
        [collectionSize]="assermentationList.length"
        [page]="pageAsser"
        [pageSize]="pageSize"
        (pageChange)="onPageChange($event, 0)"
        aria-label="Default pagination"
      ></ngb-pagination>
    </div>
  </mat-tab>
  <!-- end assermentation -->

  <!-- classic bloc -->
  <mat-tab>
    <div id="orders">
      <!--class="showBloc"-->
      <ng-template mat-tab-label>
        <h6>
          {{ "menu_label_traductor_classic" | localise }}
        </h6>
      </ng-template>

      <table class="table table-striped border mt-4">
        <thead>
          <tr>
            <!-- <th scope="col" class="align-middle">
            <a *ngIf="desc" class="badge badge-info menu_badge text-white clickable" (click)="toggleOrder()" ><i class="fa fa-angle-down"></i></a>
            <a *ngIf="!desc" class="badge badge-info menu_badge text-white clickable" (click)="toggleOrder()"><i class="fa fa-angle-up"></i></a>
            {{ 'order_id' | localise }}
          </th>-->
            <th scope="col" class="align-middle">
              {{ "tasks_sent_on" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ " type_doc_commande" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "order_language_from" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "order_language_to" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "page_number" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "tasks_price_ttc" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "order_status" | localise }}
            </th>
            <!--<th scope="col" class="align-middle">
            <a class="badge badge-info menu_badge text-white clickable" [ngbPopover]="popFilterContent" [popoverTitle]="popFilterTitle" [placement]="['bottom']"><i class="fa fa-filter"></i></a>
            {{ 'tasks_state' | localise }}
          </th>-->
            <th scope="col" class="align-middle">
              {{ "order_traductor_delegate" | localise }}
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let commande of classicList
                | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize
            "
            class="clickable"
            [routerLink]="[
              'traductions',
              commande.translationData.prestationId
            ]"
          >
            <td class="align-middle">
              <div
                [ngClass]="
                  currentUser.Profile.isSeineMaritime
                    ? 'badge seine-maritime-color-secondary p-2'
                    : currentUser.Profile.isFidinam
                    ? 'badge fidinam-color-secondary p-2'
                    : 'badge my-color p-2'
                "
              >
                {{
                  commande.translationData.createdAt.toDate()
                    | date: "dd/MM/yyyy "
                }}
              </div>
            </td>
            <td class="align-middle">
              {{ commande.translationData.documentType }}
            </td>
            <td class="align-middle">
              {{ commande.translationData.srcLanguageIso639 | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ commande.translationData.destLanguageIso639 | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ commande.translationData.pagesNumber }}
            </td>
            <td class="align-middle">
              {{ commande.translationData.budget.toFixed(2) }}
            </td>

            <td class="align-middle">
              <span
                style="color: #935e10"
                *ngIf="
                  commande.translationData.prestationInfo.state ==
                  'waiting-for-translator'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
              <span
                style="color: #257f25"
                *ngIf="
                  commande.translationData.prestationInfo.state == 'translating'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
              <span
                style="color: #257f25"
                *ngIf="
                  commande.translationData.prestationInfo.state ==
                  'validated-pro'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  commande.translationData.prestationInfo.state ==
                  'waiting-for-client-to-validate'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  commande.translationData.prestationInfo.state ==
                  'refused-by-translator'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  commande.translationData.prestationInfo.state ==
                  'cancelled-by-client'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  commande.translationData.prestationInfo.state == 'validated'
                "
                >{{
                  commande.translationData.prestationInfo.state
                    | prestationStateName
                }}</span
              >
            </td>

            <td class="align-middle">
              {{ commande.translationData.traducteurName }}
            </td>
          </tr>
        </tbody>
      </table>
      <ngb-pagination
        *ngIf="!!classicList"
        [collectionSize]="classicList.length"
        [page]="page"
        [pageSize]="pageSize"
        (pageChange)="onPageChange($event, 1)"
        aria-label="Default pagination"
      ></ngb-pagination>
    </div>
  </mat-tab>
  <!-- end bloc classic-->

  <!-- bloc interprétation -->
  <mat-tab label="{{ 'menu_label_interprestation' | localise }}">
    <div id="interpretation_list">
      <ng-template mat-tab-label>
        <h6>{{ "menu_label_interprestation" | localise }}</h6>
      </ng-template>

      <!-- <div >
            <button class="btn my-color white pull-right"  (click)="openModal(2)" [disabled]="hasBudget" >
              Commander un interprète </button>
          </div> -->
      <!-- interpetation bloc -->
      <table class="table table-striped border mt-4">
        <thead>
          <tr>
            <th scope="col" class="align-middle">
              {{ "tasks_sent_on" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "account_hist_pro_date" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "type_intervention" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "menu_label_event_name" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "order_language_from" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "order_language_to" | localise }}
            </th>
            <!--<th scope="col" class="align-middle">{{ 'order_status' | localise }}</th> -->
            <th scope="col" class="align-middle">
              {{ "delai_intervention" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "tasks_price_ttc" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "order_status" | localise }}
            </th>
            <th scope="col" class="align-middle">
              {{ "order_interprete_delegate" | localise }}
            </th>
            <!-- <th scope="col" class="align-middle">{{ "action" | localise }}</th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let commandes of commandesListInterpretations
                | slice
                  : (pageInter - 1) * pageSize
                  : (pageInter - 1) * pageSize + pageSize
            "
            class="clickable"
            [routerLink]="['interpretations', commandes.id]"
          >
            <td class="align-middle">
              <div
                [ngClass]="
                  currentUser.Profile.isSeineMaritime
                    ? 'badge seine-maritime-color-secondary p-2'
                    : currentUser.Profile.isFidinam
                    ? 'badge fidinam-color-secondary p-2'
                    : 'badge my-color p-2'
                "
              >
                {{
                  commandes.interpretationData.sendTime.toDate()
                    | date: "dd/MM/yyyy"
                }}
              </div>
            </td>
            <td class="align-middle">
              {{
                commandes.interpretationData.missions.date.toDate()
                  | date: "short"
              }}
            </td>
            <td
              class="align-middle"
              *ngIf="commandes.interpretationData.missions.intervention.id == 1"
            >
              {{ "menu_label_sur_place" | localise }}
            </td>
            <td
              class="align-middle"
              *ngIf="commandes.interpretationData.missions.intervention.id == 2"
            >
              {{ "menu_label_tel" | localise }}
            </td>
            <td
              class="align-middle"
              *ngIf="commandes.interpretationData.missions.intervention.id == 3"
            >
              {{ "menu_label_visio" | localise }}
            </td>
            <td class="align-middle">
              {{ commandes.interpretationData.event }}
            </td>
            <td class="align-middle">
              {{
                commandes.interpretationData.missions.srcLang | iso639ToNative
              }}
            </td>
            <td class="align-middle">
              {{
                commandes.interpretationData.missions.destLang | iso639ToNative
              }}
            </td>
            <!--<td class="align-middle"> {{ commandes.interpretationData.state | prestationStateName }}</td> -->
            <td class="align-middle">
              {{ commandes.interpretationData.missions.extHours }}
            </td>
            <td class="align-middle">
              {{ commandes.interpretationData.price.priceTTC.toFixed(2) }}
            </td>
            <td class="align-middle">
              <span
                style="color: #935e10"
                *ngIf="
                  commandes.interpretationData.state == 'waiting-for-translator'
                "
                >{{
                  commandes.interpretationData.state | prestationStateName
                }}</span
              >
              <span
                style="color: #257f25"
                *ngIf="commandes.interpretationData.state == 'translating'"
                >{{
                  commandes.interpretationData.state | prestationStateName
                }}</span
              >
              <span
                style="color: #257f25"
                *ngIf="
                  commandes.interpretationData.state == 'accepted-by-translator'
                "
                >{{
                  commandes.interpretationData.state | prestationStateName
                }}</span
              >
              <span
                style="color: #257f25"
                *ngIf="commandes.interpretationData.state == 'validated'"
                >{{
                  commandes.interpretationData.state | prestationStateName
                }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  commandes.interpretationData.state ==
                  'waiting-for-client-to-validate'
                "
                >{{
                  commandes.interpretationData.state | prestationStateName
                }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="commandes.interpretationData.state == 'validated-pro'"
                >{{
                  commandes.interpretationData.state | prestationStateName
                }}</span
              >
            </td>
            <td class="align-middle">
              {{ commandes.interpretationData.translatorName }}
            </td>
            <!-- <td class="align-middle">
              <button
                [ngClass]="
                  currentUser.Profile.isSeineMaritime
                    ? 'btn seine-maritime-color-secondary white pull-right'
                    : currentUser.Profile.isFidinam
                    ? 'btn fidinam-color-secondary white pull-right'
                    : 'btn my-color white pull-right'
                "
                (click)="getRooms(commandes.interpretationData)"
                [disabled]="commandes.interpretationData.missions.address"
              >
                {{ "joindre_salon" | localise }}
              </button>
            </td> -->
          </tr>
        </tbody>
      </table>
      <ngb-pagination
        *ngIf="!!commandesListInterpretations"
        [collectionSize]="commandesListInterpretations.length"
        [page]="pageInter"
        [pageSize]="pageSize"
        (pageChange)="onPageChange($event, 2)"
        aria-label="Default pagination"
      ></ngb-pagination>
    </div>
  </mat-tab>
  <!-- bloc interprétation -->
</mat-tab-group>

<!--
<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item">
    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Home</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Profile</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Contact</a>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">tata</div>
  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">toto</div>
  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">mama</div>
</div> -->
<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
  Launch demo modal
</button> -->

<!-- Modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" *ngIf="">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> -->
