import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as firebase from "firebase";

@Component({
  templateUrl: "./login-modal.component.html",
  styleUrls: ["./login-modal.component.scss"],
})
export class LoginModalComponent implements OnInit {
  public registerForm: FormGroup;
  private TENTATIVE = 3;
  public error: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.createFormLogin();
  }

  private createFormLogin() {
    this.registerForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  public onSubmit() {
    const user = firebase.auth().currentUser;
    const credentials = firebase.auth.EmailAuthProvider.credential(
      this.registerForm.value.email,
      this.registerForm.value.password
    );
    user
      .reauthenticateWithCredential(credentials)
      .then(() => {
        this.activeModal.close(true);
      })
      .catch((error) => {
        this.error = true;
        this.TENTATIVE--;

        if (this.TENTATIVE === 0) this.activeModal.close(false);
      });
  }
}
