var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HieroBDD } from "../../../services/hierobdd.service";
import { connect, createLocalVideoTrack, } from "twilio-video";
import { ProfessionnelService } from "../../../services/professionnel.service";
export class MeetingComponent {
    constructor(route, router, hiero, professionnelService) {
        this.route = route;
        this.router = router;
        this.hiero = hiero;
        this.professionnelService = professionnelService;
        this.room = null;
        this.startRoom = () => __awaiter(this, void 0, void 0, function* () {
            // get the token
            let token = window.localStorage.getItem("token");
            // join room using token
            this.room = yield connect(token, {
                name: this.roomName,
                audio: true,
                video: { width: 640 },
            });
            // render local participant's video and audio
            const localVideoTrack = yield createLocalVideoTrack({ width: 640 });
            document
                .querySelector("#local-media-container")
                .appendChild(localVideoTrack.attach());
            // render remote participant's video and audio
            this.room.participants.forEach((participant) => manageTracksForRemoteParticipant(participant));
            // turn off mic if audioFlag is false
            if (!this.audioFlag) {
                this.audioOff();
            }
            // Wire-up event handlers.
            this.room.on("participantConnected", onParticipantConnected);
            this.room.on("participantDisconnected", onParticipantDisconnected);
            window.onbeforeunload = () => this.room.disconnect();
            // handle Remote Media Mute Events
            this.room.participants.forEach((participant) => {
                manageRemoteMediaMuteEvents(participant);
            });
            // handle Remote Media Unmute Events
            this.room.participants.forEach((participant) => {
                manageRemoteMediaUnmuteEvents(participant);
            });
        });
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            /* const id = this.route.snapshot.paramMap.has("id");
            this.route.paramMap.subscribe(async (paramMap) => {
              if (!paramMap.has("id")) {
                this.router.navigate(["/commandes"]);
              }
              const docId = paramMap.get("id");
            }); */
            // get the settings parameters
            const settings = JSON.parse(localStorage.getItem("settings"));
            this.audioFlag = settings.audio;
            this.videoFlag = settings.video;
            // get the room name
            this.name = (yield this.professionnelService.getProfessionnelData()).businessName;
            this.roomName = this.route.snapshot.paramMap.get("roomName");
            // hide the local media container if the videoFlag is false
            if (!this.videoFlag) {
                localMedia(this.videoFlag);
            }
            remoteNotVideo(false);
            // join room using token
            this.startRoom();
        });
    }
    audioChange() {
        this.audioFlag = !this.audioFlag;
        if (this.audioFlag) {
            this.audioOn();
        }
        else {
            this.audioOff();
        }
        this.settingsUser(this.audioFlag, this.videoFlag);
    }
    videoChange() {
        this.videoFlag = !this.videoFlag;
        this.settingsUser(this.audioFlag, this.videoFlag);
        if (this.videoFlag) {
            this.videoOn();
        }
        else {
            this.videoOff();
        }
    }
    audioOn() {
        this.room.localParticipant.audioTracks.forEach((publication) => {
            publication.track.enable();
        });
    }
    audioOff() {
        this.room.localParticipant.audioTracks.forEach((publication) => {
            publication.track.disable();
        });
    }
    videoOn() {
        this.room.localParticipant.videoTracks.forEach((publication) => {
            publication.track.enable();
            localMedia(this.videoFlag);
        });
    }
    videoOff() {
        this.room.localParticipant.videoTracks.forEach((publication) => {
            publication.track.disable();
            localMedia(this.videoFlag);
        });
    }
    settingsUser(audio, video) {
        localStorage.setItem("settings", JSON.stringify({ audio, video }));
        //window.location.reload();
    }
    leaveSession() {
        handleDisconnectedParticipant(this.room.localParticipant);
        this.room.disconnect();
        this.deleteSettingsUser();
    }
    deleteSettingsUser() {
        localStorage.removeItem("user");
        localStorage.removeItem("settings");
        localStorage.removeItem("token");
        window.location.href = "/app/commandes";
    }
}
function localMedia(videoFlag) {
    const videoContainer = document.querySelector("#local-media");
    if (videoFlag)
        videoContainer.style.display = "block";
    else
        videoContainer.style.display = "none";
}
function remoteNotVideo(bool) {
    const remoteNotVideo = document.querySelector("#remoteNotVideo");
    if (bool)
        remoteNotVideo.style.display = "block";
    else
        remoteNotVideo.style.display = "none";
}
function remoteMediaContainer(bool) {
    const videoContainer = document.querySelector("#remote-media-container");
    if (bool)
        videoContainer.style.display = "block";
    else
        videoContainer.style.display = "none";
}
/**
 * Manages track attachment and subscription for a remote participant.
 *
 * @param participant
 * The remote participant
 */
function manageTracksForRemoteParticipant(participant) {
    // Handle tracks that this participant has already published.
    attachAttachableTracksForRemoteParticipant(participant);
    // Handles tracks that this participant eventually publishes.
    participant.on("trackSubscribed", onTrackSubscribed);
    participant.on("trackUnsubscribed", onTrackUnsubscribed);
}
/**
 * Attaches all attachable published tracks from the remote participant.
 *
 * @param publications
 * The list of possible publications to attach.
 */
function attachAttachableTracksForRemoteParticipant(participant) {
    participant.tracks.forEach((publication) => {
        if (!publication.isSubscribed)
            return;
        if (!trackExistsAndIsAttachable(publication.track))
            return;
        attachTrack(publication.track);
    });
}
/**
 * Triggers when a remote track is unsubscribed from.
 *
 * @param track
 * The remote track
 */
function onTrackUnsubscribed(track) {
    if (trackExistsAndIsAttachable(track))
        track.detach().forEach((element) => element.remove());
}
/**
 * Triggers when a remote track is subscribed to.
 *
 * @param track
 * The remote track
 */
function onTrackSubscribed(track) {
    if (!trackExistsAndIsAttachable(track))
        return;
    else
        attachTrack(track);
}
/**
 * Attaches a remote track.
 *
 * @param track
 * The remote track to attach.
 */
function attachTrack(track) {
    if (track.kind === "video") {
        if (track.isEnabled)
            document
                .querySelector("#remote-media-container")
                .appendChild(track.attach());
        else {
            remoteMediaContainer(false);
            remoteNotVideo(true);
        }
    }
}
/**
 * Guard that a track is attachable.
 *
 * @param track
 * The remote track candidate.
 */
function trackExistsAndIsAttachable(track) {
    return (!!track &&
        (track.attach !== undefined ||
            track.attach !== undefined));
}
/**
 * Triggers when a remote participant connects to the room.
 *
 * @param participant
 * The remote participant
 */
function onParticipantConnected(participant) {
    manageTracksForRemoteParticipant(participant);
}
/**
 * Triggers when a remote participant disconnects from the room.
 *
 * @param participant
 * The remote participant
 */
function onParticipantDisconnected(participant) {
    document.getElementById(participant.sid).remove();
}
/**
 * Triggers when a remote participant mute his audio or turn off his camera.
 *
 * @param participant
 * The remote participant
 */
function manageRemoteMediaMuteEvents(participant) {
    participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
            handleTrackDisabled(publication.track);
        }
        publication.on("subscribed", handleTrackDisabled);
    });
}
/**
 * Triggers when a remote participant unmute his audio or turn on his camera.
 *
 * @param participant
 * The remote participant
 */
function manageRemoteMediaUnmuteEvents(participant) {
    participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
            handleTrackEnabled(publication.track);
        }
        publication.on("subscribed", handleTrackEnabled);
    });
}
/*
function createVideoElt(p) {
  const div = document.createElement("div");
  div.setAttribute("id", p.identity);

  const videoContainer = document.querySelector(
    "#videoContainer"
  ) as HTMLDivElement | null;
  videoContainer.appendChild(div);
}

function handleConectParticipants(p, videoFlag) {
  createVideoElt(p);

  p.tracks.forEach((track) => {
    handleTrackPublication(track, p, videoFlag);
  });

  p.on("trackPublished", handleTrackPublication);
}

function handleTrackPublication(track, p, videoFlag) {
  function displayTrack(track) {
    const pDiv = document.getElementById(p.identity);
    pDiv.append(track.attach());
  }

  if (track.track) {
    displayTrack(track.track);
  }

  track.on("subscribed", displayTrack);
}
*/
function handleDisconnectedParticipant(p) {
    p.removeAllListeners();
    const pDiv = document.getElementById(p.identity);
    if (pDiv) {
        pDiv.remove();
    }
}
function handleTrackDisabled(track) {
    if (track.kind === "video") {
        track.on("disabled", () => {
            remoteMediaContainer(false);
            remoteNotVideo(true);
        });
    }
}
function handleTrackEnabled(track) {
    if (track.kind === "video") {
        track.on("enabled", () => {
            remoteMediaContainer(true);
            remoteNotVideo(false);
        });
    }
}
