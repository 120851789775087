var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { COLLECTION } from "../../../common/src/bdd/utility/collections";
import { TypeProfessionnal } from "../../../common/src/bdd/interfaces/types";
import { HieroBDD } from "./hierobdd.service";
export class ProfessionnelService {
    constructor(hiero) {
        this.hiero = hiero;
    }
    getProfessionnelId() {
        return __awaiter(this, void 0, void 0, function* () {
            const currentUser = this.hiero.Auth.User;
            let proId = yield this.hiero.DB.collection(COLLECTION.PROFESSIONNELS)
                .where("uid", "==", currentUser.Id)
                .get()
                .then((snapshot) => {
                return snapshot.docs.map((doc) => {
                    return doc.id;
                });
            });
            this.professionnelId = proId[0];
            return this.professionnelId;
        });
    }
    getProfessionnelData() {
        return __awaiter(this, void 0, void 0, function* () {
            const currentUser = this.hiero.Auth.User;
            let proData = yield this.hiero.DB.collection(COLLECTION.PROFESSIONNELS)
                .where("uid", "==", currentUser.Id)
                .get()
                .then((snapshot) => {
                return snapshot.docs.map((doc) => {
                    return doc.data();
                });
            });
            this.professionnelData = proData[0];
            return this.professionnelData;
        });
    }
    getTypeProfessionnel() {
        return __awaiter(this, void 0, void 0, function* () {
            const currentUser = this.hiero.Auth.User;
            const user = this.hiero.DB.collection(COLLECTION.USERS).doc(currentUser.Id);
            const snap = yield user.get();
            const client = snap.data();
            if (client.profile.isExpat)
                return TypeProfessionnal.expat;
            else if (client.profile.isSeineMaritime)
                return TypeProfessionnal.seineMaritime;
            else if (client.profile.isFidinam)
                return TypeProfessionnal.fidinam;
            else if (client.profile.isANR)
                return TypeProfessionnal.ANR;
            else
                return TypeProfessionnal.normal;
        });
    }
    updateBudget(price) {
        if (this.professionnelData.budget > 0) {
            this.professionnelData.budget = this.professionnelData.budget - price;
            this.hiero.DB.collection("professionnels")
                .doc(this.professionnelId)
                .update({
                budget: this.professionnelData.budget,
            });
        }
    }
}
