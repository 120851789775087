var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EnumPrestationState } from "../../../common/src/bdd/interfaces/IPrestation";
import { ProfessionnelService } from "./professionnel.service";
import { TypeProfessionnal, PrestationType, } from "../../../common/src/bdd/interfaces/types";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { COLLECTION } from "../../../common/src/bdd/utility/collections";
import { DevisService } from "./devis.service";
import { FactureService } from "./facture.service";
import { HieroBDD } from "./hierobdd.service";
export class InterpretationService {
    constructor(hiero, pro, devisService, factureService) {
        this.hiero = hiero;
        this.pro = pro;
        this.devisService = devisService;
        this.factureService = factureService;
        this.state = EnumPrestationState.WaitingForTranslator;
        this.commandInterpretations = [];
        this.commandInterpretationsSubject = new BehaviorSubject([]);
    }
    dispachCommandInterpretations() {
        this.commandInterpretationsSubject.next(this.commandInterpretations);
    }
    getInterpretations() {
        return __awaiter(this, void 0, void 0, function* () {
            let professionnelId = yield this.pro.getProfessionnelId();
            const request = this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES)
                .where("professionnelId", "==", professionnelId)
                .orderBy("sendTime", "desc");
            return request
                .get()
                .then((snapshot) => {
                this.commandInterpretations = snapshot.docs.map((doc) => {
                    // change the hour format
                    let time = doc.data().missions.extHours;
                    let ts = time.split(":");
                    doc.data().missions.extHours = ts[0] + "h" + ts[1];
                    return {
                        type: PrestationType.INTERPRETATION,
                        id: doc.id,
                        interpretationData: doc.data(),
                    };
                });
                this.dispachCommandInterpretations();
                return this.commandInterpretations;
            })
                .catch();
        });
    }
    getInterpretation(id) {
        return this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES)
            .doc(id)
            .get()
            .then((snapshot) => {
            let data = snapshot.data();
            let time = data.missions.extHours;
            let ts = time.split(":");
            data.missions.extHours = ts[0] + "h" + ts[1];
            return {
                id: snapshot.id,
                interpretationData: data,
            };
        });
    }
    getInterpretationsThisMonth() {
        return this.commandInterpretations.filter((data) => data.interpretationData.missions.date.toDate().getMonth() ===
            new Date().getMonth());
    }
    getInterpretationsLastMonth() {
        let interprationsLastMonth = this.commandInterpretations.filter((data) => {
            if (new Date().getMonth() === 0) {
                return (data.interpretationData.missions.date.toDate().getFullYear() ===
                    new Date().getFullYear() - 1 &&
                    data.interpretationData.missions.date.toDate().getMonth() === 11);
            }
            else
                return (data.interpretationData.missions.date.toDate().getMonth() ===
                    new Date().getMonth() - 1);
        });
        return interprationsLastMonth.length;
    }
    addInterpretation(formValue, selectedTranslator, isDevis) {
        return __awaiter(this, void 0, void 0, function* () {
            let professionnelId = yield this.pro.getProfessionnelId();
            let price = this.priceFormat(selectedTranslator.price);
            let bonDeCommande = formValue.bon;
            formValue = this.formValueFormat(formValue, professionnelId, selectedTranslator.translatorId);
            let event = formValue.event;
            delete formValue.event;
            if (isDevis) {
                this.devisService.sendDevisInterpretation(event, formValue, selectedTranslator, price, bonDeCommande);
            }
            else {
                this.sendInterpretation(event, formValue, selectedTranslator.translatorData.businessName, selectedTranslator.translatorId, price);
            }
        });
    }
    /**
     *
     * @param event event name
     * @param formValue form values
     * @param translator selected translator
     * @param price
     * @returns the new interpretation ID
     */
    sendInterpretation(event, formValue, translatorName, translatorId, price, bonDeCommande = null, devisId = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                let professionnelId = yield this.pro.getProfessionnelId();
                let professionnelData = yield this.pro.getProfessionnelData();
                let professionnelUid = this.hiero.Auth.User.Id;
                let interpretation = {
                    sendTime: new Date(),
                    missions: formValue,
                    state: this.state,
                    translatorName: translatorName,
                    translatorId: translatorId,
                    professionnelName: professionnelData.businessName,
                    professionnelId: professionnelId,
                    professionnelUid: professionnelUid,
                    event: event,
                    price: price,
                };
                if (devisId)
                    interpretation["devisId"] = devisId;
                interpretation = yield this.typeProfessionnel(interpretation);
                this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES)
                    .add(interpretation)
                    .then((value) => {
                    this.pro.updateBudget(price.priceTTC);
                    this.factureService.addFacture(value.id, event, formValue, price, translatorId, translatorName, bonDeCommande);
                    resolve(value.id);
                })
                    .catch(reject);
            }));
        });
    }
    formValueFormat(formValue, professionnelId, translatorId) {
        if (!formValue.address)
            formValue["room"] =
                formValue.event + "_" + professionnelId + "_" + translatorId;
        formValue["date"] = this.dateFormat(formValue);
        delete formValue.time;
        delete formValue.bon;
        return formValue;
    }
    dateFormat(formValue) {
        let date = formValue.date;
        let time = formValue.time;
        const [year, month, day] = date.split("-");
        const [hour, minute] = time.split(":");
        return new Date(year, month - 1, day, hour, minute);
    }
    typeProfessionnel(interpretation) {
        return __awaiter(this, void 0, void 0, function* () {
            let typeProfessionnel = yield this.pro.getTypeProfessionnel();
            switch (typeProfessionnel) {
                case TypeProfessionnal.expat:
                    interpretation["isInterpretationExpat"] = true;
                    break;
                case TypeProfessionnal.seineMaritime:
                    interpretation["isInterpretationSeineMaritime"] = true;
                    break;
                case TypeProfessionnal.fidinam:
                    interpretation["isInterpretationFidinam"] = true;
                    break;
                case TypeProfessionnal.normal:
                    break;
            }
            return interpretation;
        });
    }
    priceFormat(selectedTranslatorPrice) {
        console.log(selectedTranslatorPrice);
        let price = {
            priceTVA: selectedTranslatorPrice.priceTVA,
            priceTVAVal: selectedTranslatorPrice.priceTVAVal,
            priceHT: selectedTranslatorPrice.priceHT,
            priceTTC: selectedTranslatorPrice.priceTTC,
        };
        return price;
    }
    joinMeeting() { }
}
