<div class="modal-body">
  <div class="container">
    <div class="mb-3 text-center">
      <h3>{{ "check" | localise }}</h3>
    </div>
    <div class="mt-5">
      <p>
        {{ "info_check" | localise }}
      </p>
      <div class="border m-3 p-2">
        <dl>
          <dd>
            <strong>{{ "account_adresse" | localise }} : </strong>
            {{ "support_addr1" | localise }}
            {{ "support_addr2" | localise }}
          </dd>
          <dd>
            <strong>{{ "check_order" | localise }} : </strong
            >{{ "support_company" | localise }}
          </dd>
          <dd>
            <strong>{{ "check_courrier_service" | localise }} : </strong>
            {{ "check_comptability" | localise }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close()"
  >
    {{ "close_modal" | localise }}
  </button>
</div>
