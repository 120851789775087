import { Injectable } from "@angular/core";
import { EnumPrestationState } from "../../../common/src/bdd/interfaces/IPrestation";
import { ProfessionnelService } from "./professionnel.service";
import {
  TypeProfessionnal,
  PrestationType,
} from "../../../common/src/bdd/interfaces/types";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { COLLECTION } from "../../../common/src/bdd/utility/collections";
import { DevisService } from "./devis.service";
import { FactureService } from "./facture.service";
import { HieroBDD } from "./hierobdd.service";
import {
  IInterpretation,
  IMission,
  InterprationDocument,
  IPrice,
} from "../../../common/src/bdd/interfaces/IInterpretation";

@Injectable()
export class InterpretationService {
  private state = EnumPrestationState.WaitingForTranslator;
  private commandInterpretations: InterprationDocument[] = [];
  public commandInterpretationsSubject: BehaviorSubject<
    InterprationDocument[]
  > = new BehaviorSubject<InterprationDocument[]>(<InterprationDocument[]>[]);

  constructor(
    private hiero: HieroBDD,
    private pro: ProfessionnelService,
    private devisService: DevisService,
    private factureService: FactureService
  ) {}

  private dispachCommandInterpretations() {
    this.commandInterpretationsSubject.next(this.commandInterpretations);
  }

  public async getInterpretations(): Promise<InterprationDocument[]> {
    let professionnelId = await this.pro.getProfessionnelId();

    const request = this.hiero.DB.collection(
      COLLECTION.INTERPRETATIONS_COMMANDES
    )
      .where("professionnelId", "==", professionnelId)
      .orderBy("sendTime", "desc");
    return request
      .get()
      .then((snapshot) => {
        this.commandInterpretations = snapshot.docs.map((doc) => {
          // change the hour format
          let time = doc.data().missions.extHours;
          let ts = time.split(":");
          doc.data().missions.extHours = ts[0] + "h" + ts[1];

          return {
            type: PrestationType.INTERPRETATION,
            id: doc.id,
            interpretationData: doc.data(),
          } as InterprationDocument;
        });
        this.dispachCommandInterpretations();
        return this.commandInterpretations;
      })
      .catch();
  }

  public getInterpretation(id: string): Promise<InterprationDocument> {
    return this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES)
      .doc(id)
      .get()
      .then((snapshot) => {
        let data = snapshot.data();
        let time = data.missions.extHours;
        let ts = time.split(":");
        data.missions.extHours = ts[0] + "h" + ts[1];

        return {
          id: snapshot.id,
          interpretationData: data as IInterpretation,
        };
      });
  }

  public getInterpretationsThisMonth() {
    return this.commandInterpretations.filter(
      (data) =>
        data.interpretationData.missions.date.toDate().getMonth() ===
        new Date().getMonth()
    );
  }

  public getInterpretationsLastMonth() {
    let interprationsLastMonth = this.commandInterpretations.filter((data) => {
      if (new Date().getMonth() === 0) {
        return (
          data.interpretationData.missions.date.toDate().getFullYear() ===
            new Date().getFullYear() - 1 &&
          data.interpretationData.missions.date.toDate().getMonth() === 11
        );
      } else
        return (
          data.interpretationData.missions.date.toDate().getMonth() ===
          new Date().getMonth() - 1
        );
    });

    return interprationsLastMonth.length;
  }

  public async addInterpretation(
    formValue,
    selectedTranslator,
    isDevis: boolean
  ) {
    let professionnelId = await this.pro.getProfessionnelId();

    let price: IPrice = this.priceFormat(selectedTranslator.price);

    let bonDeCommande = formValue.bon;

    formValue = this.formValueFormat(
      formValue,
      professionnelId,
      selectedTranslator.translatorId
    );

    let event = formValue.event;
    delete formValue.event;

    if (isDevis) {
      this.devisService.sendDevisInterpretation(
        event,
        formValue,
        selectedTranslator,
        price,
        bonDeCommande
      );
    } else {
      this.sendInterpretation(
        event,
        formValue,
        selectedTranslator.translatorData.businessName,
        selectedTranslator.translatorId,
        price
      );
    }
  }

  /**
   *
   * @param event event name
   * @param formValue form values
   * @param translator selected translator
   * @param price
   * @returns the new interpretation ID
   */
  public async sendInterpretation(
    event,
    formValue,
    translatorName,
    translatorId,
    price,
    bonDeCommande = null,
    devisId = null
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let professionnelId = await this.pro.getProfessionnelId();
      let professionnelData = await this.pro.getProfessionnelData();
      let professionnelUid = this.hiero.Auth.User.Id;

      let interpretation: IInterpretation = {
        sendTime: new Date(),
        missions: formValue,
        state: this.state,
        translatorName: translatorName,
        translatorId: translatorId,
        professionnelName: professionnelData.businessName,
        professionnelId: professionnelId,
        professionnelUid: professionnelUid,
        event: event,
        price: price,
      };

      if (devisId) interpretation["devisId"] = devisId;

      interpretation = await this.typeProfessionnel(interpretation);

      this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES)
        .add(interpretation)
        .then((value: any) => {
          this.pro.updateBudget(price.priceTTC);
          this.factureService.addFacture(
            value.id,
            event,
            formValue,
            price,
            translatorId,
            translatorName,
            bonDeCommande
          );
          resolve(value.id);
        })
        .catch(reject);
    });
  }

  private formValueFormat(formValue, professionnelId, translatorId): IMission {
    if (!formValue.address)
      formValue["room"] =
        formValue.event + "_" + professionnelId + "_" + translatorId;

    formValue["date"] = this.dateFormat(formValue);
    delete formValue.time;
    delete formValue.bon;

    return formValue;
  }

  private dateFormat(formValue): Date {
    let date = formValue.date;
    let time = formValue.time;

    const [year, month, day] = date.split("-");
    const [hour, minute] = time.split(":");

    return new Date(year, month - 1, day, hour, minute);
  }

  private async typeProfessionnel(interpretation) {
    let typeProfessionnel = await this.pro.getTypeProfessionnel();

    switch (typeProfessionnel) {
      case TypeProfessionnal.expat:
        interpretation["isInterpretationExpat"] = true;
        break;
      case TypeProfessionnal.seineMaritime:
        interpretation["isInterpretationSeineMaritime"] = true;
        break;
      case TypeProfessionnal.fidinam:
        interpretation["isInterpretationFidinam"] = true;
        break;
      case TypeProfessionnal.normal:
        break;
    }

    return interpretation;
  }

  private priceFormat(selectedTranslatorPrice): IPrice {
    console.log(selectedTranslatorPrice);

    let price: IPrice = {
      priceTVA: selectedTranslatorPrice.priceTVA,
      priceTVAVal: selectedTranslatorPrice.priceTVAVal,
      priceHT: selectedTranslatorPrice.priceHT,
      priceTTC: selectedTranslatorPrice.priceTTC,
    };

    return price;
  }

  public joinMeeting() {}
}
