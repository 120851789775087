import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs/Subscription";
import {
  EmptyUserProfile,
  IUserProfile,
} from "../../../../../common/src/bdd/interfaces/IUserProfile";
import { User } from "../../../../../common/src/bdd/user/User";
import { HieroBDD } from "../../../services/hierobdd.service";
import { GeoCodeResult, GoogleGeo } from "../../../services/google.services";
import * as firebase from "firebase";
import { ProfilePictureService } from "../../../services/profile-picture.services";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { LoginModalComponent } from "./login-modal/login-modal.component";
import { CheckModalComponent } from "./check-modal/check-modal.component";
import { RefundModalComponent } from "./refund-modal/refund-modal.component";
import { PaiementModalComponent } from "./paiement-modal/paiment-modal.component";
import { ProfessionnelService } from "../../../services/professionnel.service";
import { InterpretationService } from "../../../services/interpretation.service";
import { PrestationType } from "../../../../../common/src/bdd/interfaces/types";
import { TranslationsService } from "../../../services/translations.service";

@Component({
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  private userWatch: User;
  public profile: IUserProfile;
  public pro: firebase.User;
  public photo: any;
  public profileForm: FormGroup;
  public passwordForm: FormGroup;
  public updated: boolean = false;
  public updatedPwd: boolean = false;
  public isSent: boolean = false;
  public errorImg: boolean = false;
  public error: boolean = false;
  public errorLogin: boolean = false;
  public errorPwd: boolean = false;
  public errorSent: boolean = false;

  private userSubs: Subscription;
  private profSubs: Subscription;
  private currentUser: any;

  public busy = true;

  public proData: any;
  private proId: string;
  public profileCommande: any[] = [];

  page = 1;
  pageAsser = 1;
  pageInter = 1;
  pageSize = 5;

  constructor(
    private hiero: HieroBDD,
    private fb: FormBuilder,
    private pfpService: ProfilePictureService,
    private geo: GoogleGeo,
    private router: Router,
    private modalService: NgbModal,
    private professionnelService: ProfessionnelService,
    private translationsService: TranslationsService,
    private interpretationService: InterpretationService
  ) {}

  ngOnInit() {
    this.getUserData();
    // Add listeners for the data we want
    // These listeners will either fire immediately,
    // or they will fire when the data becomes available
    this.userSubs = this.hiero.Auth.WatchUser({
      next: (user: User) => {
        if (user) {
          // We have a user, save a reference
          this.userWatch = user;

          if (this.userWatch) {
            // The user is not null, watch for the user profile
            this.profSubs = this.userWatch.WatchProfile({
              next: (profile: IUserProfile) => {
                if (profile) {
                  this.profile = profile;
                  this.createForm(profile);
                }
                this.busy = false;
              },
            });
          }
        }
      },
    });
    this.createFormPwd();
  }

  ngOnDestroy() {
    this.profSubs.unsubscribe();
    this.userSubs.unsubscribe();
  }

  async getUserData() {
    this.pro = firebase.auth().currentUser;
    this.photo = this.pro.photoURL;

    this.proId = await this.professionnelService.getProfessionnelId();
    this.proData = await this.professionnelService.getProfessionnelData();
    this.currentUser = this.hiero.Auth.User;

    this.getUserCommande().then((array) => (this.profileCommande = array));
    this.geo
      .geocode(this.proData.address.formatted)
      .then((x) => this.profileForm.get("address").setValue(x[0]));
    this.profileForm.get("address2").setValue(this.proData.address.extra);
    this.profileForm.get("poste").setValue(this.proData.poste);
    this.profileForm.get("service").setValue(this.proData.service);
  }

  createForm(profile: IUserProfile) {
    this.profileForm = this.fb.group({
      familyName: [profile.familyName, Validators.required],
      givenName: [profile.givenName, Validators.required],
      poste: ["", Validators.required],
      service: ["", Validators.required],
      address: ["", Validators.required],
      address2: [""],
      email: [this.userWatch.Email, [Validators.required, Validators.email]],
      tel: [profile.telephone, Validators.required],
    });
  }

  private createFormPwd() {
    this.passwordForm = this.fb.group(
      {
        password: ["", Validators.required],
        newPassword: ["", [Validators.required, Validators.minLength(8)]],
        newPassword2: ["", Validators.required],
      },
      {
        validator: this.mustMatch("newPassword", "newPassword2"),
      }
    );
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((term) => (term.length < 2 ? [] : this.geo.geocode(term)))
    );

  formatter = (loc: GeoCodeResult) =>
    loc && loc.address ? loc.address.formatted : "";

  async onFileSelected(event) {
    this.errorImg = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      // store the image in the firebase storage at images/profile
      let storage: firebase.storage.Storage = this.hiero.Storage;
      const fileRef = storage.ref("images/profile/").child(this.pro.uid);
      const result = await fileRef.put(file);

      // get the image stored and update the profile picture
      await storage
        .ref(result.ref.fullPath)
        .getDownloadURL()
        .then((photo) => {
          this.pro
            .updateProfile({
              photoURL: photo,
            })
            .then(() => {
              this.photo = this.pro.photoURL;
              this.pfpService.pfp$.next(this.photo);
              //window.location.reload();
            })
            .catch((error) => (this.errorImg = true));
        });
    }
  }

  public submit() {
    this.updated = false;
    this.errorLogin = false;
    this.error = false;
    let formValue = this.profileForm.value;

    formValue.address.address.extra = formValue.address2;

    if (formValue.email != this.pro.email) {
      const modal = this.modalService.open(LoginModalComponent);
      modal.result.then((x) => {
        if (x === true) {
          this.pro
            .updateEmail(formValue.email)
            .then(() => {
              this.updateProfileInfo(formValue);
              this.pro.sendEmailVerification();
              this.router.navigate(["compte", "emailVerify", "2"]);
            })
            .catch((error) => {
              // TO DO : what to do if update email failed?
              console.log("update email failed");
            });
        }
        // TO DO : what to do if login failed?
        if (x === false) {
          this.errorLogin = true;
        }
      });
    } else {
      this.updateProfileInfo(formValue);
    }
  }

  public submitPwd() {
    this.errorPwd = false;
    this.updatedPwd = false;
    const credentials = firebase.auth.EmailAuthProvider.credential(
      this.pro.email,
      this.passwordForm.value.password
    );
    this.pro
      .reauthenticateWithCredential(credentials)
      .then(() => {
        this.pro
          .updatePassword(this.passwordForm.value.newPassword)
          .then(() => {
            this.updatedPwd = true;
          })
          .catch((error) => {
            this.errorPwd = true;
          });
      })
      .catch((error) => (this.errorPwd = true));
  }

  private updateProfileInfo(formValue) {
    this.currentUser
      .UpdateProfile({
        familyName: formValue.familyName,
        givenName: formValue.givenName,
        telephone: formValue.tel,
        email: formValue.email,
      })
      .then(() => {
        this.hiero.DB.collection("professionnels").doc(this.proId).update({
          address: formValue.address.address,
          coords: formValue.address.coords,
          poste: formValue.poste,
          service: formValue.service,
        });

        this.updated = true;
        this.profile.familyName = formValue.familyName;
        this.profile.givenName = formValue.givenName;
        this.proData.poste = formValue.poste;
        this.proData.service = formValue.service;
      })
      .catch((error) => {
        console.log(error);

        this.error = true;
      });
  }

  public virement() {
    this.modalService.open(PaiementModalComponent);
  }

  public cheque() {
    this.modalService.open(CheckModalComponent);
  }

  public refund() {
    this.modalService.open(RefundModalComponent);
  }

  async getUserCommande() {
    let commandes = [];
    commandes = await this.translationsService.getTranslations();

    await this.interpretationService.getInterpretations().then((snap) => {
      snap.map((data) => commandes.push(data));
    });

    // order list by date
    commandes.sort((a, b) => {
      if (a.type === PrestationType.INTERPRETATION) {
        if (b.type === PrestationType.INTERPRETATION)
          return (
            b.interpretationData.missions.date.toDate() -
            a.interpretationData.missions.date.toDate()
          );
        else
          return (
            b.translationData.createdAt.toDate() -
            a.interpretationData.missions.date.toDate()
          );
      } else {
        if (b.type === PrestationType.INTERPRETATION)
          return (
            b.interpretationData.missions.date.toDate() -
            a.translationData.createdAt.toDate()
          );
      }
    });

    return commandes;
  }

  onPageChange(pageNumber: number, switchnmbr: number) {
    switch (switchnmbr) {
      case 0:
        this.pageAsser = pageNumber;
        // console.log('pageCOm n:', this.pageCom)

        break;
      case 1:
        this.page = pageNumber;
        // console.log('pagePre n:', this.pagePre)

        break;
      case 2:
        this.pageInter = pageNumber;
        // console.log('pageUser n:', this.pageUser)
        break;
      /*case 3:
        this.pageTrad = pageNumber
        //console.log('pageUser n:', this.pageTrad)
        break; */
      default:
        break;
    }
  }
}
