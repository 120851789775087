<div *ngFor="let ticket of loadTicket">
  <p class="entete">Mes tickets / Ticket n° {{ ticket.numero }}</p>
  <div class="d-flex">
    <div class="col-6">
      <form class="mt-3" [formGroup]="msgForm">
        <p> Objet: {{ ticket.objet }}</p>
        <p class="text-left message" style="color: #1b1f24" [innerHTML]="msgForm.value.message"></p>
        <p class="msg d-inline-block">
          <label>
            <input style="width: 520px;" type="text" name="message" formControlName="message" placeholder="Tapez votre message ici..." class="message">
           <!--  <span style="color: #1b1f24;">{{ filename }}</span> -->
          </label>
          <label for="file-upload" class="custom-file-upload">
            <i class="fa fa-paperclip"></i>
            <input id="file-upload" type="file"  (change)="onUploadFile($event)" name="file"/>
          </label>
          <button style="border: none;" [disabled]="msgForm.invalid" (submit)="onSendMsg()">
            <i class="fa fa-paper-plane"></i>
          </button>
        </p>
      </form>
    </div>
    <div class="col-6 mt-3">
      <div class="col-3">
        <div class="card" style="width: 40rem;">
          <div class="card-body">
            <h5 class="card-title">{{ 'agency_support' | localise}}</h5>
            <div class="text-right">
              <h6 class="card-subtitle mb-2 txt">{{ 'agency_support_service_client' | localise}}</h6>
              <p class="card-text"><strong> {{ 'support_tel' | localise }}</strong></p>
              <p>{{ 'agency_support_ouverture' | localise}}</p>
            </div>
            <p>{{ 'agency_support_questions' | localise }} {{ 'support_email' | localise }}</p>
          </div>
        </div>
      </div>

      <div class="col-3 mt-3">
        <div class="card" style="width: 40rem;">
          <div class="card-body">
            <h5 class="card-title">{{ 'agency_support_guide' | localise}}</h5>
            <!-- <h6 class="card-subtitle mb-2 ">Numéro de téléphone- Services Clients</h6> -->
            <div class="text-right text-justify">
              <p class="card-text text-justify">{{ 'agency_support_guide_p' | localise}}</p>
              <p> <a href="#">{{ 'agency_support_download_guide' | localise}}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
