import { Component, OnInit } from "@angular/core";
import { HieroBDD } from "../../../services/hierobdd.service";

@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.scss"],
})
export class QuestionsComponent implements OnInit {
  constructor(public hiero: HieroBDD) {}

  ngOnInit() {}
}
