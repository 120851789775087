import { Component, OnInit } from "@angular/core";
import * as firebase from "firebase";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HieroBDD } from "../../../services/hierobdd.service";
import {
  connect,
  createLocalVideoTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";
import { Nullable } from "../../../../../common/src/bdd/interfaces/types";
import { ProfessionnelService } from "../../../services/professionnel.service";

@Component({
  selector: "app-meeting",
  templateUrl: "./meeting.component.html",
  styleUrls: ["./meeting.component.scss"],
})
export class MeetingComponent implements OnInit {
  public name: string;
  public roomName: string;
  public audioFlag: boolean;
  public videoFlag: boolean;
  private room: any = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public hiero: HieroBDD,
    private professionnelService: ProfessionnelService
  ) {}

  async ngOnInit() {
    /* const id = this.route.snapshot.paramMap.has("id");
    this.route.paramMap.subscribe(async (paramMap) => {
      if (!paramMap.has("id")) {
        this.router.navigate(["/commandes"]);
      }
      const docId = paramMap.get("id");
    }); */

    // get the settings parameters
    const settings = JSON.parse(localStorage.getItem("settings"));

    this.audioFlag = settings.audio;
    this.videoFlag = settings.video;

    // get the room name
    this.name = (
      await this.professionnelService.getProfessionnelData()
    ).businessName;
    this.roomName = this.route.snapshot.paramMap.get("roomName");

    // hide the local media container if the videoFlag is false
    if (!this.videoFlag) {
      localMedia(this.videoFlag);
    }

    remoteNotVideo(false);

    // join room using token
    this.startRoom();
  }

  private startRoom = async () => {
    // get the token
    let token = window.localStorage.getItem("token");

    // join room using token
    this.room = await connect(token, {
      name: this.roomName,
      audio: true,
      video: { width: 640 },
    });

    // render local participant's video and audio
    const localVideoTrack = await createLocalVideoTrack({ width: 640 });
    document
      .querySelector("#local-media-container")
      .appendChild(localVideoTrack.attach());

    // render remote participant's video and audio
    this.room.participants.forEach((participant) =>
      manageTracksForRemoteParticipant(participant)
    );

    // turn off mic if audioFlag is false
    if (!this.audioFlag) {
      this.audioOff();
    }

    // Wire-up event handlers.
    this.room.on("participantConnected", onParticipantConnected);
    this.room.on("participantDisconnected", onParticipantDisconnected);

    window.onbeforeunload = () => this.room.disconnect();

    // handle Remote Media Mute Events
    this.room.participants.forEach((participant) => {
      manageRemoteMediaMuteEvents(participant);
    });

    // handle Remote Media Unmute Events
    this.room.participants.forEach((participant) => {
      manageRemoteMediaUnmuteEvents(participant);
    });
  };

  public audioChange() {
    this.audioFlag = !this.audioFlag;

    if (this.audioFlag) {
      this.audioOn();
    } else {
      this.audioOff();
    }
    this.settingsUser(this.audioFlag, this.videoFlag);
  }

  public videoChange() {
    this.videoFlag = !this.videoFlag;
    this.settingsUser(this.audioFlag, this.videoFlag);

    if (this.videoFlag) {
      this.videoOn();
    } else {
      this.videoOff();
    }
  }

  private audioOn() {
    this.room.localParticipant.audioTracks.forEach((publication) => {
      publication.track.enable();
    });
  }

  private audioOff() {
    this.room.localParticipant.audioTracks.forEach((publication) => {
      publication.track.disable();
    });
  }

  private videoOn() {
    this.room.localParticipant.videoTracks.forEach((publication) => {
      publication.track.enable();
      localMedia(this.videoFlag);
    });
  }

  private videoOff() {
    this.room.localParticipant.videoTracks.forEach((publication) => {
      publication.track.disable();
      localMedia(this.videoFlag);
    });
  }

  private settingsUser(audio: boolean, video: boolean) {
    localStorage.setItem("settings", JSON.stringify({ audio, video }));
    //window.location.reload();
  }

  public leaveSession() {
    handleDisconnectedParticipant(this.room.localParticipant);
    this.room.disconnect();
    this.deleteSettingsUser();
  }

  private deleteSettingsUser() {
    localStorage.removeItem("user");
    localStorage.removeItem("settings");
    localStorage.removeItem("token");
    window.location.href = "/app/commandes";
  }
}

function localMedia(videoFlag: boolean) {
  const videoContainer = document.querySelector(
    "#local-media"
  ) as HTMLDivElement | null;
  if (videoFlag) videoContainer.style.display = "block";
  else videoContainer.style.display = "none";
}

function remoteNotVideo(bool: boolean) {
  const remoteNotVideo = document.querySelector(
    "#remoteNotVideo"
  ) as HTMLDivElement | null;
  if (bool) remoteNotVideo.style.display = "block";
  else remoteNotVideo.style.display = "none";
}

function remoteMediaContainer(bool: boolean) {
  const videoContainer = document.querySelector(
    "#remote-media-container"
  ) as HTMLDivElement | null;
  if (bool) videoContainer.style.display = "block";
  else videoContainer.style.display = "none";
}

/**
 * Manages track attachment and subscription for a remote participant.
 *
 * @param participant
 * The remote participant
 */
function manageTracksForRemoteParticipant(participant: RemoteParticipant) {
  // Handle tracks that this participant has already published.
  attachAttachableTracksForRemoteParticipant(participant);

  // Handles tracks that this participant eventually publishes.
  participant.on("trackSubscribed", onTrackSubscribed);
  participant.on("trackUnsubscribed", onTrackUnsubscribed);
}

/**
 * Attaches all attachable published tracks from the remote participant.
 *
 * @param publications
 * The list of possible publications to attach.
 */
function attachAttachableTracksForRemoteParticipant(
  participant: RemoteParticipant
) {
  participant.tracks.forEach((publication) => {
    if (!publication.isSubscribed) return;

    if (!trackExistsAndIsAttachable(publication.track)) return;

    attachTrack(publication.track);
  });
}

/**
 * Triggers when a remote track is unsubscribed from.
 *
 * @param track
 * The remote track
 */
function onTrackUnsubscribed(track: RemoteTrack) {
  if (trackExistsAndIsAttachable(track))
    track.detach().forEach((element) => element.remove());
}

/**
 * Triggers when a remote track is subscribed to.
 *
 * @param track
 * The remote track
 */
function onTrackSubscribed(track: RemoteTrack) {
  if (!trackExistsAndIsAttachable(track)) return;
  else attachTrack(track);
}

/**
 * Attaches a remote track.
 *
 * @param track
 * The remote track to attach.
 */
function attachTrack(track: RemoteAudioTrack | RemoteVideoTrack) {
  if (track.kind === "video") {
    if (track.isEnabled)
      document
        .querySelector("#remote-media-container")
        .appendChild(track.attach());
    else {
      remoteMediaContainer(false);
      remoteNotVideo(true);
    }
  }
}

/**
 * Guard that a track is attachable.
 *
 * @param track
 * The remote track candidate.
 */
function trackExistsAndIsAttachable(
  track?: Nullable<RemoteTrack>
): track is RemoteAudioTrack | RemoteVideoTrack {
  return (
    !!track &&
    ((track as RemoteAudioTrack).attach !== undefined ||
      (track as RemoteVideoTrack).attach !== undefined)
  );
}

/**
 * Triggers when a remote participant connects to the room.
 *
 * @param participant
 * The remote participant
 */
function onParticipantConnected(participant: RemoteParticipant) {
  manageTracksForRemoteParticipant(participant);
}

/**
 * Triggers when a remote participant disconnects from the room.
 *
 * @param participant
 * The remote participant
 */
function onParticipantDisconnected(participant: RemoteParticipant) {
  document.getElementById(participant.sid)!.remove();
}

/**
 * Triggers when a remote participant mute his audio or turn off his camera.
 *
 * @param participant
 * The remote participant
 */
function manageRemoteMediaMuteEvents(participant: RemoteParticipant) {
  participant.tracks.forEach((publication) => {
    if (publication.isSubscribed) {
      handleTrackDisabled(publication.track);
    }
    publication.on("subscribed", handleTrackDisabled);
  });
}

/**
 * Triggers when a remote participant unmute his audio or turn on his camera.
 *
 * @param participant
 * The remote participant
 */
function manageRemoteMediaUnmuteEvents(participant: RemoteParticipant) {
  participant.tracks.forEach((publication) => {
    if (publication.isSubscribed) {
      handleTrackEnabled(publication.track);
    }
    publication.on("subscribed", handleTrackEnabled);
  });
}

/* 
function createVideoElt(p) {
  const div = document.createElement("div");
  div.setAttribute("id", p.identity);

  const videoContainer = document.querySelector(
    "#videoContainer"
  ) as HTMLDivElement | null;
  videoContainer.appendChild(div);
}

function handleConectParticipants(p, videoFlag) {
  createVideoElt(p);

  p.tracks.forEach((track) => {
    handleTrackPublication(track, p, videoFlag);
  });

  p.on("trackPublished", handleTrackPublication);
}

function handleTrackPublication(track, p, videoFlag) {
  function displayTrack(track) {
    const pDiv = document.getElementById(p.identity);
    pDiv.append(track.attach());
  }

  if (track.track) {
    displayTrack(track.track);
  }

  track.on("subscribed", displayTrack);
}
*/

function handleDisconnectedParticipant(p) {
  p.removeAllListeners();
  const pDiv = document.getElementById(p.identity);
  if (pDiv) {
    pDiv.remove();
  }
}

function handleTrackDisabled(track) {
  if (track.kind === "video") {
    track.on("disabled", () => {
      remoteMediaContainer(false);

      remoteNotVideo(true);
    });
  }
}

function handleTrackEnabled(track) {
  if (track.kind === "video") {
    track.on("enabled", () => {
      remoteMediaContainer(true);
      remoteNotVideo(false);
    });
  }
}
