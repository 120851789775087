import { Injectable } from "@angular/core";
import { ProfessionnelService } from "./professionnel.service";
import {
  TypeProfessionnal,
  PrestationType,
} from "../../../common/src/bdd/interfaces/types";
import * as firebase from "firebase";
import { COLLECTION } from "../../../common/src/bdd/utility/collections";
import { HieroBDD } from "./hierobdd.service";
import { BehaviorSubject } from "rxjs/BehaviorSubject";

@Injectable()
export class FactureService {
  private factures: any[] = [];
  public facturesSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    <any[]>[]
  );

  constructor(private pro: ProfessionnelService, private hiero: HieroBDD) {}

  private dispachFactures() {
    this.facturesSubject.next(this.factures);
  }

  public async getFactures() {
    let professionnelId = await this.pro.getProfessionnelId();
    const factures = this.hiero.DB.collection(COLLECTION.FACTURE)
      .where("professionnelId", "==", professionnelId)
      .orderBy("date", "desc");
    return factures.get().then((snapshot) => {
      this.factures = snapshot.docs.map((data) => {
        return {
          id: data.id,
          data: data.data(),
        };
      });
      this.dispachFactures();
      return this.factures;
    });
  }

  public getFacture(id: string) {
    return this.hiero.DB.collection(COLLECTION.FACTURE)
      .doc(id)
      .get()
      .then((snapshot) => {
        return {
          id: snapshot.id,
          data: snapshot.data(),
        };
      });
  }

  public async addFacture(
    interpretationId,
    event,
    formValue,
    price,
    translatorId,
    translatorName,
    bonDeCommande
  ) {
    let professionnelId = await this.pro.getProfessionnelId();
    let professionnelData = await this.pro.getProfessionnelData();

    let facture = {
      numero: Math.floor(Math.random() * 1000000 + 1),
      event: event,
      date: new Date(),
      srcLang: formValue.srcLang,
      destLang: formValue.destLang,
      priceTTC: price.priceTTC,
      priceHT: price.priceHT,
      priceTVA: price.priceTVA,
      priceTVAVal: price.priceTVAVal,
      traducteur: translatorId,
      traducteurName: translatorName,
      prestation: interpretationId,
      typePrestation: PrestationType.INTERPRETATION,
      professionnelId: professionnelId,
    };

    if (bonDeCommande) facture["purchaseOrder"] = bonDeCommande;

    facture = await this.typeProfessionnel(facture);

    await firebase
      .firestore()
      .collection(COLLECTION.FACTURE)
      .add(facture)
      .then(() => {
        firebase
          .firestore()
          .collection("mail")
          .add({
            bcc: [
              "B.balsomi@hiero-solution.com",
              "a.luis@hiero-solution.com",
              "w.moingt@hiero-solution.com",
              "n.ziani@hiero-solution.com",
            ],
            message: {
              subject: "Nouvelle interpretation",
              text: "Nouvelle interpretation",
              html: `  <div style=" font-family: "Helvetica Neue", sans-serif; font-size: 20px;"> <div style="font-weight: bold"> <img src="https://firebasestorage.googleapis.com/v0/b/hiero-prod.appspot.com/o/Logos%2Fbird.png?alt=media&token=fa72f85d-1c09-48c1-ab47-ee7cde0eb80b" alt="" width="50" /> Hierø Solution </div> <hr /> <h3 style="text-align: center">Nouvelle interpretation</h3> <p>Bonjour,</p><p style="line-height: 2; text-align:justify"> Nous avons une nouvelle commande d'interpretation dans notre système du client ${professionnelData.businessName}. Retrouve les détails de cette prestation dans ton espace admin. <p>Bien cordialement,</p> <p>L\'équipe Hierø</p> <hr /><div><h3 style= "text-align:center">Description</h3><p > ${formValue.context}</p></div> </div> `,
            },
          })
          .then(() => console.log("Queued email for delivery!"));
      });
  }

  private async typeProfessionnel(facture) {
    let typeProfessionnel = await this.pro.getTypeProfessionnel();

    switch (typeProfessionnel) {
      case TypeProfessionnal.expat:
        facture["isFactureExpat"] = true;
        break;
      case TypeProfessionnal.seineMaritime:
        facture["isFactureSeineMaritime"] = true;
        break;
      case TypeProfessionnal.fidinam:
        facture["isFactureFidinam"] = true;
        break;
      case TypeProfessionnal.normal:
        break;
    }

    return facture;
  }
}
