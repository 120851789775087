<!-- begin register -->
<div class="register register-with-news-feed" style="min-height: 100vh">
  <!-- begin news-feed -->
  <div class="news-feed">
    <div
      class="news-image"
      style="background-image: url(../assets/img/login-bg/login-bg.png)"
    ></div>
    <div class="news-caption">
      <h4 class="caption-title">
        {{ "signup_caption_title_pro" | localise }}
      </h4>
      <p>{{ "signup_caption_subtitle" | localise }}</p>
    </div>
  </div>
  <!-- end news-feed -->
  <!-- begin right-content -->
  <div class="right-content">
    <div class="d-flex p-20 flex-row-reverse">
      <app-language-dropdown></app-language-dropdown>
    </div>

    <div class="register-header d-flex justify-content-between">
      <div class="brand">
        <img
          src="../assets/img/logo/bird.png"
          height="60px"
          style="margin-right: 10px"
        />{{ "signup_main_logo" | localise }}
      </div>

      <div class="brand">
        <img
          src="../../../../assets/img/logo/logoexpat.png"
          height="50px"
          style="margin-left: 10px"
          class="mt-2"
        />
      </div>
    </div>

    <!-- end register-header -->
    <!-- begin register-content -->
    <div class="register-content">
      <h1>{{ "signup_main_title" | localise }}</h1>
      <p>{{ "signup_main_subtitle_pro" | localise }}</p>

      <form
        [formGroup]="signupForm.Group"
        novalidate
        class="margin-bottom-0"
        data-parsley-validate="true"
      >
        <app-form-input
          [parameters]="signupForm.GetInputElement('email')"
        ></app-form-input>
        <app-form-input
          [parameters]="signupForm.GetInputElement('password')"
        ></app-form-input>
        <app-form-input
          [parameters]="signupForm.GetInputElement('confirm')"
        ></app-form-input>
        <app-form-input
          [parameters]="signupForm.GetInputElement('familyName')"
        ></app-form-input>
        <app-form-input
          [parameters]="signupForm.GetInputElement('givenName')"
        ></app-form-input>
        <app-form-input
          [parameters]="signupForm.GetInputElement('telephone')"
        ></app-form-input>
        <!--<app-form-input [parameters]="signupForm.GetInputElement('budget')"></app-form-input>-->
        <!--<app-form-input [parameters]="signupForm.GetInputElement('subscription')"> 
          </app-form-input>-->
        <app-form-general-error
          [submitForm]="signupForm"
        ></app-form-general-error>

        <!--
          <div class="checkbox checkbox-css m-b-30">
            <div class="checkbox checkbox-css m-b-30">
              <input type="checkbox" id="agreement_checkbox" value="">
              <label for="agreement_checkbox">
              En cliquant ici, vous acceptez nos <a href="#">conditions d'utilisation</a>
              </label>
            </div>
          </div>
          -->

        <div class="register-buttons">
          <button
            (click)="signupForm.Submit()"
            [disabled]="!signupForm.Valid"
            class="btn btn-block btn-lg myColor"
          >
            {{ "signup_main_button" | localise }}
          </button>
        </div>
        <div class="m-t-20 m-b-20 p-b-20 text-inverse">
          <a [routerLink]="['/compte', 'connexion']">{{
            "signup_main_to_signup" | localise
          }}</a>
        </div>
        <app-wait [show]="busy"></app-wait>
        <hr />
        <p class="text-center">
          {{ "signup_main_copyright" | localise }}
        </p>
      </form>
    </div>
    <!-- end register-content -->
  </div>
  <!-- end right-content -->
</div>
<!-- end register -->
