<div class="modal-body">
  <div class="container">
    <div class="mb-3">
      <h3>{{ "new_order" | localise }}</h3>
    </div>
    <form [formGroup]="commandForm" (ngSubmit)="submit()">
      <!-- choose type of command (asser or classic) -->
      <div class="form-group">
        <label class="col-form-label" for="type">{{
          "traduction_type" | localise
        }}</label>
        <select
          class="form-group custom-select my-1 mr-sm-2"
          id="type"
          formControlName="type"
        >
          <option value="" disabled>
            {{ "select_choose" | localise }}
          </option>
          <option value="classic">{{ "traduction_classic" | localise }}</option>
          <option value="asser">
            {{ "traduction_assermentee" | localise }}
          </option>
        </select>
        <div
          *ngIf="
            commandForm.get('type').hasError('required') &&
            commandForm.get('type').touched
          "
        >
          <small class="text-danger">
            {{ "account_error_required" | localise }}
          </small>
        </div>
      </div>
      <div class="form-group row">
        <!-- choose document type/name -->
        <div class="col-sm">
          <label class="col-form-label" for="doctype">
            {{ "order_no_asser_document_name" | localise }}
          </label>
          <input
            class="form-control"
            type="text"
            id="doctype"
            formControlName="doctype"
          />
          <div
            *ngIf="
              commandForm.get('doctype').hasError('required') &&
              commandForm.get('doctype').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
        </div>

        <!-- choose document source language-->
        <div class="col-sm">
          <label class="col-form-label" for="srcLang">{{
            "label_origin_lang" | localise
          }}</label>
          <select
            class="form-control custom-select"
            id="srcLang"
            formControlName="srcLang"
          >
            <option value="" disabled>
              {{ "select_choose" | localise }}
            </option>
            <option
              *ngFor="let srcLang of srcLangList"
              [value]="srcLang.iso639"
            >
              {{ srcLang.iso639 | iso639ToNative }}
            </option>
          </select>
          <div
            *ngIf="
              commandForm.get('srcLang').hasError('required') &&
              commandForm.get('srcLang').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <!-- choose document destination language-->
        <div class="col-sm">
          <label class="col-form-label" for="destLang">{{
            "tasks_dst_country" | localise
          }}</label>
          <select
            class="form-control custom-select"
            id="destLang"
            formControlName="destLang"
          >
            <option value="" disabled>
              {{ "select_choose" | localise }}
            </option>
            <option *ngFor="let destLang of destLangList" [value]="destLang">
              {{ destLang | iso639ToNative }}
            </option>
          </select>
          <div
            *ngIf="
              commandForm.get('destLang').hasError('required') &&
              commandForm.get('destLang').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
        </div>

        <!-- choose document country language-->
        <div class="col-sm">
          <label class="col-form-label" for="originLang">{{
            "tasks_src_country" | localise
          }}</label>
          <select
            class="form-control custom-select"
            id="originLang"
            formControlName="originLang"
          >
            <option value="" disabled>
              {{ "select_choose" | localise }}
            </option>
            <option
              *ngFor="let srcCountry of srcCountryList"
              [value]="srcCountry.code"
            >
              {{ srcCountry.code | country }}
            </option>
          </select>
          <div
            *ngIf="
              commandForm.get('originLang').hasError('required') &&
              commandForm.get('originLang').touched
            "
          >
            <small class="text-danger">
              {{ "account_error_required" | localise }}
            </small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="col-form-label" for="bon">{{
          "bon_commande" | localise
        }}</label>
        <input type="text" class="form-control" formControlName="bon" />
      </div>

      <!-- description -->
      <div class="form-group">
        <label class="my-1 mr-2" for="description">
          {{ "commande_desc" | localise }}
        </label>
        <textarea
          class="form-control"
          id="description"
          rows="3"
          formControlName="description"
        ></textarea>
      </div>
      <!-- end -->
      <!-- urgency -->
      <div class="form-group row justify-content-between mb-0">
        <label class="col-form-label" for="urgency">
          {{ "urgency" | localise }}
        </label>
        <input
          type="date"
          class="form-control col-md-5 mr-3"
          id="urgency"
          formControlName="urgency"
          data-date-format="DD MMMM YYYY"
        />
      </div>
      <div
        *ngIf="
          commandForm.get('urgency').hasError('required') &&
          commandForm.get('urgency').touched
        "
        class="d-flex flex-row-reverse"
      >
        <small class="text-danger">
          {{ "account_error_required" | localise }}
        </small>
      </div>

      <!-- document pages numbers check -->
      <div class="form-group d-flex align-items-center mt-3">
        <label class="col-form-label" for="isNumberPage"
          >{{ "order_no_asser_query_pdf_page_number" | localise }}
        </label>
        <input
          type="checkbox"
          class="ml-3 form-check-input-reverse"
          id="isNumberPage"
          formControlName="isNumberPage"
        />
      </div>
      <!-- number of pages -->
      <div *ngIf="isChecked" class="form-group row justify-content-between">
        <label class="col-form-label" for="docFilePageNumber">
          {{ "order_no_asser_query_pdf_page_number_yes" | localise }}
        </label>
        <input
          class="form-control col-md-5 mr-3"
          type="number"
          id="docFilePageNumber"
          formControlName="docFilePageNumber"
          min="0"
        />
        <div
          class="alert text-danger pull-right"
          *ngIf="commandForm.controls['docFilePageNumber'].hasError('min')"
        >
          {{ "alert_form_error_pages_number" | localise }}
        </div>
      </div>

      <!-- the documents to translate -->
      <div class="form-group">
        <input
          id="input_file"
          class="form-control-file"
          type="file"
          formControlName="docFile"
          multiple
          (change)="fileUpload($event.target.files)"
        />
      </div>

      <!-- extra documents check -->
      <div class="form-group d-flex align-items-center mt-3">
        <label class="col-form-label" for="isExtraFiles"
          >{{ "order_extra_files_yes" | localise }}
        </label>
        <input
          type="checkbox"
          class="ml-3 form-check-input-reverse"
          id="isExtraFiles"
          formControlName="isExtraFiles"
        />
      </div>
      <!-- extra documents  -->
      <div *ngIf="isExtraChecked" class="form-group">
        <div class="mb-2">
          <p class="mb-0">
            {{ "order_extra_files_info" | localise }}
          </p>
          <small>
            {{ "order_extra_files_exemple" | localise }}
          </small>
        </div>
        <input
          class="form-control-file"
          type="file"
          formControlName="extraDocFile"
          multiple
          (change)="extraFileUpload($event.target.files)"
        />
      </div>

      <!-- button to get traslators -->
      <div class="mb-3 d-flex justify-content-end">
        <button
          [ngClass]="
            hiero.Auth.User.Profile.isSeineMaritime
              ? 'btn seine-maritime-color-secondary'
              : hiero.Auth.User.Profile.isFidinam
              ? 'btn fidinam-color-secondary'
              : 'btn my-color'
          "
          [disabled]="commandForm.invalid"
        >
          {{ "find_traductors" | localise }}
        </button>
      </div>
    </form>
    <table class="table table-responsive w-auto" *ngIf="show">
      <thead>
        <tr>
          <th>{{ "order_traductor_delegate" | localise }}</th>
          <th>{{ "agency_address_city" | localise }}</th>
          <th>{{ "tasks_price_ttc" | localise }}</th>
          <th>{{ "assermente" | localise }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="translatorList?.length == 0">
          {{
            "no_traductor" | localise
          }}
          <i>{{ "no_traductor_message" | localise }}</i>
        </tr>
        <tr *ngFor="let traducteur of translatorsCopies">
          <td nowrap>
            {{ traducteur.traducteur.businessName }}
          </td>
          <td nowrap>
            {{ traducteur.traducteur.address.city }}
          </td>
          <td>{{ traducteur.priceTTC }} €</td>
          <td>
            {{
              traducteur.traducteur.assermentation === "true" ||
              traducteur.traducteur.assermentation == true
                ? ("prestation_document_confirm_oui" | localise)
                : ("prestation_document_confirm_non" | localise)
            }}
          </td>
          <td>
            <button
              [ngClass]="
                hiero.Auth.User.Profile.isSeineMaritime
                  ? 'btn seine-maritime-color-secondary'
                  : hiero.Auth.User.Profile.isFidinam
                  ? 'btn fidinam-color-secondary'
                  : 'btn my-color'
              "
              (click)="
                getTranslatorNameOnClickButton(
                  traducteur.traducteur.businessName
                )
              "
            >
              {{ "select" | localise }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
  >
    Close
  </button>
</div>
