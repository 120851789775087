import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommandeComponent } from "./commande/commande.component";
import { HieroBDD } from "../../../services/hierobdd.service";
import { InterpreteComponent } from "./interprete/interprete.component";
import { CommandeAssermenteComponent } from "./commande-assermente/commande-assermente.component";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ProfessionnelService } from "../../../services/professionnel.service";
import { InterpretationService } from "../../../services/interpretation.service";
import { Subscription } from "rxjs";
import { PrestationType } from "../../../../../common/src/bdd/interfaces/types";
import { IProfessionnel } from "../../../../../common/src/bdd/interfaces/IProfessionnel";
import { TranslationsService } from "../../../services/translations.service";

const URL = "";
@Component({
  selector: "app-commandes",
  templateUrl: "./commandes.component.html",
  styleUrls: ["./commandes.component.scss"],
})
export class CommandesComponent implements OnInit, OnDestroy {
  public commandesListInterpretations = Array();
  public assermentationList: any;
  public classicList: any;
  public page = 1;
  public pageAsser = 1;
  public pageInter = 1;
  public hasBudget = true;
  public pageSize = 10;
  public budget: number;
  public currentUser: any;
  private professionelData: IProfessionnel;
  private interpretationSub: Subscription;
  private commandsSub: Subscription;

  constructor(
    private modalService: NgbModal,
    private hiero: HieroBDD,
    private router: Router,
    private http: HttpClient,
    private pro: ProfessionnelService,
    private interpretationService: InterpretationService,
    private translationsService: TranslationsService
  ) {}

  async ngOnInit() {
    this.currentUser = this.hiero.Auth.User;

    await this.getTraductionCommandes();

    this.getInterpretationsCommandes();
  }

  ngOnDestroy() {
    if (this.interpretationSub) this.interpretationSub.unsubscribe();
    if (this.commandsSub) this.commandsSub.unsubscribe();
  }

  openModal(event) {
    if (event === 1) {
      this.modalService.open(CommandeComponent, { size: "lg" });
    } else if (event === 2) {
      this.modalService.open(InterpreteComponent, { size: "lg" });
    } else if (event === 0) {
      this.modalService.open(CommandeAssermenteComponent, { size: "lg" });
    }
  }

  isValideBudget() {
    if (this.professionelData.budget > 0) {
      this.hasBudget = false;
    }
  }

  async getTraductionCommandes() {
    this.professionelData = await this.pro.getProfessionnelData();
    this.budget = this.professionelData.budget;
    console.log(this.budget);

    this.isValideBudget();

    this.translationsService.getTranslations();
    this.commandsSub = this.translationsService.translationsSubject.subscribe({
      next: (data: any[]) => {
        this.assermentationList = data.filter(
          (el) => el.type === PrestationType.TRADUCTION_ASSERMENTEE
        );
        this.classicList = data.filter(
          (el) => el.type === PrestationType.TRADUCTION_CLASSIQUE
        );
      },
    });
  }

  getInterpretationsCommandes() {
    this.interpretationService.getInterpretations();
    this.interpretationSub =
      this.interpretationService.commandInterpretationsSubject.subscribe({
        next: (value: any[]) => {
          this.commandesListInterpretations = value;
        },
      });
  }

  getRooms(interData) {
    //this.modalService.open(TutoSalonComponent); // *****created the component for tutorial's video***
    // window.open(URL);
    const headers = {
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    };
    const body = {
      roomName: interData.room,
      identity: interData.professionnel.uid,
    };
    this.http
      .post<any>("http://localhost:5000/join-room", body, { headers })
      .subscribe(async (data) => {
        window.localStorage.setItem(
          "settings",
          '{"audio": true,"video": true}'
        );

        window.localStorage.setItem(
          "user",
          interData.professionnel.businessName
        );

        window.localStorage.setItem("token", data.token);

        this.router.navigate(["/app/interpretations/" + body.roomName]);
      });
  }

  onPageChange(pageNumber: number, switchnmbr: number) {
    switch (switchnmbr) {
      case 0:
        this.pageAsser = pageNumber;
        break;
      case 1:
        this.page = pageNumber;
        break;
      case 2:
        this.pageInter = pageNumber;
        break;
      default:
        break;
    }
  }

  showDataDashboard(selected: number) {
    const showOrdersPro = document.getElementById("orders_pro");
    const showOrders = document.getElementById("orders");
    const showInterpretation = document.getElementById("interpretation_list");
    // const showUsers = document.getElementById('users_list')
    switch (selected) {
      case 0:
        showOrdersPro.classList.remove("showBloc");
        showOrders.classList.add("showBloc");
        showInterpretation.classList.add("showBloc");
        // showUsers.classList.add("showBloc")
        break;
      case 1:
        showOrdersPro.classList.add("showBloc");
        showOrders.classList.remove("showBloc");
        showInterpretation.classList.add("showBloc");
        // showUsers.classList.add("showBloc")
        break;
      case 2:
        showOrdersPro.classList.add("showBloc");
        showOrders.classList.add("showBloc");
        showInterpretation.classList.remove("showBloc");
        // showUsers.classList.add("showBloc")
        break;
      case 3:
        showOrdersPro.classList.remove("showBloc");
        showOrders.classList.remove("showBloc");
        showInterpretation.classList.remove("showBloc");
        // showUsers.classList.remove("showBloc")
        break;
      default:
        break;
    }
  }
}
