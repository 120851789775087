<div class="container-fluid">
  <div *ngIf="facture">
    <div class="border my-first">
      <p class="m-2" [routerLink]="['/app/factures']">
        <span class="clickable"
          >{{ "main_menu_left_section_navigation_factures" | localise }} /
        </span>
        <span class="my-p">
          {{ "facture_numero" | localise }} {{ facture.data.numero }}</span
        >
      </p>
    </div>

    <div id="print-me">
      <div class="border mt-3" id="htmlDATA">
        <div>
          <p class="m-2 p1">
            {{ "commande_numero" | localise }} {{ facture.data.prestation }}
            {{ "order_translate" | localise }}
            {{ facture.data.date.toDate() | date : "dd/MM/yyyy" }}
          </p>
        </div>
        <hr />

        <div class="row">
          <!--  translator data-->
          <div class="col-md-3">
            <p class="ml-3 my-p">{{ "prestataire" | localise | uppercase }}</p>
            <div
              class="d-block ml-3"
              *ngIf="facture.data.date.toDate().getTime() < timeFacture"
            >
              <p>HIERØ</p>
              <p class="mt-n2">{{ "support_addr1" | localise }}</p>
              <p class="mt-n2">{{ "support_addr2" | localise }}</p>
            </div>
            <div
              class="d-block ml-3"
              *ngIf="facture.data.date.toDate().getTime() >= timeFacture"
            >
              <p>HIERØ</p>
              <p class="mt-n2">{{ "support_addr1_paris" | localise }}</p>
              <p class="mt-n2">{{ "support_addr2_paris" | localise }}</p>
            </div>
          </div>
          <!-- end translator data-->
          <!-- client -->
          <div class="col-md-3" *ngIf="pro">
            <p class="my-p">{{ "client" | localise | uppercase }}</p>
            <div class="d-block">
              <p>{{ pro.businessName }}</p>
              <p class="mt-n2">
                {{ pro.address.number }} {{ pro.address.street }}
              </p>
              <p class="mt-n2">
                {{ pro.address.code }} {{ pro.address.city }}
                {{ pro.address.country }}
              </p>
            </div>
          </div>
          <!-- end client data -->
          <!-- Prestation -->
          <div class="col-md-6">
            <p class="my-p text-right mr-3">
              {{ "section_prestation" | localise | uppercase }}
            </p>
            <div class="d-block">
              <p class="text-right mr-3">
                {{ "commande_numero" | localise }} :
                {{ facture.data.prestation }}
              </p>
              <p
                class="text-right mr-3"
                *ngIf="facture.data.typePrestation === 'Traduction assermentée'"
              >
                {{ "section_type_prestation" | localise }}:
                {{ "traduction_assermentee" | localise }}
              </p>
              <p
                class="text-right mr-3"
                *ngIf="facture.data.typePrestation === 'Traduction classique'"
              >
                {{ "section_type_prestation" | localise }}:
                {{ "traduction_classic" | localise }}
              </p>
              <p
                class="text-right mr-3"
                *ngIf="facture.data.typePrestation === 'Interprétation'"
              >
                {{ "section_type_prestation" | localise }}:
                {{ "interpretation" | localise }}
              </p>
              <p class="text-right mr-3">
                {{ "section_date" | localise }} :
                {{ facture.data.date.toDate() | date : "dd/MM/yyyy" }}
              </p>
            </div>
          </div>
        </div>
        <!-- end with trad and client -->

        <hr />

        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">{{ "commande_qte" | localise }}</th>
              <th scope="col">{{ "commande_produit" | localise }}</th>
              <th scope="col">{{ "commande_desc" | localise }}</th>
              <th scope="col">{{ "tasks_price_tva" | localise }}</th>
              <th scope="col">{{ "price_total_ht" | localise }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>{{
                  facture.data.pagesNumber ? facture.data.pagesNumber : 1
                }}</span>
              </td>
              <td>{{ product }}</td>
              <td>
                <span
                  *ngIf="facture.data.typePrestation === 'Traduction classique'"
                >
                  {{ "traduction_classic" | localise }}
                </span>
                <span
                  *ngIf="
                    facture.data.typePrestation === 'Traduction assermentée'
                  "
                >
                  {{ "traduction_assermentee" | localise }}
                </span>
                <span *ngIf="facture.data.typePrestation === 'Interprétation'">
                  {{ "interpretation" | localise }}
                </span>
                {{ "prestation_order_from" | localise }}
                {{ facture.data.srcLang | iso639ToNative }}
                {{ "order_language_to" | localise }}
                {{ facture.data.destLang | iso639ToNative }}
              </td>
              <td>{{ facture.data.priceTVA.toFixed(2) }}</td>
              <td>{{ facture.data.priceHT.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
        <div class="space"></div>

        <div class="row mt-3">
          <div class="col-md-8 mt-5">
            <p class="ml-3 my-p">
              {{ "rules_conditions" | localise | uppercase }} :
              {{ "rules_conditions_p" | localise }}
            </p>
            <p class="ml-3 p">{{ "condition_paragraphe_1" | localise }}</p>
            <p class="ml-3 p">{{ "condition_paragraphe_2" | localise }}</p>
            <p class="ml-3 p">{{ "condition_paragraphe_3" | localise }}</p>
          </div>

          <div class="col-md-4 mt-5">
            <div class="d-flex flex-column">
              <div class="d-flex justify-content-between ml-5 mr-5 mt-3">
                <span class="p">{{ "total_ht" | localise }}:</span>
                <span>
                  {{ facture.data.priceHT.toFixed(2) }}
                </span>
              </div>
              <div class="d-flex justify-content-between ml-5 mr-5 mt-3">
                <span class="p">{{ "total_tva" | localise }}: </span
                ><span>{{ facture.data.priceTVAVal.toFixed(2) }} </span>
              </div>
              <div class="d-flex justify-content-between ml-5 mr-5 mt-3">
                <span class="mt-1 font-weight-bold"
                  >{{ "montant_total_euro" | localise }}:</span
                ><span class="font-weight-bold total-price">
                  {{ facture.data.priceTTC.toFixed(2) }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!--Infos de la facture au niveau de pied de page -->
        <div class="row">
          <div class="col-md-12">
            <div
              class="d-flex flex-nowrap my-footer mt-3"
              *ngIf="facture.data.date.toDate().getTime() < timeFacture"
            >
              <p class="p-2">{{ "capital_sasu" | localise }}</p>
              <p class="p-2">
                {{ "agency_address_number" | localise }}
                {{ "rcs_infos" | localise }}
              </p>
              <p class="p-2">
                {{ "agency_address_number" | localise }}
                {{ "siret" | localise }}
              </p>
              <p class="p-2">
                {{ "agency_address_number" | localise }}
                {{ "tva_fr" | localise }}
              </p>
              <p class="p-2">{{ "facture_numero" | localise }}</p>
              <p class="p-2">{{ facture.data.numero }}</p>
            </div>
            <div
              class="d-flex flex-nowrap my-footer mt-3"
              *ngIf="facture.data.date.toDate().getTime() >= timeFacture"
            >
              <p class="p-2">{{ "capital_sasu" | localise }}</p>
              <p class="p-2">
                {{ "agency_address_number" | localise }}
                {{ "rcs_infos_new" | localise }}
              </p>
              <p class="p-2">
                {{ "agency_address_number" | localise }}
                {{ "siret_new" | localise }}
              </p>
              <p class="p-2">
                {{ "agency_address_number" | localise }}
                {{ "tva_fr" | localise }}
              </p>
              <p class="p-2">{{ "facture_numero" | localise }}</p>
              <p class="p-2">{{ facture.data.numero }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end div print-me -->
  </div>
  <!--end div container -->

  <div class="d-flex mt-5">
    <div class="mr-auto p-2">
      <button [routerLink]="['/app/factures']" class="btn">
        <i class="fa fa-undo"></i> {{ "retour_btn" | localise }}
      </button>
    </div>
    <div class="p-2">
      <button
        style="background-color: #702963; color: white"
        class="btn mr-1"
        (click)="download(2)"
      >
        <i class="fa fa-download"></i>
        {{ "section_generate_facture" | localise }}
      </button>
      <button
        class="btn mr-1"
        style="background-color: black; color: white"
        (click)="download(1)"
      >
        <i class="fa fa-print"></i> {{ "section_print" | localise }}
      </button>
    </div>
  </div>
</div>
