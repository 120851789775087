var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { HieroBDD } from "../../../services/hierobdd.service";
export class CardComponent {
    constructor(hiero) {
        this.hiero = hiero;
    }
    ngOnInit() { }
    test() {
        return __awaiter(this, void 0, void 0, function* () {
            const fun = fetch("https://us-central1-hierodev.cloudfunctions.net/allUsers");
            const data = yield fun;
            const result = yield data.json();
            return console.log(result.filter((user) => user.uid === "4y9DkIK2HidbKpwHZ4cnDwzkKSD3"));
        });
    }
}
