/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./maintenance.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./maintenance.component";
var styles_MaintenanceComponent = [i0.styles];
var RenderType_MaintenanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MaintenanceComponent, data: {} });
export { RenderType_MaintenanceComponent as RenderType_MaintenanceComponent };
export function View_MaintenanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "article", [["style", "text-align:start;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We\u2019ll be back soon!"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sorry for the inconvenience but we\u2019re performing some maintenance at the moment. If you need to you can always "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["href", "mailto:contact@hiero-support.com"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["contact us"])), (_l()(), i1.ɵted(-1, null, [", otherwise we\u2019ll be back online shortly!"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2014 The Team Hiero"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["height", "60px"], ["src", "../assets/img/logo/bird.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "img", [["height", "300px"], ["src", "../assets/img/WebMaintenance.webp"]], null, null, null, null, null))], null, null); }
export function View_MaintenanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-maintenance", [], null, null, null, View_MaintenanceComponent_0, RenderType_MaintenanceComponent)), i1.ɵdid(1, 114688, null, 0, i2.MaintenanceComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MaintenanceComponentNgFactory = i1.ɵccf("app-maintenance", i2.MaintenanceComponent, View_MaintenanceComponent_Host_0, {}, {}, []);
export { MaintenanceComponentNgFactory as MaintenanceComponentNgFactory };
